import {AND, ARRAY, INTEGER, OBJECT, STRING} from '@acng/frontend-rubicon';
import {GALLERY_DATA} from '../../core/service/guard.js';
import {FSK_LEVEL} from '../../core/model/fsk.js';

/**
 * Validate picture data.
 */
export const PICTURE_DATA = /* @__PURE__ */ OBJECT({
  id: INTEGER,
  set_id: INTEGER,
  fsk: FSK_LEVEL,
  width: INTEGER,
  height: INTEGER,
  price: INTEGER,
  preview: STRING,
});

/**
 * Validate picture set data.
 */
export const PICTURE_SET_DATA = /* @__PURE__ */ AND(
  GALLERY_DATA,
  OBJECT({
    id: INTEGER,
    rawItems: ARRAY(PICTURE_DATA),
    items: ARRAY(INTEGER),
  })
);
