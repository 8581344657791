// bounty
import {NaN} from '@acng/frontend-bounty/std/value.js';
import {assign} from '@acng/frontend-bounty/object.js';
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {get} from '@acng/frontend-bounty/dom/attribute.js';
// stargazer, relativity
import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';
import {localProvide} from '@acng/frontend-relativity';
// enterprise
import {ctxChallenge} from '../context/challenge.js';
import {Challenge} from '../model/challenge.js';
import {CHALLENGE_KEY} from './challenge-key.js';
import {cdn} from 'acng/core/service/backend.js';
import {guard} from '@acng/frontend-rubicon';
import {CHALLENGE_DATA} from '../model/challenge-data.js';

/**
 * Provide a {@link ctxChallenge | Challenge}.
 *
 * The challenge is instantly provided with `id` and `value` properties set to `NaN`.
 *
 * When the element is connected, a backend request for the specified
 * challenge is made. On success the challenge is provided again with the
 * correct `id` and `value` properties.
 *
 * @example
 * ```html
 * <section use-challenge="valentine2025">
 *   <!-- valentine2025 challenge context -->
 * </section>
 * ```
 * @group DOM Attribute
 */
export const USE_CHALLENGE = 'use-challenge';

defineRegistryAttribute(USE_CHALLENGE, () => {
  /**
   * @param {Element} element
   * @param {Challenge} challenge
   * @returns {Promise<void>}
   */
  const update = async (element, challenge) => {
    // TODO backend service or die hard when achievement is not there, at least for some seconds
    DEBUG: if (debug(element)) console.debug('fetch challenge data...', {element, challenge});
    const data = await cdn(`achievement/${challenge.name}`, element);
    DEBUG: if (debug(element)) console.log('...got challenge data', {element, data});
    ASSERT: guard(data, CHALLENGE_DATA, 'challenge data');

    assign(challenge, data);
    localProvide(element, ctxChallenge, challenge);
  };

  defineRenderAttribute(USE_CHALLENGE, (element, name) => {
    const challenge = new Challenge({
      id: NaN,
      name,
      value: NaN,
      key: get(element, CHALLENGE_KEY),
    });

    localProvide(element, ctxChallenge, challenge);

    return () => {
      update(element, challenge);
    };
  });
});
