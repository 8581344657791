import {createSet} from '@acng/frontend-bounty/collection.js';

/**
 * The matchmaker matches of the `authUser`
 *
 * The set is empty while there is no `authUser` and
 * is filled asynchronously after authentication.
 *
 * @see [authUser](../userPool/context/auth-user.js)
 * @type {Set<Amateur["id"]>}
 */
export const matches = createSet();
