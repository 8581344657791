/**
 * @module Warpdrive
 * @author Jacob Viertel <jv@onscreen.net>
 */

import {HTMLElement, connectedCallback, disconnectedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {createPerceptiveHTML, createStream} from '@acng/frontend-discovery';
import {createTemplate} from '@acng/frontend-bounty';
import {Engine} from '@acng/frontend-stargazer';
import {IS, guard} from '@acng/frontend-rubicon';
import {append, get} from '@acng/frontend-bounty/dom/template.js';

/**
 * @abstract
 * @template T
 */
export class Warpdrive extends HTMLElement {
  #transcluded = createTemplate();
  #stream = createStream([], (offset) => this.more(offset, this.#renderEmpty));
  #warpdrive = createPerceptiveHTML(this, this.#stream, (data, loader) =>
    this.render(data, this.#renderItem, loader)
  );

  /**
   * @type {?Engine}
   */
  #renderItem = null;

  /**
   * @type {?Engine}
   */
  #renderEmpty = null;

  constructor() {
    super();
    DEBUG: if (this.hasAttribute('debug')) {
      this.#warpdrive.enableDebug(this.localName);
    }
  }

  /**
   * @abstract
   * @param {number} offset
   * @param {?Engine} render
   * @returns {Promise<T[]>}
   */
  async more(offset, render) {
    throw Error();
  }

  /**
   * @abstract
   * @param {T} item
   * @param {import("@acng/frontend-discovery").Loader} loader
   * @param {?Engine} render
   * @returns {Promise<void | HTMLTemplateElement>}
   */
  async render(item, render, loader) {
    throw Error();
  }

  link() {
    this.warp();
  }

  warp() {
    this.#warpdrive.disconnect();
    this.#stream.streamReset([]);
    this.replaceChildren();
    this.#warpdrive.connect();
  }

  [connectedCallback]() {
    const template = this.#transcluded;

    append(template, ...this.childNodes);

    const item = get(template, 'item');
    const empty = get(template, 'empty');

    ASSERT: {
      guard(item, IS(HTMLTemplateElement));
      guard(empty, IS(HTMLTemplateElement));
    }

    this.#renderItem = new Engine(item);
    this.#renderEmpty = new Engine(empty);

    this.link();
  }

  [disconnectedCallback]() {
    this.#warpdrive.disconnect();
    this.replaceChildren(...this.#transcluded.content.childNodes);
  }
}
