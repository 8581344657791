import angular from 'angular';
import {env, FEATURES} from 'acng/core/service/env';
import './layout';
import './achievement';
import './kiss';
import './imprint';
import './privacyPolicy';
import './alternativeProducts';
import './comment';
import './games';
import './locale';
import './regard';
import './sexicon';
import './special';
import './terms';
import './pin';
import './friend';
import voyager, {rootRoute} from '@acng/frontend-voyager/angularjs';
import {runRouter} from './core/service/router.js';
import {runBootstrap} from './core/service/templates.js';

/**
 * @returns {string} -
 */
String.prototype.ucFirst = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
/**
 * @returns {string} -
 */
String.prototype.lcFirst = function () {
  return this.charAt(0).toLowerCase() + this.slice(1);
};
/**
 * @returns {string} -
 */
String.prototype.toDash = function () {
  return this.replace(/([A-Z])/g, function ($1) {
    return '-' + $1.toLowerCase();
  });
};
/**
 * @returns {string} -
 */
String.prototype.toCamel = function () {
  return this.replace(/(-[a-z])/g, function ($1) {
    return $1.toUpperCase().replace('-', '');
  });
};
/**
 * @returns {number} -
 */
String.prototype.hashCode = function () {
  var hash = 5381;
  for (let i = 0; i < this.length; i++) {
    const char = this.charCodeAt(i);
    hash = (hash << 5) + hash + char;
    /* hash * 33 + c */
  }
  return hash;
};

/**
 * @typedef RootScopeTrait
 * @property {(name: string) => boolean} hasFeature -
 * @property {() => string} locale -
 * @property {typeof rootRoute.globals} request
 * @property {Amateur | null} dialogAmateur
 * @property {string[]} friends
 * @property {string[]} matches
 * @property {string[]} pins
 * @property {import('./messenger/model/dialog').Dialog[] & {changed?: number, online?: () => boolean}} dialogs
 */
/**
 * @typedef {angular.IRootScopeService & RootScopeTrait} RootScope
 */
angular
  .module('acngApp', [
    voyager,
    'core',
    'acngMisc',
    'ngSanitize',
    'pascalprecht.translate',
    'ngAnimate',
    'ngStorage',
    'storage',
    'ons-actionslider',
    'ngCookies',
    'ons-LaForge-client',
    ...FEATURES.filter((v) => !['special', 'achievement'].includes(v)),
  ])
  .run(['$rootScope', runRouter])
  .run(['$rootScope', '$compile', '$location', runBootstrap]);

for (const k in env) {
  angular.module('core').constant(k, env[k]);
}
