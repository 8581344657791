import angular from 'angular';

import {hasMyPortal} from 'acng/core/service/env';
import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate';

import {runReloadOnLogin, runSignupOverlay} from './config/routes';
import {runUser} from './service/user';
import {fsk} from './fsk';
import {fskTrans} from './0fsk';
import nickname, {nicknameFilter} from './filter/nickname';
import idlePopup from './idle-popup';
import './attribute/index.js';
import './config/controller/if-myportal.js';
import './attribute/open-cookiebar.js';
import './widget/mailing-config.js';
import './widget/confirm-device.js';
// import './widget/profile-info.js';
import './widget/2fa-setup.js';
import './widget/profileImageEditor.js';
import ngModuleName from './config/feature.js';

export default ngModuleName;

angular
  .module(ngModuleName, [angularTranslate, 'stock', idlePopup])
  .config(provideTranslations(ngModuleName))
  .run(runUser)
  .run(runReloadOnLogin)
  .run(runSignupOverlay)
  .filter('fskTrans', () => fskTrans)
  .filter(nickname, nicknameFilter)
  .factory('fsk', [() => fsk])
  .factory('isPortal', () => hasMyPortal);
