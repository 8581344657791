import {Element} from '@acng/frontend-stargazer';
import {addClass, append, createSpan, removeChildNodes, setText} from '@acng/frontend-bounty';

import {commentFeature} from '../config/feature.js';
import {HTMLElement, def} from '@acng/frontend-bounty/dom/custom.js';
import {Watch} from '@acng/frontend-relativity';
import {ctxGallery} from 'acng/core/context/gallery.js';
import {countComments} from '../service/http.js';

/**
 * @group DOM Element
 */
const COMMENT_COUNT = 'onsw-comment-count';

Element(COMMENT_COUNT, (tagName) => {
  const watchGallery = Watch(tagName, ctxGallery);

  class CommentCount extends HTMLElement {
    connectedCallback() {
      addClass(this, 'ons-item');
      watchGallery(this, async (element, commentable) => {
        removeChildNodes(this);
        if (commentable) {
          const box = createSpan('box');
          const label = createSpan('label');
          const value = createSpan('value');
          setText(value, `${await countComments(element, commentable)}`);
          setText(label, await commentFeature.translate('comments'));
          append(box, label, value);
          append(this, box);
        }
      });
    }
  }

  def(tagName, CommentCount);

  return [, ,];
});
