// @ts-nocheck
import {popup} from '@acng/frontend-discovery';
import pick from 'underscore/modules/pick.js';

import {getLocale} from 'acng/locale/model/locale.js';
import {publish} from '../context/event-bus.js';
import {setOutdated} from '../service/backend/x-version.js';
import {handleCfChallenge, showTurnstyle} from '../service/backend/cf-challenge.js';

angular.module('core').factory('globalHttpInterceptor', [
  '$q',
  '$rootScope',
  '$injector',
  'VERSION',
  'fsk',
  '$timeout',
  function ($q, $rootScope, $injector, VERSION, fsk, $timeout) {
    let http;
    $timeout(() => {
      http = $injector.get('http');
    });

    return {
      request: function (config) {
        var match;
        if ((match = /^\/?template(.*)/.exec(config.url))) {
          config.url = `/template/${getLocale()}${match[1]}`;
          config.params = {v: VERSION};
        } else if (/^\/?api(.*)/.exec(config.url)) {
          (config.params || (config.params = {})).locale = getLocale();
        }
        //config.headers = {...config.headers, 'X-Version': VERSION};
        return config;
      },
      response: function (response) {
        var messages = angular.fromJson(response.headers()['x-notifications'] || '[]');

        angular.forEach(messages, function (message) {
          // message.type: "danger" | "warning" | "info" | "success"
          popup()[{danger: 'error', warning: 'warn', info: 'info', success: 'info'}[message.type]](
            message.message
          );
        });

        if (response.headers('x-version') && response.headers('x-version') != VERSION) {
          setOutdated();
        }

        if (response.headers('x-loggedin')) {
          $rootScope.$broadcast('userPool.login.ready');
        }

        if (response.headers('x-userpopups')) {
          $rootScope.$broadcast(
            'userPool.popups',
            angular.fromJson(response.headers('x-userpopups') || '[]')
          );
        }

        if (response.headers('x-fsk')) {
          fsk.set(response.headers('x-fsk'));
        }

        return response;
      },
      responseError: function (rejection) {
        if (rejection.headers('cf-mitigated') === 'challenge') {
          showTurnstyle();
        }

        if (!(rejection.config && rejection.config.dontIntercept)) {
          switch (rejection.status) {
            case 401:
              if (!$rootScope.user.guestSignup(rejection.config && rejection.config.signupOverlayParams)) {
                $rootScope.user.clear();
              }
              break;
            case 402: // use event or something
              if (rejection.data && rejection.data.code == 4001) {
                if ($injector.has('Payback')) {
                  $injector.get('Payback').openOverlay();
                  break;
                }
              } else if ($injector.has('payment')) {
                angular.element(document).focus();
                $injector.get('payment').overlay(rejection.config && rejection.config.paymentOverlayParams);
                break;
              }
            case 409:
              if (rejection.data.code < 9000 || rejection.data.code > 9999) {
                break;
              }

              let id = [9000, 9003].includes(rejection.data.code)
                ? rejection.config.data.recipient
                : /^.*\/(\d+)$/.exec(rejection.config.url)[1];
              let type = {9000: 'msg', 9001: 'movie', 9002: 'picture', 9003: 'msg'};

              /**
               * @type {Valid<typeof import('../model/price-change').EVENTBUS_PRICE_CHANGE>}
               */
              const busEvent = {
                type: 'price',
                article: {
                  article_id: id,
                  newPrice: Number(rejection.data.price),
                  type: type[rejection.data.code],
                },
              };

              publish(busEvent);

              if (rejection.config.data.price > rejection.data.price && rejection.config.method != 'GET') {
                return http()[rejection.config.method.toLowerCase()](
                  rejection.config.url,
                  {...rejection.config.data, price: rejection.data.price},
                  {
                    headers: pick(rejection.config.headers, (value, key) => {
                      return key.indexOf('X-') === 0 || key == 'Content-Type';
                    }),
                    paymentOverlayParams: rejection.config.paymentOverlayParams,
                  }
                );
              }
              break;
            default:
              if (
                (!rejection.config || !rejection.config.noMessageBag) &&
                rejection.data &&
                rejection.data.message &&
                rejection.data.code != 3002
              ) {
                popup().error(rejection.data.message);
              }
          }
        }
        return $q.reject(rejection);
      },
    };
  },
]);
