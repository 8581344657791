import {isInstanceOf} from '@acng/frontend-bounty/std/value.js';
import {post} from '../../core/service/backend.js';
import {Movie} from 'acng/moviePool/model/movie.js';
import {PictureSet} from 'acng/picturePool/model/picture-set.js';
import {ARRAY, INTEGER, OBJECT, STRING, guard} from '@acng/frontend-rubicon';

const RATEABLE_MOVIE = 'movie';
const RATEABLE_PICTURESET = 'picture';

/**
 * @param {Gallery} gallery
 */
const getRateableType = (gallery) => {
  if (isInstanceOf(gallery, Movie)) {
    return RATEABLE_MOVIE;
  }

  if (isInstanceOf(gallery, PictureSet)) {
    return RATEABLE_PICTURESET;
  }

  ASSERT: throw ReferenceError();
};

/**
 * @param {Element} element
 * @param {Gallery} gallery
 * @returns {Promise<number | undefined>}
 */
export const getRating = async (element, gallery) => {
  const type = getRateableType(gallery);
  const res = await post(`rating/${type}`, {ids: [gallery.set_id]}, element);
  ASSERT: guard(res, ARRAY(OBJECT({id: STRING, rating: INTEGER})));

  return res[0]?.rating;
};

/**
 * @param {Element} element
 * @param {Gallery} gallery
 * @param {number} rating
 * @returns {Promise<void>}
 */
export const setRating = async (element, gallery, rating) => {
  const type = getRateableType(gallery);
  await post(`rating/${type}/${gallery.set_id}`, {rating}, element);
};
