import {fsk} from 'acng/userPool/fsk';
import {getFallbackImageUrl} from 'acng/layout/service/fallback-image.js';
import {assign} from '@acng/frontend-bounty/object.js';

/**
 * @param {Record<string, unknown>} data
 */
export const fixPictureData = (data) => {
  // PictureSetData contains wrong type for that.
  // @ts-ignore
  data.fsk = parseInt(data.fsk);

  // PictureSetData contains wrong type for that.
  // @ts-ignore
  data.width = parseInt(data.width);

  // PictureSetData contains wrong type for that.
  // @ts-ignore
  data.height = parseInt(data.height);

  // PictureSetData contains wrong key and wrong type for that.
  // @ts-ignore
  data.set_id ??= data.setId;
  // @ts-ignore
  data.set_id = parseInt(data.set_id);

  delete data.setId;
  delete data.position;
};

/**
 * @implements {PictureData}
 */
export class Picture {
  /**
   * @param {PictureData} data
   */
  constructor(data) {
    ASSERT: {
      this.id = data.id;
      this.set_id = data.set_id;
      this.fsk = data.fsk;
      this.width = data.width;
      this.height = data.height;
      this.preview = data.preview;
      this.price = data.price;
    }

    assign(this, data);
  }

  getImageUrl() {
    return this.preview;
  }

  getBlurredImageUrl() {
    if (this.fsk > fsk.get()) {
      return getFallbackImageUrl('900');
    }

    const cdn_url_regex = /c2\.ng-source\.com/;
    if (cdn_url_regex.test(this.preview)) {
      return this.preview.replace(cdn_url_regex, 'c2.ng-source.com/cdn-cgi/image/blur=35,20quality=20');
    } else {
      return this.preview;
    }
  }
}
