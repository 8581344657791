/**
 * @module acng/moviePool
 */
import angular from 'angular';
import {runLaforge} from './config/laforge';
import {type as t1, name as n1, param as p1} from './widget/player.js';
import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate.js';
import './factory/FreeMovie.js';
import {runUpdates} from './factory/FreeMovie.js';
import './attribute/index.js';
import './element/index.js';

const name = 'moviePool';

angular
  .module(name, [angularTranslate])
  .config(provideTranslations(name))
  [t1](n1, p1)
  .run(runLaforge)
  .run(['$rootScope', runUpdates]);
