import escape from 'underscore/modules/escape.js';

import {media} from '../../core/service/env.js';
import {fsk} from '../../userPool/fsk.js';
import {WEBLOG_DATA} from './weblog-data.js';

/**
 * @implements {Valid<WEBLOG_DATA>}
 */
export class Weblog {
  /**
   * @param {Valid<WEBLOG_DATA>} data
   * @param {Amateur} amateur
   * @param {Movie | PictureSet | null} gallery
   */
  constructor(data, amateur, gallery) {
    this.id = data.id;
    this.user_id = data.user_id;

    this.created = data.created;
    this.published = data.published;
    this.title = data.title;
    //this.content = data.content;
    this.content = escape(data.content).replace(/\[link\](.*)\[\/link\]/, (m, a) => {
      if (!this.media) {
        return a;
      }
      return `<span class="ons-item link"><span class="box"><span class="label">${a}</span></span></span>`;
    });
    this.image = data.image;
    this.image_fsk = data.image_fsk;
    this.set_id = data.set_id;
    this.media_type = data.media_type;
    this.movie_id = data.movie_id;

    this.amateur = amateur;

    // legacy
    this.imageFsk = data.image_fsk;
    this.amateurId = `${data.user_id}`;
    this.mediaType = data.mediaType;
    this.mediaId = data.mediaId;
    this.media = gallery;
  }

  getImageUrl() {
    if (this.image && fsk.get() >= this.image_fsk) {
      return media.content.pictures + this.image;
    }

    if (this.media) {
      return this.media.getImageUrl();
    }

    return null;
  }

  /**
   *
   */
  textClick(ev) {
    if (ev.target.className == 'label') {
      this.goto();
    }
  }

  goto() {
    (this.media || this.amateur).goto();
  }
}
