import {
  defineCompileAttribute,
  defineRegistryAttribute,
  defineRenderAttribute,
} from '@acng/frontend-stargazer';
import {addClass, removeNode, setText} from '@acng/frontend-bounty';
import {query} from '@acng/frontend-bounty/dom/query.js';
import {STRING, guard} from '@acng/frontend-rubicon';
import {moviePoolFeature} from '../config/feature.js';
import {amateurRoute} from 'acng/amateurPool/config/routes.js';
import {inject} from 'acng/core/service/ng.js';
import {STYLE_EMPTY, swapClass} from 'acng/layout/service/style.js';

defineRegistryAttribute('data-sedcard-movie-count', (name) => {
  defineCompileAttribute(name, (element) => {
    if (moviePoolFeature.isHidden) {
      removeNode(element);
    }
  });

  defineRenderAttribute(name, async (element) => {
    const {nickname} = amateurRoute.params;
    addClass(element, STYLE_EMPTY);

    ASSERT: guard(nickname, STRING);
    DEBUG: if (element.hasAttribute('debug')) console.info(name, {element, nickname});

    const {total} = await inject('Movie').list(0, 0, {nickname});
    const valueNode = query(element, '.value') ?? element;
    setText(valueNode, `${total}`);
    swapClass(element, STYLE_EMPTY, !total);
  });
});
