import {NODES, Widget} from '@acng/frontend-stargazer';
import {forEachOfArray, on, queryAll, removeNode, setText} from '@acng/frontend-bounty';
import {TAGNAME_INPUT} from '@acng/frontend-bounty/dom/type.js';
import {popup} from '@acng/frontend-discovery';

import {portal} from 'acng/core/service/env.js';

import {userPoolFeature} from '../config/feature.js';
import {getMailConfig, putMailConfig} from '../service/http.js';
import {guard} from '@acng/frontend-rubicon';

userPoolFeature.defineWidget(
  'onsw-mailing-config',
  class extends Widget {
    render() {
      create(this);
    }
  }
);

const CHANGE_EVENT = 'change';
const marketingInputs = /** @type {const} */ (['informer', 'newsletter', 'bonus']);
const dataInputs = /** @type {const } */ ([...marketingInputs, 'transaction']);

const TEMPLATE = /* @__PURE__ */ NODES({
  portalName: Text,
  transaction: HTMLInputElement,
  informer: HTMLInputElement,
  newsletter: HTMLInputElement,
  bonus: HTMLInputElement,
  marketing: HTMLInputElement,
  bounced: HTMLElement,
  unsubscribed: HTMLElement,
});

/**
 * @param {Widget} element
 */
async function create(element) {
  ASSERT: guard(element.nodes, TEMPLATE);

  const elements = element.nodes;

  const {portalName, marketing, bounced, unsubscribed} = elements;

  const params = new URLSearchParams(location.hash.split('?')[1]);

     const authParams = {
       subscription: params.get('subscription'), // userId
       email: params.get('email'),
       hash: params.get('hash'),
     };

  /**
   * @param {boolean} disabled
   */
  const disableInputs = (disabled) => {
    for (const elt of queryAll(TAGNAME_INPUT, element)) {
      elt.disabled = disabled;
    }
  };

  /**
   * Set the checkboxes according to the ACNG api data.
   */
  const setup = async () => {
    const data = await getMailConfig(element, `${params}`);

    for (const id of dataInputs) {
      elements[id].checked = data[`${id}_subscribed`];
    }

    updateForm();

    // Show modal (blocking) note when the mail-recipient is UNSUBSCRIBED by the MAILGERÄT
    if (data.unsubscribed) {
      popup(element).showModal(unsubscribed);
    }

    // Show modal (blocking) note when the mail-recipient is BOUNCED by the MAILGERÄT
    if (data.bounced) {
      popup(element).showModal(bounced);
    }

    disableInputs(false);

    return data;
  };

  // Prepare template and show it.
  removeNode(bounced);
  removeNode(unsubscribed);
  setText(portalName, portal.name);
  disableInputs(true);

  // Fill the inputs with users mailing config from http api.
  let data = await setup();

  // Set/unset the whole "marketing" group accordingly.
  on(marketing, CHANGE_EVENT, () =>
    forEachOfArray(marketingInputs, (id) => (elements[id].checked = marketing.checked))
  );

  on(element, CHANGE_EVENT, async () => {
    updateForm();
    disableInputs(true);

    // Prepare data object with changed input values.
    const putData = dataInputs.reduce((putData, id) => {
      if (elements[id].checked !== data[`${id}_subscribed`]) {
        putData[`${id}_subscribed`] = data[`${id}_subscribed`] = elements[id].checked;
      }
      return putData;
    }, /** @type {import('acng/userPool/service/http.js').MailConfigPutData} */ (authParams));

    try {
      await putMailConfig(putData, element);
      disableInputs(false);
    } catch (error) {
      // Try to get valid data again.
      data = await setup();
    }
  });

  /**
   * Check the "marketing" group button accordingly.
   */
  function updateForm() {
    marketing.checked = marketingInputs.reduce((checked, id) => checked || elements[id].checked, false);
  }
}
