import {Attribute} from '@acng/frontend-stargazer';
import {remove} from '@acng/frontend-bounty/dom/move.js';
import {OPTIONAL, STRING, TUPLE, guard} from '@acng/frontend-rubicon';
import {authUser} from '../context/auth-user.js';

/**
 * @group DOM Attribute
 */
export const USER_LEVEL = 'user-level';

Attribute(USER_LEVEL, () => {
  /**
   * @type {Record<string, (x: number, y: number) => boolean>}
   */
  const compare = {
    '<=': (x, y) => x <= y,
    '>=': (x, y) => x >= y,
    '<': (x, y) => x < y,
    '>': (x, y) => x > y,
    '=': (x, y) => x === y,
  };

  const syntax = new RegExp(`^(<=|>=|<|>|=)?\\s*(\\d+)$`);

  return [
    (element, value) => {
      const match = value.match(syntax);
      ASSERT: guard(match, TUPLE(STRING, OPTIONAL(STRING), STRING), `[${USER_LEVEL}] syntax`);

      const parsedLevel = Number(match[2]);
      const usersLevel = authUser?.level ?? 0;
      const operator = match[1] ?? '>=';

      console.log(USER_LEVEL, {usersLevel, operator, parsedLevel});
      if (!compare[operator](usersLevel, parsedLevel)) {
        remove(element);
      }
    },
    ,
  ];
});
