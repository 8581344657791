import {NODES, Widget} from '@acng/frontend-stargazer';
import {guard} from '@acng/frontend-rubicon';
import {on, EVENT_CLICK} from '@acng/frontend-bounty/event';

import {userPoolFeature} from '../config/feature.js';
import {activate2fa, deactivate2fa, generate2fa, get2fa} from '../service/http.js';
import {popup} from '@acng/frontend-discovery';
import {SecurityCodeElement} from 'acng/core/widgets/securityCode.js';

const MODULE = 'userPool/widget/2fa-setup';

userPoolFeature.defineWidget(
  'onsw-2fa-setup',
  class extends Widget {
    render() {
      ASSERT: guard(this.nodes, TEMPLATE);
      const {activateButton, setup, qrcode, complete, code, deactivateButton, deactivateCode, setupkey} =
        this.nodes;

      (async () => {
        const {active} = await get2fa(this);
        if (active) {
          this.append(complete);
        } else {
          const {svg, key} = await generate2fa(this);
          qrcode.setAttribute('src', 'data:image/svg+xml;base64,' + svg);
          setupkey.innerHTML = key;
          this.append(setup);
        }
      })();

      complete.remove();
      setup.remove();

      on(deactivateButton, EVENT_CLICK, async () => {
        await deactivate2fa(deactivateCode.value, this);
        popup(this).info(await userPoolFeature.translate('2fa.deactivated'));
        complete.remove();
        this.append(setup);
      });

      on(activateButton, EVENT_CLICK, async () => {
        await activate2fa(code.value, this);
        popup(this).info(await userPoolFeature.translate('2fa.activated'));
        setup.remove();
        this.append(complete);
      });
    }
  }
);

const TEMPLATE = /* @__PURE__ */ NODES({
  activateButton: HTMLButtonElement,
  generateButton: HTMLButtonElement,
  setup: HTMLDivElement,
  complete: HTMLDivElement,
  incomplete: HTMLDivElement,
  qrcode: HTMLImageElement,
  code: SecurityCodeElement,
  deactivateButton: HTMLButtonElement,
  deactivateCode: SecurityCodeElement,
  setupkey: HTMLSpanElement,
});
