/**
 * An `Element` with the `:fsk` attribute is removed if the given condition is not met.
 *
 * @example
 * ```html
 * <div data-fsk="18">
 *   <!-- The div is removed if users fsk level is smaller than 18. -->
 * </div>
 * ```
 *
 * An optional comparison operator can be prefixed to the fsk level to be more specific.
 *
 * ```html
 * <div data-fsk="= 12"></div>
 * <div data-fsk="< 18"></div>
 * <div data-fsk=">= 16"></div>
 * ```
 *
 * ## Formal Syntax
 * ```
 * data-fsk = [ <operator> ] <fsk-level>
 * <operator> = '<' | '<=' | '=' | '>=' | '>'
 * <fsk-level> = '12' | '16' | '18'
 * ```
 *
 * @module data-fsk
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.84.0
 */

import {defineCompileAttribute, defineRegistryAttribute} from '@acng/frontend-stargazer';
import {joinArray, removeNode} from '@acng/frontend-bounty';

import {POSSIBLE_FSK_KEYS} from '../../core/model/fsk.js';
import {fsk} from '../fsk.js';

const MODULE = 'userPool/attribute/fsk';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryAttribute('data-fsk', (name) => {
  DEBUG: if (VERBOSE) console.info(MODULE, 'setup', name);

  /**
   * @type {Record<string, (x: number, y: number) => boolean>}
   */
  const compare = {
    '<=': (x, y) => x <= y,
    '>=': (x, y) => x >= y,
    '<': (x, y) => x < y,
    '>': (x, y) => x > y,
    '=': (x, y) => x === y,
  };

  const syntax = new RegExp(`^(<=|>=|<|>|=)?\\s*(${joinArray(POSSIBLE_FSK_KEYS, '|')})$`);

  defineCompileAttribute(name, (element, value) => {
    const match = value.match(syntax);

    if (!match) {
      DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, {syntax});
      console.error(name, element);

      return;
    }

    const parsedFskLevel = Number(match[2]);
    const usersFskLevel = fsk.get();
    const operator = match[1] ?? '>=';

    DEBUG: if (VERBOSE || element.hasAttribute('debug')) {
      console.debug(MODULE, {element, usersFskLevel, operator, parsedFskLevel});
    }

    if (!compare[operator](usersFskLevel, parsedFskLevel)) {
      DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, 'remove', {element});

      removeNode(element);
    }
  });
});
