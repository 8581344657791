import {guard} from '@acng/frontend-rubicon';
import {isInstanceOf} from '@acng/frontend-bounty/std/value.js';

import {get, post} from '../../core/service/backend.js';
import {PictureSet} from '../../picturePool/model/picture-set.js';
import {Movie} from '../../moviePool/model/movie.js';
import {COMMENTS_DATA, COMMENT_DATA} from '../model/comment.js';

const COMMENTABLE_MOVIE = 'movie';
const COMMENTABLE_PICTURESET = 'pictures';

/**
 * @param {Gallery} gallery
 * @returns {"movie" | "pictures"}
 */
const getCommentableType = (gallery) => {
  if (isInstanceOf(gallery, Movie)) {
    return COMMENTABLE_MOVIE;
  }

  if (isInstanceOf(gallery, PictureSet)) {
    return COMMENTABLE_PICTURESET;
  }

  ASSERT: throw ReferenceError();
};

/**
 * List comments.
 *
 * @param {Element} element
 * @param {Gallery} gallery
 * @param {number} limit
 * @param {number} offset
 * @returns {Promise<Valid<typeof COMMENT_DATA>[]>}
 */
export const listComments = async (element, gallery, limit, offset) => {
  const type = getCommentableType(gallery);
  const id = gallery.set_id;
  const comments = await get(`comments/${type}/${id}?limit=${limit}&offset=${offset}`, element);

  ASSERT: guard(comments, COMMENTS_DATA);
  return comments.chunk;
};

/**
 * @param {Element} element
 * @param {Gallery} gallery
 * @returns {Promise<number>}
 */
export const countComments = async (element, gallery) => {
  const type = getCommentableType(gallery);
  const id = gallery.set_id;
  const data = await get(`comments/${type}/${id}?limit=0&offset=0`, element);

  ASSERT: guard(data, COMMENTS_DATA);
  return data.total;
};

/**
 * Write a comment.
 *
 * @param {Element} element
 * @param {Gallery} gallery
 * @param {string} text
 * @returns {Promise<Valid<typeof COMMENT_DATA>>}
 */
export const createComment = async (element, gallery, text) => {
  const type = getCommentableType(gallery);
  const id = gallery.set_id;
  const comment = await post(`comments/${type}/${id}`, {text}, element);

  ASSERT: guard(comment, COMMENT_DATA);
  return comment;
};
