import {ARRAY, BOOLEAN, ENUM, INTEGER, NULLABLE} from '@acng/frontend-rubicon';
import {OBJECT, RECORD, STRING} from '@acng/frontend-rubicon';
import {FSK_LEVEL} from '../../core/model/fsk.js';

import {hasFeature} from 'acng/core/service/env';

export class User {
  /**
   * @deprecated use authUser context
   */
  guest = true;

  hasGames() {
    return !this.guest && hasFeature('games');
  }

  hasVoiceMessage() {
    return !this.guest && hasFeature('messenger');
  }
}

/**
 * @implements {AuthUserData}
 */
export class AuthUser extends User {
  /**
   * @param {AuthUserData} data
   */
  constructor(data) {
    super();

    ASSERT: {
      this.id = data.id;
      this.pool_id = data.pool_id;
      this.partner_pool = data.partner_pool;
      this.uid = data.uid;
      this.nickname = data.nickname;
      this.email = data.email;
      this.coins = data.coins;
      this.points = data.points;
      this.level = data.level;
      this.fsk = data.fsk;
      this.split_tests = data.split_tests;
      this.preferred_language = data.preferred_language;
      this.signup_wl_id = data.signup_wl_id;
      this.signup_type = data.signup_type;
      this.first_login = data.first_login;
      this.confirmed_at = data.confirmed_at;
      this.doi_at = data.doi_at;
      this.tester = data.tester;
      this.first_login_country = data.first_login_country;
      this.use_utm = data.use_utm;
      this.doi_reward_group = data.doi_reward_group;
      this.is_international = data.is_international;
      this.niche = data.niche;
      this.list_sorting_suffix = data.list_sorting_suffix;
      this.mailing = data.mailing;
      this.active_features = data.active_features;
      this.languages = data.languages;
      this.usergroups = data.usergroups;
    }

    Object.assign(this, data);
    this.guest = false;
  }
}

export const AUTH_USER_DATA = /* @__PURE__ */ (() =>
  OBJECT({
    id: INTEGER,
    pool_id: INTEGER,
    partner_pool: INTEGER,
    uid: STRING,
    nickname: STRING,
    email: STRING,
    coins: INTEGER,
    points: INTEGER,
    level: INTEGER,
    fsk: FSK_LEVEL,
    split_tests: ARRAY(
      OBJECT({
        id: INTEGER,
        group: INTEGER,
      })
    ),
    preferred_language: ENUM(/** @type {const} */ (['de', 'en'])), // circular import POSSIBLE_LANGUAGES
    signup_wl_id: INTEGER,
    signup_type: ENUM(/** @type {const} */ (['doi', 'soi'])),
    first_login: STRING,
    confirmed_at: NULLABLE(STRING),
    doi_at: NULLABLE(STRING),
    tester: BOOLEAN,
    first_login_country: NULLABLE(STRING),
    use_utm: BOOLEAN,
    doi_reward_group: ENUM(/** @type {const} */ (['vip_points', 'free_coins'])),
    is_international: BOOLEAN,
    niche: STRING,
    list_sorting_suffix: STRING,
    mailing: OBJECT({
      transaction: BOOLEAN,
      informer: BOOLEAN,
      informer_ratio: INTEGER,
      bonus: BOOLEAN,
      newsletter: BOOLEAN,
    }),
    active_features: RECORD(ENUM(/** @type {const} */ (['voice_messages', 'messenger'])), BOOLEAN),
    languages: OBJECT({
      selected: NULLABLE(STRING),
      detected: NULLABLE(STRING),
    }),
    usergroups: ARRAY(OBJECT({name: STRING})),
  }))();

export const EVENTBUS_USER = /* @__PURE__ */ (() =>
  OBJECT({
    type: ENUM(/** @type {const} */ (['user'])),
    user: OBJECT({
      coins: INTEGER,
      level: INTEGER,
      points: INTEGER,
      fsk: FSK_LEVEL,
    }),
    timestamp_ms: INTEGER,
  }))();
