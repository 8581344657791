import {size, splice} from '@acng/frontend-bounty/std/array.js';
import {guard} from '@acng/frontend-rubicon';

import {cdn} from '../../core/service/backend.js';
import {authUser, hasFeature, portal} from '../../core/service/env.js';
import {FEED_DATA} from '../model/feed.js';
import {checkPinById, promise as pinsAreAvailable} from 'acng/pin/service.js';

/**
 * Append the `cacheBust` to amateur feed requests, so that it is
 * at least as up-to-date as the last global feed.
 */
let cacheBust = '';

const apiPrefix = 'amateur-profile/feed';

/**
 * @type {Partial<Record<string, boolean>>}
 */
const enabled = {
  Weblog: true,
  Movie: hasFeature('moviePool'),
  Picture: hasFeature('picturePool'),
};

/**
 * Remove entries of hidden features.
 *
 * @param {FeedItem[]} list
 * @returns {void}
 */
const removeHidden = (list) => {
  for (let i = size(list) - 1; i >= 0; i--) {
    if (!enabled[list[i].type]) {
      splice(list, i, 1);
    }
  }
};

/**
 * Get an amateur feed list from the ACNG backend.
 *
 * @param {Element} element is for the loader and the debug attribute.
 * @param {Amateur["id"]} amateurId is appended to the query string.
 * @returns {Promise<FeedItem[]>} Feed list of the amateur
 */
export const listOfAmateur = async (element, amateurId) => {
  const data = await cdn(`${apiPrefix}/${amateurId}`, element, {cacheBust});
  ASSERT: guard(data, FEED_DATA, 'cdn response with amateur feed list');

  removeHidden(data);

  return data;
};

/**
 * Get the feed item list of all amateurs ordered by descending date.
 *
 * Origin is the ACNG backend via CDN.
 *
 * @param {Element} element is for the loader and the debug attribute.
 * @param {"all" | "posts" | "media" | "favorites"} filter the list by type.
 * @param {boolean} latest includes only the latest feed entry of each amateur.
 * @returns {Promise<FeedItem[]>} Feed list
 */
export const list = async (element, filter, latest) => {
  const feed = await cdn(`${apiPrefix}/global`, element, {
    // limit: 1000, // default is 1000
    filter: filter == 'favorites' ? 'all' : filter,
    latest: `${latest}`,
    niche: authUser?.niche ?? portal.niche,
    fsk: `${authUser?.fsk ?? portal.guest_fsk}`,
  });
  ASSERT: guard(feed, FEED_DATA, 'cdn response with global feed list');

  if (size(feed)) {
    cacheBust = `${feed[0].date}`;
  }

  removeHidden(feed);

  if (filter == 'favorites' && hasFeature('pin')) {
    await pinsAreAvailable;
    feed.filter((item) => checkPinById(item.userId));
  }

  return feed;
};
