const ngComponentName = 'onswMessengerMessageBox';
export default ngComponentName;
/**
 * @type {angular.IComponentOptions}
 */
export const messagesComponent = {
  bindings: {
    voicemessageInfo: '<',
  },
  templateUrl: '/template/messenger.messages', // TODO template override exists!
  controller: ['$scope', '$element', '$compile', '$attrs', controller],
};

import angular from 'angular';
import {add, has, remove, toggle} from '@acng/frontend-bounty/style/element.js';
import {off, on} from '@acng/frontend-bounty/dom/event.js';
import {setTimeout} from '@acng/frontend-bounty/dom/window.js';
import {WatchGroup} from '@acng/frontend-relativity';
import {CTX_VALUE} from '@acng/frontend-relativity/minify';

import {MESSAGE_DATA, Message} from '../model/message';
import {listen, publish} from 'acng/core/context/event-bus.js';
import {ctxEventBusUser} from 'acng/userPool/context/event-bus-user.js';
import {EVENTBUS_MESSAGE_DATA} from '../model/message.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {getDialog} from '../service/dialogs.js';
import {inject} from 'acng/core/service/ng.js';
import {getFallbackImageUrl} from 'acng/layout/service/fallback-image.js';
import {ARRAY, CONST, OBJECT, guard} from '@acng/frontend-rubicon';
import {UHURA_USER_ID} from '../model/user';

const watch = WatchGroup('onsw-messenger-message-box', ctxAmateur, ctxEventBusUser);

/**
 * @this {angular.IController & {voiceMessageInfo: boolean}}
 * @param {Scope} scope
 * @param {JQLite} element
 * @param {angular.ICompileService} $compile
 * @param {angular.IAttributes} $attrs
 */
function controller(scope, element, $compile, $attrs) {
  const host = element[0];
  const scrollBox = angular.isDefined($attrs.disableScroll)
    ? null
    : host.closest('.ons-scroll')?.querySelector(':scope > .box');
  const dialogBox = host.closest('.messenger-dialog');

  function blubb() {
    if (!dialogBox) {
      return;
    }

    const scrollParent = scrollBox?.parentElement;

    if (scrollParent && has(scrollParent, 'scroll-min')) {
      remove(dialogBox, 'short');
      off(scrollBox, 'scroll', blubb);
    }
  }

  scope.$watch('messages.length', () =>
    setTimeout(() => scrollBox?.scrollTo({top: scrollBox.scrollHeight, behavior: 'smooth'}), 100)
  );

  const offMessage = listen('message', (data, reply) => {
    ASSERT: guard(data, EVENTBUS_MESSAGE_DATA);

    if (!data.message) {
      return;
    }

    const amateur = ctxAmateur[CTX_VALUE](host);

    if (!scope.messages || data.partner.id !== amateur?.id) {
      return;
    }

    const msg = new Message(amateur, data.message);
    if (msg.sender.id === amateur.id) {
      msg.translate().then(() => {
        scope.messages?.push(msg);
        reply(markDialogAsRead(amateur.id));
      });
    } else {
      msg.ready = true;
      scope.messages.push(msg);
    }
  });

  watch(element[0], (element, [amateur, user]) => {
    toggle(element, 'offline', !user);
    delete scope.messages;
    remove(element, 'ready'); // TODO ready (animation)
    this.amateur = amateur;

    if (!user || !amateur) {
      return;
    }

    if (dialogBox && scrollBox) {
      on(scrollBox, 'scroll', blubb);
      add(dialogBox, 'short');
    }

    const dialog = getDialog(amateur.id, true);
    scope.voicemessageInfo = this.voicemessageInfo;
    publish({type: 'messages', partner: {product_id: user.partner_pool, id: amateur.id}});
  });

  const offMessages = listen('messages', (data, reply) => {
    ASSERT: guard(data, EVENTBUS_MESSAGES_DATA);

    const amateur = ctxAmateur[CTX_VALUE](host);

    if (data.partner.id !== amateur?.id) {
      return;
    }
    scope.messages = data.messages.map((messageData) => {
      const message = new Message(amateur, messageData);
      message.translate();
      return message;
    });

    reply(markDialogAsRead(amateur.id));
  });

  this.$onDestroy = () => {
    if (scrollBox) {
      off(scrollBox, 'scroll', blubb);
    }

    offMessages();
    offMessage();
  };

  scope.displayAttachment = ($event, url) => {
    if (inject('user').fsk < 18) {
      url = getFallbackImageUrl();
    }

    if (/\.(?:jpe?g|png)$/i.test(url)) {
      $compile('<image-overlay src="' + url + '"></image-overlay>')(scope).appendTo(angular.element('body'));
      $event.preventDefault();
    }
  };
}

/**
 * @param {string} partner
 */
const markDialogAsRead = (partner) => ({
  type: 'dialog.read',
  partner: {
    product_id: 2,
    id: partner,
  },
});

/**
 * @typedef {angular.IScope & {
 *   messages?: Message[];
 *   displayAttachment(evt: Event, url: string): void;
 *   voicemessageInfo?: boolean;
 * }} Scope
 */

const EVENTBUS_MESSAGES_DATA = /* @__PURE__ */ OBJECT({
  type: /* @__PURE__ */ CONST('messages'),
  partner: UHURA_USER_ID,
  messages: /* @__PURE__ */ ARRAY(MESSAGE_DATA),
});
