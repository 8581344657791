import {size} from '@acng/frontend-bounty/std/array.js';
import {floor, rand} from '@acng/frontend-bounty/std/number.js';
import {dummyImageMap, media} from 'acng/core/service/env.js';

/**
 * Select a random image in the given format from a whitelabel mapped list.
 *
 * Default to a "default" format if the mapping is not defined for the format.
 *
 * @param {ImageFormat} [format]
 * @returns {string} the full source url of a fallback image
 */
export function getFallbackImageUrl(format) {
  const images = (format && dummyImageMap[format]) ?? dummyImageMap['default'];
  const image = images[floor(rand(size(images)))];

  return `${media.assets_ext}/img/dummy/${image}`;
}
