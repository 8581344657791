/**
 * Continuously provide the users vip points value to the ACNG frontend.
 *
 * @see [VIP-Points Button](../widget/points.js)
 * @see [Latest Achievements List](../widget/latest.js)
 *
 * @module
 */

import {GlobalActor, GlobalWatch} from '@acng/frontend-relativity';
import {guard} from '@acng/frontend-rubicon';

import {listen} from 'acng/core/context/event-bus.js';
import {ctxAuthUser} from 'acng/userPool/context/auth-user.js';
import {EVENTBUS_USER} from '../../userPool/model/user.js';
import {ctxVipPoints} from '../context/points.js';

const MODULE = 'achievement/service/sock';
const VERBOSE = false;

(() => {
  const broadcast = GlobalActor(ctxVipPoints);

  GlobalWatch(ctxAuthUser)((user) => broadcast(user?.points ?? NaN));

  listen('user', (data) => {
    ASSERT: guard(data, EVENTBUS_USER);
    DEBUG: if (VERBOSE) console.info(`${MODULE}`, data);

    broadcast(data.user.points);
  });

  /*
  let fakePoints = 0;
  (function fake() {
    fakePoints += Math.round(Math.random() * 50) + 1;
    broadcast(fakePoints);
    setTimeout(() => {
      fake();
    }, Math.random() * 25000 + 500);
  })();
  */
})();
