import {HTMLElement, con, def, dis} from '@acng/frontend-bounty/dom/custom.js';
import {Watch} from '@acng/frontend-relativity';
import {IS, guard} from '@acng/frontend-rubicon';
import {Engine, defineRegistryElement} from '@acng/frontend-stargazer';

import {inject} from '../../core/service/ng.js';
import {ctxGallery} from '../../core/context/gallery';
import {Movie} from '../model/movie.js';

export const MOVIE_DETAIL = 'movie-detail';

defineRegistryElement(MOVIE_DETAIL, (name) => {
  const watchGallery = Watch(name, ctxGallery);
  const transclude = Engine.Transclude(name);
  // @ts-expect-error
  const unitFilter = inject('unitFilter');

  class MovieDetail extends HTMLElement {
    #linker = Engine.root;

    [con]() {
      const linker = transclude(this);

      watchGallery(this, (element, movie) => {
        ASSERT: guard(movie, IS(Movie));

        linker.toElement(element, {
          description: movie.getDescription(),
          width: movie.width,
          height: movie.height,
          playtime: unitFilter(movie.playtime, 'sec'),
          filesize: unitFilter(movie.filesize, 'byte'),
        });
      });

      this.#linker = linker;
    }

    [dis]() {
      this.#linker.disconnect();
    }
  }

  def(name, MovieDetail);
});
