/**
 * TODO move file
 * @module acng/userPool/fsk
 */
import {getLocale} from 'acng/locale/model/locale.js';
import {startUpLanguage} from 'acng/core/service/env.js';
import {fsk} from './fsk.js';
/**
 * @typedef {{
 *   [key in import('./fsk').FskString]?: {
 *     [key in Language]: string | undefined
 *   }
 * } | null} FskTrans
 */

/**
 * @typedef {(obj: FskTrans) => string} Filter
 */

/**
 * @param {FskTrans | null | undefined} input
 * @returns {string | undefined} -
 */
export function fskTrans(input) {
  const f = fsk.get();
  const l = getLocale();

  let res = input?.[f]?.[l],
    hitF = false,
    hitL = false,
    fallback;

  if (!res && input) {
    for (const [cFsk, cLngs] of Object.entries(input)) {
      const cf = parseInt(cFsk);
      if (hitF && hitL) break;
      if (cf <= f) {
        for (const [cLng, val] of Object.entries(cLngs)) {
          if (hitL) break;
          if (val && cLng === l) {
            if (cf == f) {
              hitF = true;
            }
            hitL = true;
            res = val;
          } else if (val && cLng === startUpLanguage) {
            res = val;
          } else if (val) {
            fallback = val;
          }
        }
      }
    }
  }

  return res ?? fallback;
}
