import {ENUM} from '@acng/frontend-rubicon';

export const POSSIBLE_IMAGE_FORMATS = /** @type {const} */ ([
  '160',
  '300',
  '320',
  '400',
  '500',
  '600',
  '800',
  '900',
  '1000',
  '1200',
  'large',
  'small',
  'svga',
  'xsignup',
  'mini',
  'th',
  'thumb',
  'fsk',
  'status',
  'pw',
  'avatar',
  'sm',
  'medium',
  'preview',
  'portrait',
  'xl',
  'xxl',
  'xga',
]);

export const IMAGE_FORMAT = /* @__PURE__ */ ENUM(POSSIBLE_IMAGE_FORMATS);
