/**
 * Validate common data.
 *
 * @see [Rubicon](https://docs.devac.de/rubicon/)
 */

import {
  AND,
  ARRAY,
  BOOLEAN,
  CONST,
  FLOAT,
  INTEGER,
  NULLABLE,
  OBJECT,
  OPTIONAL,
  OR,
  RECORD,
  STRING,
} from '@acng/frontend-rubicon';
import {AUTH_USER_DATA} from '../../userPool/model/user.js';
import {IMAGE_FORMAT} from '../model/image.js';
import {LANGUAGE, TRANSLATIONS} from '../model/lang.js';
import {FSK_KEY} from '../model/fsk.js';
import {PORTAL} from '../model/portal.js';

export {guard} from '@acng/frontend-rubicon';

/**
 * Validate abstract gallery data. Use in `Movie` and `PictureSet`.
 *
 * @see [Movie](../../moviePool/model/movie.js)
 * @see [PictureSet](../../picturePool/model/picture-set.js)
 */
export const GALLERY_DATA = /* @__PURE__ */ OBJECT({
  set_id: INTEGER,
  nickname: STRING,
  user_id: INTEGER,
  published: STRING,
  price: INTEGER,
  blocked: OPTIONAL(BOOLEAN),
  votes: INTEGER,
  rating: FLOAT,
  category_codes: ARRAY(STRING),
  image_fsk12: OPTIONAL(STRING),
  image_fsk16: OPTIONAL(STRING),
  image_fsk18: OPTIONAL(STRING),
  media_rating: OPTIONAL(FLOAT),
  media_rating_test: OPTIONAL(FLOAT),
  name: TRANSLATIONS,
  description: TRANSLATIONS,
});

const BUILD_INFO = /* @__PURE__ */ OBJECT({
  enableAsserts: BOOLEAN,
  throwFailedAsserts: BOOLEAN,
  enableDebug: BOOLEAN,
  enableEventSource: BOOLEAN,
  eventSourceUrl: STRING,
});

const MEDIA = /* @__PURE__ */ OBJECT({
  content: OBJECT({
    movies: STRING,
    pictures: STRING,
    items: STRING,
  }),
  assets: STRING,
  assets_ext: STRING,
  user: STRING,
});

export const ENV = /* @__PURE__ */ OBJECT({
  BUILD: BUILD_INFO,
  authUser: NULLABLE(AUTH_USER_DATA),
  media: MEDIA,
  FEATURES: ARRAY(STRING),
  INHERIT: ARRAY(STRING),
  portal: PORTAL,
  hasMyPortal: BOOLEAN,
  startUpCountry: LANGUAGE,
  startUpLanguage: LANGUAGE,
  startUpFsk: FSK_KEY,
  VERSION: STRING,
  API_cdn: STRING,
  API_origin: STRING,
  SWFile: STRING,
  isMobile: BOOLEAN,
  SONUS: STRING,
  UTM: STRING,
  PLAYPAL_STATIC: STRING,
  ARCHIMEDES: STRING,
  TROI: STRING,
  OBRIEN: STRING,
  HELLO: STRING,
  RATATOSKR: STRING,
  LaForge: STRING,
  passwordResetToken: STRING,
  supportEmail: STRING,
  webmasterLink: STRING,
  dummyImageMap: AND(OBJECT({default: ARRAY(STRING)}), RECORD(IMAGE_FORMAT, OPTIONAL(ARRAY(STRING)))),
  livecamStartFactor: CONST('1'),
  webmailDomains: RECORD(STRING, ARRAY(STRING)),
  sid: STRING,
  paymentConfig: OBJECT({
    flow: CONST('productsFirst'),
    packages: OBJECT({
      sorting: OBJECT({
        key: STRING,
        order: STRING,
      }),
    }),
  }),
  freeCoinsConfig: OBJECT({value: INTEGER}),
  TagToTopicMapping: RECORD(STRING, STRING),
  SystemCdnUsage: BOOLEAN,
});
