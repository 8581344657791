import {Element, Engine, NODES, task} from '@acng/frontend-stargazer';
import {closest, createSvg, setText} from '@acng/frontend-bounty';
import {all as whenAll} from '@acng/frontend-bounty/std/control.js';
import {replace} from '@acng/frontend-bounty/dom/move.js';
import {toggle} from '@acng/frontend-bounty/style/element.js';
import {CHANGE, on} from '@acng/frontend-bounty/dom/event.js';
import {all} from '@acng/frontend-bounty/dom/element.js';
import {popup} from '@acng/frontend-discovery';
import {IS, NULLABLE, guard} from '@acng/frontend-rubicon';

import {achievementFeature} from '../config/feature.js';
import {inject} from 'acng/core/service/ng';
import {LAYOUT_CLASS_ITEM} from 'acng/layout/config/css-classes.js';

import vipSvg from 'assets/basic/img/vip.svg';
import {asset} from 'acng/core/service/env.js';
import {repeatElement} from 'acng/layout/service/repeat.js';
import {cdn, post} from 'acng/core/service/backend.js';
import {PACKAGE_DATA, PACKAGE_DATA_LIST} from '../model/package-data.js';
import {HTMLElement, con, def, dis} from '@acng/frontend-bounty/dom/custom.js';
import {globalValue} from '@acng/frontend-relativity';
import {ctxVipPoints} from '../context/points.js';
import {onClick} from 'acng/layout/service/worker.js';
import {iterate} from '@acng/frontend-bounty/object.js';

/**
 * @group DOM Element
 */
export const ACHIEVEMENT_REDEEM = 'onsw-points-to-coins';

Element(ACHIEVEMENT_REDEEM, (name) => {
  /**
   * @type {HTMLElement}
   */
  let ICON;

  /**
   * @type {Package[]}
   */
  let packages;

  const transclude = Engine.Transclude(name);

  def(
    name,
    class extends HTMLElement {
      /**
       * @type {Engine | undefined}
       */
      #linker;

      [con]() {
        const linker = transclude(this);

        update(this, linker);
        this.#linker = linker;
      }

      [dis]() {
        this.#linker?.disconnect();
      }
    }
  );

  /**
   * @param {HTMLElement} element
   * @param {Engine} linker
   */
  const update = async (element, linker) => {
    linker.toElement(element);
    ASSERT: {
      guard(
        linker.nodes,
        NODES({
          repeat: HTMLElement,
          radio: HTMLInputElement,
          button: HTMLButtonElement,
          name: Text,
          descr: Text,
          icon: Text,
        })
      );
    }

    const {name, descr, icon, repeat, radio, button} = linker.nodes;
    const points = globalValue(ctxVipPoints);

    replace(icon, ICON);

    repeatElement(repeat, packages, (item) => {
      const isAllowed = points >= item.points;

      toggle(repeat, 'notAvailable', !isAllowed);
      radio.disabled = !isAllowed;
      radio.value = `${item.value}`;
      radio.name = 'name';
      setText(name, item.label);
      setText(descr, item.descr);
    });

    /** @type {string} */
    let selectedPackageName;

    // TODO add bounty disable & enable
    on(element, CHANGE, (evt) => {
      ASSERT: guard(evt.target, IS(HTMLInputElement));
      selectedPackageName = evt.target.value;
      button.disabled = false;
      closest(
        evt.target,
        `.${LAYOUT_CLASS_ITEM}`,
        (item) => iterate(all(element, `.${LAYOUT_CLASS_ITEM}`), (elt) => toggle(elt, 'active', elt == item)),
        element
      );
    });

    onClick(button, async () => {
      const pkg = await post(
        'achievements/redeemCoins',
        {name: selectedPackageName},
        element,
        async (error) => {
          if (error.code === 4002) {
            inject('payment').overlay('payment.buyReasonPoints');
            return null;
          } else {
            throw error;
          }
        }
      );
      ASSERT: guard(pkg, NULLABLE(PACKAGE_DATA));

      if (pkg) {
        const msg = await achievementFeature.translate('pointsRedeemed', {
          coins: `${pkg.coins}`,
          points: `${pkg.points}`,
        });
        await popup(element).info(msg);

        update(element, linker);
      }
    });
  };

  const loadItems = async () => {
    const icon = await createSvg(asset(vipSvg));
    const packagesData = await cdn('achievements/packages');
    ASSERT: guard(packagesData, PACKAGE_DATA_LIST);

    packages = await whenAll(
      packagesData.map(async (pkg) => ({
        points: pkg.points,
        value: pkg.name,
        label: await achievementFeature.translate('packages.value', {coins: `${pkg.coins}`}),
        descr: await achievementFeature.translate('packages.text', {points: `${pkg.points}`}),
      }))
    );
    ICON = icon;
  };

  return [(element, _p, compiler) => task(compiler, loadItems()), ,];
});

/**
 * @private
 * @typedef {{
 *   value: string;
 *   points: number;
 *   label: string;
 *   descr: string;
 * }} Package
 */
