/**
 * Put a generic submit action on a form.
 *
 * `[data-sumbit="scopeFn"]` adds a "submit" event listener to the form.
 * The listener has to be defined in the `scope` of the specific render call.
 *
 * @example
 * ```html
 * <form data-submit="submit">
 *   ...
 * </form>
 * ```
 *
 * @see [Debounce](../service/worker.js)
 *
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.86.0
 */

import {defineRenderAttribute} from '@acng/frontend-stargazer';
import {IS, guard} from '@acng/frontend-rubicon';
import {onSubmit} from '../service/worker.js';

defineRenderAttribute('data-submit', (form, listenerKey, scope) => {
  ASSERT: guard(scope?.[listenerKey], IS(Function));
  onSubmit(form, scope[listenerKey].bind(scope));
});
