import angular from 'angular';
import {get, getByNickname} from '../service/amateur.js';

angular
  .module('amateurPool')

  .directive('onsdAmateurs', [
    'PimpMyRide',
    'AmateurFilter',
    '$q',
    (PimpMyRide, AmateurFilter, $q) =>
      PimpMyRide(
        'Amateurs',
        ['filterId', 'limit', 'reload', 'trigger'],
        /**
         * @param {[string, number, number, string]} v
         * @param {angular.IScope & {filter: import('acng/amateurPool/factory/Filter').Filter, reload?: number}} scope
         */
        (v, scope) => {
          if (!scope.filter) {
            scope.$watch('filterId', (id) => {
              scope.filter = AmateurFilter.get(id);
              scope.filter.addEventListener('change', changeFilter);
              scope.$on('$destroy', () => scope.filter.removeEventListener('change', changeFilter));
              function changeFilter() {
                scope.reload = (scope.reload || 0) + 1;
              }
            });
            scope.reload = 0;
            return $q.resolve(null);
          }
          if (v[3]) {
            scope.filter.trigger = v[3];
          }
          return scope.filter.list(20000);
        },
        /**
         * @param {[string, number, number, string]} v
         */
        (v) => v[1] > 0 && !!v[0]
      ),
  ])

  .directive('onsdAmateur', [
    'PimpMyRide',
    (PimpMyRide) =>
      PimpMyRide(
        'Amateur',
        ['amateurId', 'amateurName'],
        /**
         * @param {[string, string]} v
         */
        (v) => v[0] ? get(v[0]) : getByNickname(v[1]),
        /**
         * @param {[string, string]} v
         */
        (v) => v[0] || v[1]
      ),
  ]);
