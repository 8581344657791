/**
 * Put a generic click action on the element.
 *
 * `[data-click]` adds a "click" event listener to the element.
 * The listener has to be defined in the `scope` of the specific render call.
 *
 * @example
 * ```html
 * <span data-click="close">Close</span>
 * ```
 *
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.86.0
 */

import {defineRenderAttribute} from '@acng/frontend-stargazer';
import {IS, guard} from '@acng/frontend-rubicon';
import {onClick} from '../service/worker.js';

defineRenderAttribute('data-click', (element, listenerKey, scope) => {
  ASSERT: guard(scope?.[listenerKey], IS(Function));
  onClick(element, scope[listenerKey].bind(scope));
});
