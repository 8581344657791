import {TITLE, set} from '@acng/frontend-bounty/dom/attribute.js';
import {IS, guard} from '@acng/frontend-rubicon';
import {defineCompileAttribute} from '@acng/frontend-stargazer';

/**
 * @group DOM Attribute
 */
export const DATA_TITLE = 'data-title';

defineCompileAttribute(DATA_TITLE, (element) => {
  ASSERT: guard(element, IS(HTMLElement));
  setTitleToInnerText(element);
});

/**
 * @param {HTMLElement} element
 */
export const setTitleToInnerText = (element) => {
  set(element, TITLE, element.innerText ?? '');
};
