import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';
import {guard} from '@acng/frontend-rubicon';
import {ctxLocale, setLocale} from '../model/locale';

import {swapClass} from 'acng/layout/service/style.js';
import {STYLE_ACTIVE} from 'acng/layout/config/style';
import {body, onClick} from '@acng/frontend-bounty';
import {Watch} from '@acng/frontend-relativity';
import {LANGUAGE} from '../../core/model/lang.js';

const MODULE = 'locale/attributes/set';

defineRegistryAttribute('data-click-set-locale', (name) => {
  const watch = Watch(`[${name}]`, ctxLocale);

  defineRenderAttribute(name, (element, countryCode) => {
    ASSERT: guard(countryCode, LANGUAGE, MODULE);

    watch(element, (element) => swapClass(element, STYLE_ACTIVE, body.lang == countryCode));

    onClick(element, () => {
      if (body.lang != countryCode) {
        setLocale(countryCode);
      }
    });
  });
});
