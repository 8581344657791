import {Engine, defineCustomWidget} from '@acng/frontend-stargazer';
import {provide} from '@acng/frontend-relativity';
import {connectPopupController, createDropdown} from '@acng/frontend-discovery';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';
import {addClass, append, createDiv, getDataAttribute, onClick} from '@acng/frontend-bounty';
import {ENUM, guard} from '@acng/frontend-rubicon';

import {friendFeature} from '../config/feature.js';
import {ctxFriendship, error, friendships} from '../context/friendships.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {t} from 'acng/locale/config/translate.js';
import {POSSIBLE_STATES} from '../service/friendship.js';
import {Warpdrive} from 'acng/layout/service/warpdrive.js';
import {get} from '../../amateurPool/service/amateur.js';

defineCustomWidget(
  friendFeature,
  'onsw-user-friends',

  /**
   * @extends Warpdrive<string>
   */
  class extends Warpdrive {
    link() {
      ctxFriendship[CTX_OBSERVE](this, () => this.warp());
    }

    /**
     * @param {number} offset
     * @param {Engine} renderEmpty
     */
    async more(offset, renderEmpty) {
      if (error) {
        append(
          this,
          renderEmpty.toElement(createDiv(), {
            noFriendsText: error.message,
          })
        );
        return [];
      }

      const friendStatus = getDataAttribute(this, 'status');
      ASSERT: guard(friendStatus, ENUM(POSSIBLE_STATES));

      if (!offset && !friendships[friendStatus].length) {
        append(
          this,
          renderEmpty.toElement(createDiv(), {
            noFriendsText: await t(`friend.${friendStatus}_none`).catch(() => ''),
          })
        );
      }

      return offset ? [] : friendships[friendStatus];
    }

    /**
     * @param {string} amateurId
     * @param {Engine} renderFriendship
     * @param {import('@acng/frontend-discovery').Loader} loader
     */
    async render(amateurId, renderFriendship, loader) {
      const amateur = await get(amateurId);
      const image = await amateur.getImage('800');
      const {target} = loader;

      if (!target) {
        return;
      }

      const scope = {
        nickname: amateur.getNickname(),
        age: amateur.age,
        gotoSedcard: () => amateur.goto(),
      };

      provide(ctxAmateur, amateur, target);
      renderFriendship.toElement(target, scope);
      addClass(image, 'image');
      onClick(image, scope.gotoSedcard);
      target.prepend(image);

      connectPopupController(target);
      createDropdown(target);
    }
  },
  [ctxFriendship]
);
