import angular from 'angular';
import {createDiv, append, on} from '@acng/frontend-bounty';
import {getFallbackImageUrl} from 'acng/layout/service/fallback-image.js';

angular.module('core')

  .directive('onswImage', ['Widget', 'media', '$location', 'onsOverlay', (Widget, media, $location, onsOverlay) => ({
    scope: {
      imageUrl: '<',
      imageClick: '&?',
      fallbackUrl: '<?'
    },
    link: function (scope, element) {

      const widget = new Widget(scope, element, 'ons-image');

      let closeMsg = null;

      function busy(fn) {

        if (closeMsg) {
          closeMsg();
          closeMsg = null;
        }

        widget.ifNotBusy(fn)
          .catch(err => closeMsg = widget.notify(err));
      }

      const box = createDiv('box');
      append(element[0], box);

      let img;

      scope.$on('$destroy', () => {
        if (img) {
          img.src = '';
          img = null;
        }
      });

      scope.$watch('imageUrl', function (url) {
        box.style.backgroundImage = '';
        if (!url) {
          return;
        }
        widget.clear().busy((resolve, reject) => {
          img = new Image();
          img.src = url;
          img.onload = () => resolve(url);
          img.onerror = reject;
        }).catch(() => {
          return scope.fallbackUrl ?
            scope.fallbackUrl :
            getFallbackImageUrl('900');
        }).then(url => {
          img = null;
          box.style.backgroundImage = 'url(' + url + ')';
        });
      });
      if (scope.imageClick) {
        on(box, 'click', () => scope.$apply(() => scope.imageClick({
          busy: busy,
          goto: path => $location.path(path),
          openOverlay: hookname => onsOverlay.create(hookname).disableBackdrop().open()
        })));
      }
    }
  })]);
