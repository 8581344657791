/**
 *
 */

// bounty, rubicon
import {guard} from '@acng/frontend-rubicon';
// enterprise
import {getLocale} from 'acng/locale/model/locale';
import {get} from '../backend.js';
import {availableValidators, VALIDATOR_DATA} from '../../model/api-validator.js';
import {DiscardingQueue} from '@acng/frontend-bounty/timing';
import {VERBOSE} from '../debug.js';

const MODULE = 'core/service/backend/validation';

/**
 * @typedef ApiValidatorResponseData
 * @property {number[]} [failed]
 * @property {string[]} [messages]
 * @property {string[]} [rules]
 * @property {string[]} [suggestions]
 * @property {boolean} valid
 */

/**
 * @type {Array<[RegExp, (arg: string, value: string) => boolean]>}
 */
const knownRules = [
  [/^notExists:(.+)$/, () => true],
  [/^mailCheck$/, () => true],
  [/^regex:\/(.+)\/([^\/]*)$/, (arg, value) => !value || !!value.match(arg)],
  [/^min:(\d+)$/, (arg, value) => !value || value.length >= parseInt(arg)],
  [/^max:(\d+)$/, (arg, value) => !value || value.length <= parseInt(arg)],
  [/^required$/, (_arg, value) => !!value],
  [/^email$/, (_arg, value) => !value || REGEX_EMAIL.test(value)],
  [
    /^blacklist:(.*)$/,
    (arg, value) => {
      for (const s of arg.split(',')) {
        if (s && value.match(`^${s.replace('*', '.*')}$`)) {
          return false;
        }
      }
      return true;
    },
  ],
];

const REGEX_EMAIL = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

/**
 * @param {string} rule
 * @param {string} value
 */
const checkRule = (rule, value) => {
  for (const [checkRule, checkValue] of knownRules) {
    const match = checkRule.exec(rule);

    if (match) {
      return checkValue(match[1], value);
    }
  }

  DEBUG: console.error(`${MODULE} Unknown validator rule "${rule}"`, {value, knownRules});

  return true;
};

/**
 * @param {string[]} rules
 * @param {string} value
 * @returns {ApiValidatorResponseData}
 */
const checkOffline = (rules, value) => {
  const failed = [];

  for (let i = 0; i < rules.length; i++) {
    if (!checkRule(rules[i], value)) {
      failed.push(i);
    }
  }

  return {
    valid: !failed.length,
    rules,
    failed,
  };
};

/**
 * @type {ApiValidatorResponseData}
 */
const success = {
  valid: true,
};

/**
 * @param {HTMLInputElement} inputElement
 * @param {availableValidators[number]} apiPath
 */
export const Validator = (inputElement, apiPath) => {
  let seq = 0;

  /**
   * @type {ApiValidatorResponseData["rules"] | undefined}
   */
  let rules;

  const debounce = DiscardingQueue(500);

  DEBUG: if (VERBOSE(inputElement)) {
    debounce.enableDebug(MODULE);
  }

  /**
   * @param {string} value
   * @param {number} seqNo
   */
  const check = async (value, seqNo) =>
    debounce(
      /**
       * @returns {Promise<ApiValidatorResponseData>}
       */
      async () => {
        if (seqNo !== seq) return success;
        let data;

        try {
          data = await get(`${apiPath}?locale=${getLocale()}&value=${value}`, inputElement);

          ASSERT: guard(data, VALIDATOR_DATA);

          rules = data.rules;

          return data;
        } catch (err) {
          console.error('api validation', err);
          return success;
        }
      }
    );

  /**
   * @param {string} value
   */
  return async (value) => {
    seq++;

    if (rules) {
      const res = checkOffline(rules, value);
      if (!res.valid) {
        return res;
      }
    }

    return check(value, seq).catch(() => success);
  };
};
