import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';
import {GlobalWatch} from '@acng/frontend-relativity';
import {ARRAY, BOOLEAN, CONST, ENUM, OBJECT, guard} from '@acng/frontend-rubicon';

import {createAudio} from '@acng/frontend-bounty';
import {amateurVisitsUser} from 'acng/amateurPool/service/profile-visit.js';
import {listen, publish} from 'acng/core/context/event-bus.js';
import {ctxEventBusUser} from 'acng/userPool/context/event-bus-user.js';
import {ctxNewMessage} from '../context/message.js';
import {Dialog} from '../model/dialog.js';
import {ngDigest} from 'acng/core/service/ng.js';
import {ctxUnreadDialogs} from '../context/unread-dialogs.js';
import {UHURA_USER_ID} from '../model/user.js';
import {TIMESTAMP_MS} from '../../core/model/time.js';

/**
 * @type {Map<string, import('../model/dialog').Dialog>}
 */
const byAmateur = new Map();

/**
 * @type {import('../model/dialog').Dialog[] & {changed: number}}
 */
export const dialogs = (() => {
  /** @type {any} */
  const res = [];
  res.changed = 0;
  return res;
})();

export let online = false;
export let newMessagesCount = 0;

/**
 * @param {string} dialogId
 * @param {boolean} [pushToContacts]
 */
export const getDialog = (dialogId, pushToContacts = false) => {
  let dialog = dialogs.find((d) => d.id == dialogId);
  if (!dialog) {
    dialog = new Dialog(dialogId);
    byAmateur.set(dialogId, dialog);
    if (pushToContacts) {
      dialogs.push(dialog);
      sort();
    }
  }
  return dialog;
};

/**
 * @deprecated check if needed
 * @param {string | Dialog} target
 * `true` when dialog is flagged "new", `false` otherwise
 */
export const dialogIsNew = (target) => {
  let id;
  if (target instanceof Dialog) {
    id = target.id;
  } else {
    id = target;
  }
  return byAmateur.get(id)?.new;
};

const sort = () => {
  let changed = 0;
  dialogs.sort((a, b) => {
    let diff = b.lastActivity.getTime() - a.lastActivity.getTime();
    if (diff < 0) {
      changed = 1;
    }
    return diff;
  });
  dialogs.sort((a, b) => {
    let diff = b.activated - a.activated;
    if (diff < 0) {
      changed = 1;
    }
    return diff;
  });
  dialogs.changed += changed;
};

GlobalWatch(ctxEventBusUser)((user) => {
  if (!user) {
    online = false;
    dialogs.length = 0;
    dialogs.changed++;
  } else {
    online = true;
    newMessagesCount = 0;
    publish({type: 'dialogs'});
  }
});

GlobalWatch(ctxNewMessage)((message) => {
  if (!message) {
    return;
  }

  const dialog = getDialog(message.amateur.id, true);

  if (message.isMine) {
    dialog.new = false;
    dialog.lastActivity = new Date();
    dialog.activated = dialog.lastActivity.getTime();
  } else {
    if (!dialog.new) {
      newMessagesCount++;
    }
    dialog.new = true;
    dialog.lastActivity = new Date(message.time);
    if (message.payload.ibid) {
      amateurVisitsUser(message.amateur.id);
    }
    if (dialog.activated) {
      createAudio('/sounds/testsound4.mp3');
    }
    ctxUnreadDialogs[CTX_PROVIDE](null, newMessagesCount);
  }

  sort();
  ngDigest();
});

listen('dialog.close', (ev) => {
  ASSERT: guard(ev, EVENTBUS_DIALOG_READ_OR_CLOSE);

  const index = dialogs.findIndex((dialog) => dialog.id == ev.partner.id);
  if (index >= 0) {
    dialogs.splice(index, 1);
    dialogs.changed++;
    ngDigest();
  }
});

listen('dialog.read', (ev) => {
  ASSERT: guard(ev, EVENTBUS_DIALOG_READ_OR_CLOSE);

  const dialog = dialogs.find((d) => d.id == ev.partner.id);
  if (dialog) {
    if (dialog.new) {
      dialog.new = false;
      newMessagesCount--;
      ctxUnreadDialogs[CTX_PROVIDE](null, newMessagesCount);
    }
    ngDigest();
  }
});

listen('dialogs', (ev) => {
  ASSERT: guard(ev, EVENTBUS_DIALOGS);

  for (const data of ev.dialogs) {
    const amateurId = data.partner.id;
    let dialog = dialogs.find((d) => d.id == amateurId);
    if (!dialog) {
      dialog = new Dialog(amateurId);
      byAmateur.set(amateurId, dialog);
      dialogs.push(dialog);
    }
    dialog.lastActivity = new Date(data.timestamp_ms);
    dialog.new = !data.read;
    if (dialog.new) {
      newMessagesCount++;
    }
  }
  dialogs.changed++;
  sort();
  ctxUnreadDialogs[CTX_PROVIDE](null, newMessagesCount);
  ngDigest();
});

const EVENTBUS_DIALOGS = /* @__PURE__ */ (() =>
  OBJECT({
    type: CONST('dialogs'),
    dialogs: ARRAY(
      OBJECT({
        partner: UHURA_USER_ID,
        read: BOOLEAN,
        timestamp_ms: TIMESTAMP_MS,
      })
    ),
  }))();

const EVENTBUS_DIALOG_READ_OR_CLOSE = /* @__PURE__ */ (() =>
  OBJECT({
    type: ENUM(/** @type {const} */ (['dialog.read', 'dialog.close'])),
    partner: UHURA_USER_ID,
  }))();
