import angular from 'angular';
import {fskTrans} from 'acng/userPool/0fsk.js';
import {fsk} from 'acng/userPool/fsk.js';

angular.module('amateurPool').component('onswMoreAboutMe', {
  bindings: {amateur: '<'},
  templateUrl: '/template/amateurPool.moreAboutMe',
  controller: [
    '$scope',
    /**
     * @this {angular.IController & {amateur: import('../model/amateur').Amateur}}
     * @param {angular.IScope & {
     *   hasAboutMe: () => boolean;
     *   hasOthersAboutMe: () => boolean;
     *   hasFantasies: () => boolean;
     *   fsk: typeof fsk;
     * }} $scope
     */
    function ($scope) {
      $scope.fsk = fsk;
      /**
       * @param {"about_me" | "others_about_me" | "fantasies"} key
       */
      const has = (key) => this.amateur && !!fskTrans(this.amateur[key]);
      $scope.hasAboutMe = () => has('about_me');
      $scope.hasOthersAboutMe = () => has('others_about_me');
      $scope.hasFantasies = () => has('fantasies');
    },
  ],
});
