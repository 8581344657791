import {guard} from '@acng/frontend-rubicon';
import {CLICK, on} from '@acng/frontend-bounty/dom/event.js';
import {remove} from '@acng/frontend-bounty/dom/move.js';
import {all} from '@acng/frontend-bounty/std/control.js';
import {
  closestData,
  createDiv,
  setDataAttribute,
  setText,
} from '@acng/frontend-bounty';
import {t} from 'acng/locale/config/translate.js';
import {coreFeature} from '../../config/feature.js';
import {repeatElement} from 'acng/layout/service/repeat.js';
import {NODES} from '@acng/frontend-stargazer';

const TEMPLATE = /* @__PURE__ */ NODES({
  fail: HTMLElement,
  alternatives: HTMLElement,
  alternative: HTMLElement,
  message: Text,
  suggestion: Text,
});

/**
 * @param {HTMLInputElement} inputElement
 * @param {import('./validation.js').ApiValidatorResponseData} params
 * @param {(suggestion: string) => void} [suggestionClick]
 * @returns {Promise<HTMLDivElement>}
 */
export const setMessage = async (inputElement, params, suggestionClick) => {
  const [template, translations] = await all([
    coreFeature.render('api-validator'),
    all((params.rules ?? []).map((rule) => t(`validation.frontend.${rule.split(':')[0]}`))),
  ]);

  return template.toElement(createDiv(), {}, (nodes) => {
    ASSERT: guard(nodes, TEMPLATE, 'template nodes');

    const {fail, alternatives, alternative, message, suggestion} = nodes;

    repeatElement(fail, params.failed ?? [], (i) => setText(message, translations[i]));
    if (!params.suggestions || !params.suggestions.length) {
      remove(alternatives);
    } else {
      on(alternatives, CLICK, ({target}) =>
        closestData(
          target,
          'suggestion',
          (value) => {
            inputElement.value = value;
            suggestionClick?.(value);
          },
          alternatives
        )
      );
      repeatElement(alternative, params.suggestions, (str) => {
        setDataAttribute(alternative, 'suggestion', str);
        setText(suggestion, str);
      });
    }
  });
};
