import {BOOLEAN, INTEGER, OBJECT, OPTIONAL, STRING} from '@acng/frontend-rubicon';
import {FSK_LEVEL} from '../model/fsk.js';

export const PORTAL = /* @__PURE__ */ (() =>
  OBJECT({
    id: INTEGER,
    name: STRING,
    shortcut: STRING,
    mobile: BOOLEAN,
    niche: STRING,
    list_sorting_suffix: STRING,
    is_international: BOOLEAN,
    email: OBJECT({
      support: STRING,
      compliance: STRING,
    }),
    copyright: OPTIONAL(STRING), // TODO
    guest_fsk: FSK_LEVEL,
    list_base_fsk: FSK_LEVEL,
  }))();
