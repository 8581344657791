// @ts-nocheck
import angular from 'angular';
import {LivecamSession} from "../model/session.js";
import {get} from 'acng/amateurPool/service/amateur.js';

angular.module('livecam')

  .directive('onswTipButton', ['http', 'user', 'payment', 'Widget', '$log', function (http, user, payment, Widget, $log) {

    return {
      template: '<span ng-click="tip()" class="box"><span class="label"></span><span class="price">{{ value }} {{ "coins" | translate }}</span></span>',
      scope: {
        value: '@',
        token: '<'
      },
      link: (scope, el) => {

        const widget = new Widget(scope, el, 'ons-item');

        scope.$watch('token', token => {
          scope.session = LivecamSession.get(token);
        });

        scope.tip = () => {

          if (!user.isPremium()) {
            payment.overlay('exception.4005');
            return;
          }

          widget.ifNotBusy(() => get(scope.session.id))
            .then(amateur => http().post('/api/livecam/tip', $.param({
              session: scope.token,
              recipient: amateur.id,
              value: scope.value
            })))
            .catch(err => {
              scope.session.client.exitFullscreen();
              $log.error(err);
            });
        };
      }
    };
  }]);
