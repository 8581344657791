import {pick} from '@acng/frontend-bounty/object.js';
import {publish} from 'acng/core/context/event-bus.js';

/**
 * @todo TODO move to ../model/message after converting that to js
 *
 * @param {import('../model/message').Message} message
 */
export const publishUserMessage = (message) => {
  /**
   * @type {import('../model/message').EventBusMessageData}
   */
  const ev = {
    type: 'message',
    partner: {
      product_id: 2,
      id: message.amateur.id,
    },
    message: pick(message, 'sender', 'body', 'timestamp_ms', 'payload', 'attachment', 'fromLivecam'),
  };

  publish(ev);
};

/**
 * @param {EventBusDialogPartner} amateur
 */
export const requestDialogMessages = (amateur) => {
  const ev = {
    type: 'request.messages',
    partner: amateur.id,
  };

  publish(ev);
};

/**
 * @typedef {import('acng/amateurPool/model/amateur').Amateur} EventBusDialogPartner
 */
