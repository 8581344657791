import {defineRegistryElement} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {IS, guard} from '@acng/frontend-rubicon';
import {HTMLElement, con, def} from '@acng/frontend-bounty/dom/custom.js';
import {find} from '@acng/frontend-bounty/dom/element.js';
import {set} from '@acng/frontend-bounty/style/element.js';
import {setText} from '@acng/frontend-bounty';

import {ctxGallery} from '../../core/context/gallery.js';

/**
 * Display the {@link Gallery.media_rating}.
 *
 * @group DOM Element
 * @example
 * ```html
 * <rating-stars>
 *   <span class="inner"></span>
 *   <span class="value"></span>
 * </rating-stars>
 * ```
 */
export const RATING_STARS = 'rating-stars';

defineRegistryElement(RATING_STARS, (name) => {
  const watchGallery = Watch(name, ctxGallery);

  /**
   * @param {HTMLElement} element
   * @param {Gallery} gallery
   */
  const update = (element, gallery) => {
    const rating = gallery.media_rating || 0;
    const stars = rating / 2;
    const inner = find(element, '.inner');
    const value = find(element, '.value');
    ASSERT: {
      guard(inner, IS(HTMLElement));
      guard(value, IS(HTMLElement));
    }

    set(inner, 'width', `${10 * rating}%`);
    setText(
      value,
      rating
        ? stars.toLocaleString(document.body.lang, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
        : ''
    );
  };

  def(
    name,
    class extends HTMLElement {
      [con]() {
        watchGallery(this, update);
      }
    }
  );
});
