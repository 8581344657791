import {GlobalContext} from '@acng/frontend-relativity';
import {COMMENT_DATA} from '../model/comment.js';

/**
 * @private
 * @typedef CommentCreatedEvent
 * @property {Gallery} gallery
 * @property {Valid<typeof COMMENT_DATA>} comment
 */

/**
 * @type {Global<CommentCreatedEvent | null>}
 */
export const ctxCreatedComment = GlobalContext(null);
