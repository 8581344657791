import {Error, toggleClass} from '@acng/frontend-bounty';

import {
  STYLE_ACTIVE,
  STYLE_HIDDEN,
  STYLE_INACTIVE,
  STYLE_PLAYING,
  STYLE_RECORDING,
  STYLE_WAITING,
} from 'acng/layout/config/style';

import {livecamSession} from 'acng/livecam/context/session.js';
import {inject} from 'acng/core/service/ng.js';
import {spinner} from 'acng/core/service/spinner.js';

import {sendMessage} from '../service/send-message.js';
import {sendVoice} from '../service/send-voice.js';
import {uploadVoiceMessage} from '../service/sonus.js';
import {Message} from './message.js';
import {isMobile} from 'acng/core/service/env.js';

/**
 * The ***draft*** of a `Message` written by the `AuthUser`.
 */
export class MessageDraft extends Message {
  /**
   * @type {Blob | boolean}
   */
  voice = false;
  playing = false;
  recording = false;
  allowVoice = false;
  wait = false;
  /**
   * @type {HTMLElement | undefined}
   */
  focusElement;

  /**
   * @param {Amateur} amateur
   */
  constructor(amateur) {
    const user = inject('user');
    super(amateur, {
      sender: {
        id: `${user.id}`,
        product_id: user.pool_id,
      },
      body: '',
      timestamp_ms: NaN,
      attachment: null,
      payload: {},
    });
    this.check();
  }

  check() {
    this.allowVoice = inject('user').hasVoiceMessage() && this.amateur.hasVoiceMessages() && !livecamSession;
  }

  /**
   * @param {HTMLElement} context
   */
  async send(context) {
    if (this.wait) {
      throw Error();
    }

    this.wait = true;

    try {
      if (this.voice instanceof Blob) {
        const sonusRes = await spinner(uploadVoiceMessage(context, this.voice), context);

        await sendVoice(context, this, sonusRes.id);
        this.voice = false;
      } else {
        await sendMessage(context, this);

        this.attachment = null;
        this.body = '';
        this.payload = {};
      }
    } catch (reason) {
      DEBUG: console.error(reason);
    }

    this.wait = false;
  }

  focusIfNotMobile() {
    if (!isMobile) {
      this.focusElement?.focus();
    }
  }

  /**
   * @param {Element} element
   */
  applyState(element) {
    toggleClass(element, STYLE_HIDDEN, !this.allowVoice);
    toggleClass(element, STYLE_INACTIVE, !this.voice || (this.voice === true && this.wait));
    toggleClass(element, STYLE_RECORDING, this.voice === true && !this.wait);
    toggleClass(element, STYLE_WAITING, this.wait);
    toggleClass(element, STYLE_ACTIVE, this.voice instanceof Blob);
    toggleClass(element, STYLE_PLAYING, this.playing);
  }
}
