import {popup} from '@acng/frontend-discovery';
import {Request, Response, fetch, getHeader} from '@acng/frontend-bounty/fetch.js';
import {keyIn} from '@acng/frontend-bounty/object.js';

import {FSK_LEVEL} from '../model/fsk.js';
import {fsk} from 'acng/userPool/fsk.js';
import {API_cdn, VERSION} from './env.js';
import {spinner} from './spinner.js';
import {inject} from './ng.js';
import {Error} from '@acng/frontend-bounty/std/error.js';
import {ERROR_RESPONSE_BODY} from '../model/backend.js';
import {showXNotifications} from './backend/x-notifications.js';
import {showXUserPopups} from './backend/x-userpopups.js';
import {setOutdated} from './backend/x-version.js';
import {publish} from '../context/event-bus.js';
import {INTEGER, OBJECT, STRING, TUPLE, guard} from '@acng/frontend-rubicon';
import {ERROR_CODE_MAP} from '../model/price-change.js';
import {handleCfChallenge} from './backend/cf-challenge.js';

const MODULE = 'core/service/backend';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * Apply these headers both to cdn and direct api request.
 */
const headers = {Accept: 'application/json'};

/**
 * @param {string} path
 * @param {Element} [element]
 * @param {{}} [params]
 * @returns {Promise<unknown>}
 */
export const cdn = async (path, element, params) => {
  const url = `${API_cdn}/api/${path}${params ? `?${new URLSearchParams(params)}` : ''}`;
  const request = new Request(url, {headers});
  const response = await fetchResponse(request, element);

  if (!response.ok) {
    throw response;
  }

  return await response.json();
};

/**
 * @param {"GET" | "POST" | "PUT" | "DELETE"} method
 * @param {string} path
 * @param {{}} [params]
 */
const createApiRequest = (method, path, params) => {
  const reqHeaders = {...headers};

  if (params) {
    reqHeaders['Content-Type'] = 'application/json';
  }

  return new Request(`/api/${path}`, {
    method,
    headers: reqHeaders,
    mode: 'same-origin',
    body: params ? JSON.stringify(params) : undefined,
  });
};

/**
 * @template T
 * @callback ErrorCallback
 * @param {ErrorResponse} error
 * @returns {Promise<T>}
 */

/**
 * @template [T=unknown]
 * @param {string} path
 * @param {Element} [element]
 * @param {ErrorCallback<T>} [errorCallback]
 * @returns {Promise<T>}
 */
export const get = (path, element, errorCallback) =>
  http(createApiRequest('GET', path), element, errorCallback);

/**
 * @typedef ErrorResponseData
 * @property {number} code
 * @property {string} message
 * @property {{[key: string]: string[]}} [reasons]
 */

/** @implements {ErrorResponseData} */
export class ErrorResponse extends Error {
  /**
   * @param {number} httpStatus
   * @param {ErrorResponseData & {[key: string]: unknown}} data
   */
  constructor(httpStatus, data) {
    super(data.message);
    this.httpStatus = httpStatus;
    this.code = data.code;
    this.reasons = data.reasons;
    this.data = data;
  }
}

/**
 * @todo TODO improve typechecks (make PriceChangeError)
 *
 * @template {{}} D
 * @template [T=unknown]
 * @throws {Promise<void> | ErrorResponse}
 * @param {string} path
 * @param {D} data
 * @param {Element} [element]
 * @param {ErrorCallback<NoInfer<T>>} [errorCallback]
 * @returns {Promise<T>}
 */
export const post = (path, data, element, errorCallback) =>
  http(createApiRequest('POST', path, data), element, async (error) => {
    // TODO REFACTOR PRICE STUFF: move price factory to price-service and make publishPossiblePriceChange(error) or so
    if (error.httpStatus == 409 && keyIn(ERROR_CODE_MAP, error.code)) {
      ASSERT: {
        guard(data, OBJECT({price: INTEGER}));
        guard(error.data.price, INTEGER);
      }

      /**
       * @type {string | number}
       */
      const article_id = (() => {
        if (ERROR_CODE_MAP[error.code] == 'msg') {
          ASSERT: guard(data, OBJECT({recipient: STRING}), 'changed message price amateur id from post body');
          return data.recipient;
        } else {
          const match = /^.*\/(\d+)$/.exec(path);
          ASSERT: guard(match, TUPLE(STRING, STRING), 'match movie or picture id from post url path');
          return Number(match[1]);
        }
      })();

      /**
       * @type {Valid<typeof import("../model/price-change").EVENTBUS_PRICE_CHANGE>}
       */
      const busEvent = {
        type: 'price',
        article: {
          article_id,
          newPrice: Number(error.data.price),
          type: ERROR_CODE_MAP[error.code],
        },
      };

      publish(busEvent);

      if (data.price > error.data.price) {
        data.price = error.data.price;

        return await post(path, data, element, errorCallback);
      }
    }

    if (errorCallback) {
      return await errorCallback(error);
    }

    throw error;
  });

/**
 * @template T
 * @template {{}} D
 * @throws {Promise<void> | ErrorResponse}
 * @param {string} path
 * @param {D} data
 * @param {Element} [element]
 * @param {ErrorCallback<T>} [errorCallback]
 * @returns {Promise<T>}
 */
export const put = (path, data, element, errorCallback) =>
  http(createApiRequest('PUT', path, data), element, errorCallback);

/**
 * @template T
 * @throws {Promise<void> | ErrorResponse}
 * @param {string} path
 * @param {HTMLElement} [element]
 * @param {ErrorCallback<T>} [errorCallback]
 * @returns {Promise<T>}
 */
export const httpDelete = (path, element, errorCallback) =>
  http(createApiRequest('DELETE', path), element, errorCallback);

/**
 * Fetch the response for a request and show a loader while fetching.
 *
 * @param {RequestInfo | URL} request
 * @param {Element} [element] - Define a context for the loader.
 * @returns Never rejected
 */
const fetchResponse = async (request, element) => {
  try {
    const res = await spinner(fetch(request), element);
    handleCfChallenge(res);
    return res;
  } catch (reason) {
    console.error('fetch request failed for reason', reason);
    return Response.error();
  }
};

/**
 * @template T
 * @param {Request} request
 * @param {Element} [element]
 * @param {ErrorCallback<T>} [errorCallback]
 */
const http = async (request, element, errorCallback) => {
  const response = await fetchResponse(request, element);

  // TODO Do not `setOutdated` if the `response` might be cached.
  const xVersion = getHeader(response, 'x-version');
  if (xVersion && xVersion != VERSION) {
    setOutdated();
  }

  showXUserPopups(response);
  showXNotifications(response);

  const xFsk = getHeader(response, 'x-fsk');
  if (xFsk) {
    const parsedFskLevel = Number(xFsk);
    DEBUG: if (VERBOSE) console.info(MODULE, 'x-fsk header in response', {response, xFsk, parsedFskLevel});
    ASSERT: guard(parsedFskLevel, FSK_LEVEL, `${MODULE} parsed x-fsk`);
    fsk.set(parsedFskLevel);
  }

  /**
   * @type {ErrorResponseData}
   */
  let errorData;

  if (response.ok) {
    try {
      return response.status === 204 ? null : await response.json();
    } catch (reason) {
      errorData = {code: -1, message: 'Response decode error'}; // cause: response
    }
  } else if (response.type == 'error') {
    // TODO consider confirm retry
    errorData = {code: -1, message: 'Request error'}; // cause: request
  } else if (response.status === 401) {
    // In case of 401 the response is empty. It is no user logged into the backend session, which would
    // be required for this call.
    errorData = {code: -2, message: 'User is not authenticated'};
  } else {
    // Await generic error data from ACNG api.
    try {
      errorData = await response.json();
      ASSERT: guard(errorData, ERROR_RESPONSE_BODY);
      // TODO put body into message on exception
    } catch (reason) {
      console.error('Error Response decode error', {response, reason});
      errorData = {code: -1, message: 'Error Response decode error'}; // cause: response
    }
  }

  let error = new ErrorResponse(response.status, errorData); // cause: response

  // Customize catch with error callback
  // TODO consider postprocess for successful response too, this would also provide a return type hint
  if (errorCallback) {
    try {
      return await errorCallback(error);
    } catch (reason) {
      if (reason instanceof ErrorResponse) {
        error = reason;
      } else {
        throw reason;
      }
    }
  }

  if (response.status == 401) {
    const user = inject('user');
    if (!user.guestSignup(error.message)) {
      await popup(element).error(error.message);
      user.clear();
    }
  } else if (response.status == 402) {
    if (error.code == 4001) {
      inject('Payback').openOverlay();
    } else {
      inject('payment').overlay(error.message);
    }
  } else {
    DEBUG: console.error(error);
    await popup(element).error(error.message);
  }

  throw error;
};
