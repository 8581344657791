import {AND, INTEGER, OBJECT} from '@acng/frontend-rubicon';
import {GALLERY_DATA} from '../../core/service/guard.js';
import {FSK_LEVEL} from '../../core/model/fsk.js';

export const MOVIE_DATA = /* @__PURE__ */ AND(
  GALLERY_DATA,
  OBJECT({
    id: INTEGER,
    fsk: FSK_LEVEL,
    filesize: INTEGER,
    playtime: INTEGER,
    width: INTEGER,
    height: INTEGER,
  })
);
