import {FINAL, OWNED, guard} from '@acng/frontend-rubicon';
import {assign} from '@acng/frontend-bounty/object.js';
import {Gallery} from 'acng/core/model/gallery.js';
import {inject} from 'acng/core/service/ng.js';

/**
 * @param {Record<string, unknown>} data
 */
export const fixPictureSetData = (data) => {
  data.id = data.set_id;
  data.rawItems = /** @type {any} */ (data.items);
  // @ts-ignore
  data.items = data.rawItems.filter((i) => Number(i.fsk) <= inject('user').getListFsk()).map((v) => v.id);

  delete data.number;
  delete data.min_fsk;
};

/**
 * @implements {PictureSetData}
 */
export class PictureSet extends Gallery {
  /**
   * @param {PictureSetData} data
   */
  constructor(data) {
    super(data);

    ASSERT: {
      /**
       * @deprecated Use set_id
       */
      this.id = data.id;
      this.rawItems = data.rawItems;
      this.items = data.items;

      guard(this, OWNED(data), 'PictureSet');
      guard(this, FINAL(PictureSet), 'PictureSet');
    }

    assign(this, data);
  }

  getPictures() {
    const Picture = inject('Picture');
    return this.rawItems
      .filter((i) => Number(i.fsk) <= inject('user').getListFsk())
      .map((v) => {
        Picture.cache.set(v.id, Date.now() + 3600000, new Picture(v));
        return v.id;
      });
  }

  /**
   * @returns {Promise<void>}
   */
  async goto() {
    const nickname = await this.getAmateur()
      .then((amateur) => amateur.nickname)
      .catch(() => 'unknown');

    location.hash = `/sedcard/${nickname}/pictures/set/${this.id}`;
  }

  /**
   * @deprecated
   * @param {"nickname" | "recommendation"} type
   * @param {number} limit
   */
  more(type, limit) {
    return inject('http')()
      .get('/api/picture/more/' + this.id + '?type=' + type + '&limit=' + limit, {dontIntercept: true})
      // @ts-ignore
      .then((res) => res.data);
  }
}
