/**
 * @module acng/regard/model/category
 */
import {media, API_cdn} from 'acng/core/service/env';
import {Regard, RegardData} from './regard';
import {fsk} from 'acng/userPool/fsk.js';
import {getFallbackImageUrl} from 'acng/layout/service/fallback-image.js';

type FskTrans = import('acng/userPool/0fsk').FskTrans;
type RegardCategoryData = {
  id: number;
  name: Exclude<FskTrans, null>;
  icon: string;
  fsk: number;
};

export class RegardCategory {
  id: number;
  name: Exclude<FskTrans, null>;
  icon: string;
  icon_fallback_url: string;
  fsk: number;
  items?: Regard[];

  constructor(data: RegardCategoryData) {
    this.id = data.id;
    this.name = data.name;
    this.icon = `${media.content.items}${data.icon}`;
    this.icon_fallback_url = getFallbackImageUrl('300');
    this.fsk = data.fsk;
  }

  getIconUrl() {
    return this.fsk > fsk.get() ? this.icon_fallback_url : this.icon;
  }

  async getItems() {
    if (!this.items) {
      const res = await fetch(`${API_cdn}/api/regard/category/${this.id}`);
      if (!res.ok) {
        throw Error('regard/model/category not ok');
      }
      const regardsData: RegardData[] = await res.json();
      this.items = regardsData.map(data => new Regard(data));
    }
    return this.items;
  }

  static async list() {
    const res = await fetch(`${API_cdn}/api/regard/categories`);
    if (!res.ok) {
      throw Error('regard/model/category not ok');
    }
    const data: RegardCategoryData[] = await res.json();
    return data.map(data => new RegardCategory(data));
  }
}
