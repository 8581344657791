// bounty, rubicon
import {loadImage} from '@acng/frontend-bounty';
import {assign} from '@acng/frontend-bounty/object.js';
import {get, has} from '@acng/frontend-bounty/collection.js';
import {window} from '@acng/frontend-bounty/dom/window.js';
import {FINAL, OWNED, guard} from '@acng/frontend-rubicon';
// enterprise
import {hasFeature, media, portal} from 'acng/core/service/env.js';
import {fsk} from 'acng/userPool/fsk.js';
import {fskTrans} from 'acng/userPool/0fsk.js';
import {getGlobalDialog} from 'acng/messenger/context/global-dialog.js';
import {getContactsVisibility} from 'acng/messenger/context/contacts-visibility.js';
import {isOnline} from '../service/online.js';
import {getFallbackImageUrl} from 'acng/layout/service/fallback-image.js';
import {sessionOfAmateur} from '../../games/service/games.js';
import {t} from '../../locale/config/translate.js';
import {zipFilter} from '../../core/filter/zip.js';
import {authUser} from 'acng/userPool/context/auth-user.js';
import {matches} from '../../hotornot/model.js';
import {cams} from 'acng/livecam/model/livecam.js';

/**
 * @implements {AmateurData}
 */
export class Amateur {
  /**
   * @param {AmateurData} data
   */
  constructor(data) {
    ASSERT: {
      this.id = data.id;
      this.nickname = data.nickname;
      this.nickname_fsk = data.nickname_fsk;
      this.intlname = data.intlname;
      this.message_price = data.message_price;

      this.images = data.images;
      this.matchmaker_image = data.matchmaker_image;

      this.gender = data.gender;
      this.age = data.age;
      this.height = data.height;
      this.weight = data.weight;
      this.country = data.country;
      this.zip_area = data.zip_area;

      /**
       * Spoken languages
       */
      this.languages = data.languages;

      this.do_not_show_in = data.do_not_show_in;
      this.niche_tags = data.niche_tags;

      /**
       * Arbitrary name list of supported features
       */
      this.features = data.features;

      this.tags = data.tags;
      this.topics = data.topics;
      this.profile_video_fsk = data.profile_video_fsk;
      this.profile_video_path = data.profile_video_path;

      this.about_me = data.about_me;
      this.description = data.description;
      this.fantasies = data.fantasies;
      this.others_about_me = data.others_about_me;

      this.experiences = data.experiences;
      this.lookingfor = data.lookingfor;
      this.preferences = data.preferences;

      this.body = data.body;
      this.bodyhair = data.bodyhair;
      this.eyecolor = data.eyecolor;
      this.brasize = data.brasize;
      this.ethnic = data.ethnic;
      this.glasses = data.glasses;
      this.haircolor = data.haircolor;
      this.hairlength = data.hairlength;
      this.marital = data.marital;
      this.piercings = data.piercings;
      this.pubichair = data.pubichair;
      this.smoking = data.smoking;
      this.tattoos = data.tattoos;
      this.penislength = data.penislength;
      this.penisperimeter = data.penisperimeter;

      guard(this, FINAL(Amateur));
      guard(this, OWNED(data));
    }

    assign(this, data);
  }

  /**
   * @returns {string}
   */
  getNickname() {
    return authUser?.is_international || portal.is_international ? this.intlname : this.nickname;
  }

  /**
   * @returns {boolean}
   */
  doNotShow() {
    return (
      !!authUser &&
      (this.do_not_show_in.includes(authUser.first_login_country ?? '') ||
        !this.niche_tags.includes(authUser.niche) ||
        authUser.fsk < this.nickname_fsk)
    );
  }

  /**
   * @param {ImageFormat} format
   */
  getImageUrl(format) {
    const tuple = this.getPreferredImage();

    if (!tuple) {
      return getFallbackImageUrl(format);
    }

    const [, , src] = tuple;

    return `${media.content.pictures}${src}-${format}.jpg`;
  }

  /**
   * @returns {?Amateur["images"][number]}
   */
  getPreferredImage() {
    const wantedFsk = fsk.get();

    for (const source of this.images) {
      const [, fsk] = source;

      if (fsk <= wantedFsk) {
        return source;
      }
    }

    return null;
  }

  /**
   * @param {ImageFormat} format
   */
  getMatchMakerImageUrl(format) {
    const [, , src] = this.getMatchMakerImage();

    return `${media.content.pictures}${src}-${format}.jpg`;
  }

  /**
   * @returns {Amateur["images"][number]}
   */
  getMatchMakerImage() {
    const fallbackIfNoImageAtAll = /** @type {Amateur["images"][number]} */ ([0, 18, '']);
    let positiveVoteImages = JSON.parse(window.localStorage.getItem('positiveVoteImages') ?? '{}');

    if (positiveVoteImages[this.id]) {
      let image = this.images.find(([id]) => id === positiveVoteImages[this.id]);
      if (image) {
        return image;
      }
    }

    const matchmakerImage = this.images.find(([id]) => id === this.matchmaker_image);
    return matchmakerImage && matchmakerImage[1] <= fsk.get()
      ? matchmakerImage
      : this.getPreferredImage() ?? fallbackIfNoImageAtAll;
  }

  /**
   * @param {ImageFormat} format
   * @returns {Promise<HTMLImageElement>}
   */
  getImage(format) {
    return loadImage(this.getImageUrl(format), getFallbackImageUrl(format));
  }

  isOnline() {
    return isOnline(this.id);
  }

  getDescription() {
    return fskTrans(this.description);
  }

  /**
   * @param {string} [add]
   */
  goto(add) {
    const path = `#/sedcard/${this.nickname}${add ? `/${add}` : ''}`;
    if (window.innerWidth < 768 && path == location.hash && (getGlobalDialog() || getContactsVisibility())) {
      location.hash = `${path}?x=${Math.random() * 1e6}`;
    } else {
      location.hash = path;
    }
  }

  /**
   * @param {number} visitDuration in seconds
   */
  trackVisit(visitDuration) {
    const formData = new FormData();
    formData.append('visit_duration', `${visitDuration}`);
    navigator.sendBeacon(`/api/amateur-profile/track-visit/${this.id}`, formData);
  }

  hasGames() {
    return hasFeature('games') && this.features.includes('games');
  }

  /**
   * @returns {import('../../games/model/game').Session | undefined}
   * Latest Game with this amateur
   */
  getGame() {
    const session = sessionOfAmateur.get(this.id);

    if (session) {
      session.amateur = this;
    }

    return session;
  }

  /**
   * @returns {boolean}
   * `true` when the current user hat an active game with this amateur
   * `false` otherwhise
   */
  hasActiveGame() {
    return !!sessionOfAmateur.get(this.id)?.getState().isActive;
  }

  hasVoiceMessages() {
    return hasFeature('messenger') && this.features.includes('voice_messages');
  }

  async getLocation() {
    return await t('amateurPool.fromZipArea', {
      zip_area: zipFilter(this.zip_area, this.country),
      country: this.country ? await t(`countries.iso.${this.country.toUpperCase()}`) : '',
    });
  }

  /**
   * @returns {boolean}
   */
  isMatch() {
    return has(matches, this.id);
  }

  /**
   * @returns {import('acng/livecam/model/livecam').RoomType[]}
   */
  getLivecamShows() {
    return get(cams, this.id)?.show_types ?? [];
  }

  /**
   * @param {import('acng/livecam/model/livecam').RoomType} type
   * @returns {"lovense" | "cam" | "voyeur"}
   */
  getShowIcon(type) {
    /**
     * @type {Record<import('acng/livecam/model/livecam.js').RoomType, "lovense" | "cam" | "voyeur">}
     */
    const map = {
      normal: get(cams, this.id)?.toycontrol ? 'lovense' : 'cam',
      voyeur: 'voyeur',
      exclusive: 'cam',
      free: 'cam',
    };

    return map[type];
  }

  /**
   * @returns {boolean}
   */
  livecamIsOccupied() {
    return !!get(cams, this.id)?.is_occupied;
  }

  /**
   * @returns {boolean}
   */
  livecamIsOnline() {
    const livecam = get(cams, this.id);

    return !!livecam && (livecam.is_online || livecam.is_occupied);
  }

  /**
   * @returns {boolean}
   */
  hasToycontrol() {
    return !!get(cams, this.id)?.toycontrol;
  }
}

/**
 * @private
 * @typedef {Valid<typeof import("./amateur-data").AMATEUR_DATA>} AmateurData
 */
