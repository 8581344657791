import angular from 'angular';
import {hasFeature} from 'acng/core/service/env';
import {get} from '../service/amateur.js';
import {Weblog} from '../model/weblog.js';

angular.module('amateurPool')

  .factory('weblogStore', ['HttpCache', '$injector', (HttpCache, $injector) => {

    const Movie = hasFeature('moviePool') ? $injector.get('Movie') : null;
    const PictureSet = hasFeature('picturePool') ? $injector.get('PictureSet') : null;

    function prepareApiResponse(data) {
      data.amateurId = data.user_id;
      data.mediaFactory = {
        picture: PictureSet,
        movie: Movie
      }[data.media_type];
      data.mediaId = {
        picture: data.set_id,
        movie: data.movie_id
      }[data.media_type];
      data.mediaType = {
        picture: 'PictureSet',
        movie: 'Movie'
      }[data.media_type];
      return data;
    }

    return new HttpCache({
      prefix: '/api/amateur-profile/weblog/',
      useCdn: true,
      fnResolve: res => {

        const item = prepareApiResponse(res.data);

        let amateur = null;
        let mediaItem = null;

        const amateurPromise = get(item.amateurId)
          .then(item => amateur = item)
          .catch(err => {
            if (err.status == 404) {
              throw err;
            }
            console.error('Weblogs Amateur Error', res.data, item, err);
          });

        const mediaPromise = item.mediaFactory && item.mediaFactory.get(item.mediaId)
          .then(item => mediaItem = item)
          .catch(err => console.warn('Weblog: media not present - discard', { err, item }));

        return amateurPromise
          .then(() => mediaPromise)
          .then(() => new Weblog(item, amateur, mediaItem));
      }
    });
  }])

  .directive('onsdWeblog', ['PimpMyRide', 'weblogStore', (PimpMyRide, weblogStore) => PimpMyRide(
    'Weblog', 'weblogId', id => weblogStore.get(id), id => !isNaN(id) && id)]);
