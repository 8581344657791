import {Widget, defineCustomWidget, NODES} from '@acng/frontend-stargazer';
import {guard, IS} from '@acng/frontend-rubicon';
import {setText} from '@acng/frontend-bounty';
import {hide, show, toggle} from '@acng/frontend-bounty/style/element.js';

import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {messengerFeature} from '../config/feature.js';
import {ctxMessageDraft, getMessageDraft, provideComposer} from '../context/message-draft.js';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';
import {connectedCallback} from '@acng/frontend-bounty/dom/custom.js';

/**
 * @module
 * [Template]{@link ../../../html/messenger/onsw-message-composer.html}
 */

defineCustomWidget(
  messengerFeature,
  'onsw-message-composer',
  class extends Widget {
    static consumables = [ctxAmateur, ctxMessageDraft];

    [connectedCallback]() {
      ASSERT: guard(this.parentElement, IS(HTMLElement));
      const p = this.parentElement;

      ctxAmateur[CTX_OBSERVE](this, (amateur) => provideComposer(p, amateur));
      super[connectedCallback]();
    }

    /**
     * Two nodes are expected in the template.
     *
     * - attachment
     *   Show the `#attachment` element while an attachment is assigned to the composer.
     * - attachmentName
     *   Show the "name" of the attachment at the `${attachmentName}` node.
     */
    render() {
      ctxMessageDraft[CTX_OBSERVE](this, (message) => {
        if (!message) {
          return;
        }

        const {attachment, voice} = message;
        ASSERT: guard(
          this.nodes,
          NODES({
            attachment: HTMLElement,
            attachmentName: Text,
          })
        );

        const {attachment: element, attachmentName: name} = this.nodes;

        toggle(this, 'voice', !!voice);
        if (attachment && !voice) {
          setText(name, attachment.name);
          show(element);
        } else {
          setText(name, null);
          hide(element);
        }
      });
    }

    removeAttachment() {
      const message = getMessageDraft(this);
      if (message) {
        message.attachment = null;
      }
    }
  }
);
