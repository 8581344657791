/**
 * @module acng/userPool/filter/nickname
 */
export default 'nickname';
export const nicknameFilter = ['user', filter];

type User = import('acng/userPool/factory/user').User;

function filter(user: User) {
  return (input: string | undefined, amateur: Amateur) => {
    if (typeof input != 'string') {
      return input;
    }
    let res = input.replace(/(@nickname)/g, user.nickname);
    if (amateur) {
      res = res.replace(/@amateurName/g, amateur.getNickname());
    }
    return res;
  };
}
