// bounty, rubicon
import {HIDDEN} from '@acng/frontend-bounty/dom/attribute.js';
import {extend} from '@acng/frontend-bounty/object.js';
import {IS, OBJECT, guard} from '@acng/frontend-rubicon';
// stargazer
import {Engine} from '@acng/frontend-stargazer';
// enterprise
import {hasFeature, media} from 'acng/core/service/env.js';

(() => {
  const SPECIAL = 'special';
  const specials = Engine.root.spawn(SPECIAL);

  specials[HIDDEN] = !hasFeature(SPECIAL);
  //specials.template.setAttribute('debug', '');

  specials.source = async ([name]) => {
    const module = await import(`${media.assets_ext}/specials/${name}/${SPECIAL}.js`);
    ASSERT: guard(module, SOURCE, `special "${name}" source module`);
    const {source, config} = module;

    const htmlFile = await source();
    ASSERT: guard(htmlFile, IS(URL), 'special source');

    const special = specials.spawn(name);
    const scope = extend(Engine.rootScope, config(Engine.rootScope));

    special.sourceScope = scope;
    await special.fetch(htmlFile, null);
  };
})();

/**
 * TODO Put into *Stargazer*
 *
 * @private
 * @typedef Module
 * @property {(...path: string[]) => Promise<URL | void>} source
 * @property {(config: {}) => {}} config
 */

/**
 * @type {Validator<Module>}
 */
const SOURCE = /* @__PURE__ */ (() =>
  OBJECT({
    config: IS(Function),
    source: IS(Function),
  }))();
