import {Attribute} from '@acng/frontend-stargazer';
import {HIDDEN} from '@acng/frontend-bounty/dom/attribute.js';
import {remove} from '@acng/frontend-bounty/dom/move.js';
import {attributeSelector} from '@acng/frontend-bounty/dom/query.js';
import {Watch} from '@acng/frontend-relativity';

import {ctxVipPoints} from '../context/points.js';
import {animate} from '../../layout/service/animate-number.js';
import {achievementFeature} from '../config/feature.js';

const MODULE = 'achievement/attribute/data-achievement-points';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * @group DOM Attribute
 */
const ACHIEVEMENT_POINTS = 'data-vip-points';

Attribute(ACHIEVEMENT_POINTS, (name) => {
  DEBUG: if (VERBOSE) console.debug(MODULE, 'setup', name);
  const watch = Watch(attributeSelector(name), ctxVipPoints);

  return [
    (element) => {
      DEBUG: if (VERBOSE) element.setAttribute('debug', '');

      if (achievementFeature[HIDDEN]) {
        DEBUG: if (element.hasAttribute('debug')) console.info(MODULE, 'hidden feature', {name});

        remove(element);
      }
    },
    (element) => watch(element, animate),
  ];
});
