import {get, post, put} from 'acng/core/service/backend.js';

/**
 * @typedef MailConfigData
 * @property {boolean} transaction_subscribed
 * @property {boolean} informer_subscribed
 * @property {boolean} bonus_subscribed
 * @property {boolean} newsletter_subscribed
 * @property {boolean} unsubscribed
 * @property {boolean} bounced
 */

/**
 * @typedef MailConfigPutData
 * @property {boolean} [transaction_subscribed]
 * @property {boolean} [informer_subscribed]
 * @property {boolean} [bonus_subscribed]
 * @property {boolean} [newsletter_subscribed]
 * @property {?string} subscription - User id as string
 * @property {?string} email
 * @property {?string} hash
 */

/**
 * @typedef Generate2faData
 * @property { string } key
 * @property { string } svg
 */

/**
 * @typedef CountryData
 * @property { string } countries_iso_code_2
 * @property { string } countries_iso_code_3
 * @property { string } currency
 * @property { number } id
 */

/**
 * @typedef UpdateProfileRequestData
 * @property { string= } country
 * @property { string= } birthday
 * @property { string= } gender
 * @property { string= } weight
 * @property { string= } height
 * @property { string= } ethnic
 * @property { string= } eyecolor
 * @property { string= } haircolor
 * @property { string= } hairlength
 * @property { string= } body
 * @property { string= } bodyhair
 * @property { string= } pubichair
 * @property { string= } brasize
 * @property { string= } glasses
 * @property { string= } tattoos
 * @property { string= } piercings
 * @property { string= } marital
 * @property { string= } smoking
 */

/**
 * @typedef get2faData
 * @property { boolean } active
 */

/**
 * @param {HTMLElement} element
 * @param {string} authParams
 * @returns {Promise<MailConfigData>}
 */
export const getMailConfig = (element, authParams) =>{
  return get(`user/mail-config?${authParams}`, element);
}

/**
 * @param {MailConfigPutData} data
 * @param {HTMLElement} [element]
 * @returns {Promise<void>}
 */
export const putMailConfig = (data, element) => put(`user/mail-config`, data, element);

/**
 * @param {string} code
 * @param {HTMLElement} [element]
 * @returns {Promise<AuthUserData>}
 */
export const confirmDevice = (code, element) => post('user/confirm-device', {code}, element);

/**
 * @param {string} [code]
 * @param {HTMLElement} [element]
 * @returns {Promise<void>}
 */
export const activate2fa = (code, element) => post('user/2fa-setup', {code}, element);

/**
 * @param {string} [code]
 * @param {HTMLElement} [element]
 * @returns {Promise<void>}
 */
export const deactivate2fa = (code, element) => put('user/2fa', {code, deactivate: true}, element);

/**
 * @param {HTMLElement} [element]
 * @returns {Promise<get2faData>}
 */
export const get2fa = (element) => get('user/2fa', element);

/**
 * @param {HTMLElement} [element]
 * @returns {Promise<Generate2faData>}
 */
export const generate2fa = (element) => get('user/2fa-setup', element);

/**
 * @param {HTMLElement} [element]
 * @returns {Promise<CountryData[]>}
 */
export const getCountries = (element) => get('country/all', element);

/**
 * @param {HTMLElement} element
 * @param {UpdateProfileRequestData} data
 * @returns {Promise<CountryData[]>}
 */
export const updateProfile = (data, element) => put('userProfile/update', data);

/**
 * @param {HTMLElement=} element
 * @returns {Promise<ProfileData>}
 */
export const getProfile = (element) => get('userProfile/view', element);
