/**
 * Provides basic *EventBus* functionality.
 *
 * Establishes a websocket connection over which the `TROI` communication takes place.
 *
 * @module EventBus
 * @author Jacob Viertel <jv@onscreen.net>
 * @see [EventBusUser](../../userPool/context/event-bus-user.js)
 */

import SockJS from 'sockjs-client';
import {AND, ENUM, INTEGER, OBJECT, OPTIONAL, RECORD, STRING, UNKNOWN, guard} from '@acng/frontend-rubicon';
import {createGlobalContext, provide} from '@acng/frontend-relativity';
import {Error} from '@acng/frontend-bounty/std/error.js';
import {clearInterval, clearTimeout, setInterval, setTimeout} from '@acng/frontend-bounty/dom/window.js';
import {createPromise, when, rejected} from '@acng/frontend-bounty/std/control.js';
import {isArray, push, size} from '@acng/frontend-bounty/std/array.js';
import {now} from '@acng/frontend-bounty/timing/now.js';
import {TROI} from '../service/env.js';
import {TIMESTAMP_MS} from '../model/time.js';

const MODULE = 'core/context/event-bus';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`);

/**
 * Provides instant updates about the troi websocket availability.
 *
 * @see [ctxEventBusUser](../../userPool/context/event-bus-user.js) for the availability of an authenticated troi user.
 *
 * @example
 * ```js
 * ctxEventBus.observe(el, (isConnected) => {
 *   if (!isConnected) {
 *     throw Error("TROI connection is not OPEN");
 *   }
 *
 *   publish(data);
 * });
 * ```
 *
 * @type {import('@acng/frontend-relativity').LegacyContext<boolean> & import('@acng/frontend-relativity').ObservableValue<boolean>}
 */
export const ctxEventBus = createGlobalContext(false);

/**
 * When sending using this method, `data` may be lost in some edge case.
 *
 * @see {@link request} for reliable communication.
 *
 * @param {Data} data
 *
 * @returns {boolean}
 * `false` if the event bus connection is not open and the `data` is not sent.
 * `true` if the data is *queued* to be sent
 */
export const publish = (data) => {
  DEBUG: if (VERBOSE) console.info(`${MODULE} <---`, data);

  return send(data);
};

/**
 * @param {Data} data
 *
 * @param {number} timeout
 * Timeout in milliseconds
 * The default `timeout` is 30s.
 *
 * @returns {Promise<Data>}
 */
export const request = async (data, timeout = 30) => {
  const ack = declareAck();

  data[ACK] = ack;

  DEBUG: if (VERBOSE) console.info(`${MODULE} <--?`, data);

  if (!send(data)) {
    return rejected(new NotOpenError());
  }

  const future = createPromise((...executor) => (pending[ack] = executor));

  const timeoutId = setTimeout(
    () => pending[ack][1](new AckTimeoutError(`timeout after ${timeout} seconds`)),
    timeout * 1000
  );

  const clear = () => {
    clearTimeout(timeoutId);
    delete pending[ack];
  };

  when(future, clear, clear);

  return future;
};

/**
 * @param {Data["type"]} type
 * @param {DataListener} listener
 * @returns {() => void}
 */
export const listen = (type, listener) => {
  DEBUG: if (VERBOSE) console.debug(`${MODULE} -<>-`, {type, listener});

  push((listeners[type] ??= []), listener);

  return () => removeListener(type, listener);
};

/**
 * @todo TODO don't export?
 * @param {Data["type"]} type
 * @param {DataListener} listener
 */
const removeListener = (type, listener) => {
  DEBUG: if (VERBOSE) console.debug(`${MODULE} >  <`, {type, listener});

  const list = listeners[type];

  if (list) {
    const i = list.indexOf(listener);
    if (i >= 0) {
      list.splice(i, 1);
    }
  }
};

/**
 * @param {Data} data
 * @returns {boolean}
 */
const send = (data) => {
  if (ws.readyState === 1) {
    ws.send(JSON.stringify(data));

    return true;
  }

  return false;
};

/**
 * @type {Record<number, [(data: Data) => void, (reason: unknown) => void]>}
 */
const pending = {};

const ACK = 'ack';

const declareAck = () => ++ackSeqNo;

/**
 * @type {Record<string, DataListener[] | undefined>}
 */
const listeners = {};

/**
 * @type {WebSocket}
 */
let ws;

let ackSeqNo = 0;

export class EventBusError extends Error {}
export class AckTimeoutError extends EventBusError {}
export class NotOpenError extends EventBusError {}

(() => {
  /**
   * @param {Data} data
   * @param {typeof publish} reply
   */
  const dispatch = (data, reply) => {
    const typeListeners = listeners[data.type];

    DEBUG: if (!isArray(typeListeners) || !size(typeListeners)) {
      console.warn(`${MODULE} dispatch without listeners`, data);
    }

    if (typeListeners) {
      for (const listener of typeListeners) {
        listener(data, reply, data.error);
      }
    }
  };

  const connect = () => {
    ws = new SockJS(`${TROI}/chat`);
    //ws = new WebSocket('wss://troi1.devac.de/chat/websocket');

    DEBUG: if (VERBOSE) console.info(MODULE, ws);

    let pingWatchdog = 0;

    ws.onopen = (ev) => {
      DEBUG: if (VERBOSE) console.info(`${MODULE} open, start ping watchdog interval`, ev);

      provide(ctxEventBus, true);

      // Start ping watchdog
      const timeout = 3e4;
      lastPingAt = now();
      pingWatchdog = setInterval(() => {
        if (now() - lastPingAt > timeout) {
          DEBUG: console.error(`${MODULE} ${timeout / 1e3} seconds without ping. close websocket.`);

          ws.close(3000, PING);
        }
      }, timeout);
    };

    ws.onclose = (ev) => {
      DEBUG: if (VERBOSE) console.warn(`${MODULE} close, stop ping watchdog interval`, ev);
      console.log(ev.toString());

      for (const ack in pending) {
        pending[ack][1](new NotOpenError(`${ev.code} ${ev.reason}`));
      }

      console.error(ev);
      clearInterval(pingWatchdog);
      provide(ctxEventBus, false);
      setTimeout(connect, 1500);
    };

    ws.onmessage = (ev) => {
      const data = JSON.parse(ev.data);
      ASSERT: guard(data, BUS_DATA);

      DEBUG: if (data.error && !data.ack) {
        console.error(`${MODULE} --->`, data);
      } else if (VERBOSE) {
        console.info(`${MODULE} ${data.ack ? '!' : '-'}-->`, data);
      }

      dispatch(data, data[ACK] ? reply : publish);
    };
  };

  /**
   * @param {Data} data
   */
  const reply = (data) => {
    if (data[ACK]) {
      data.type = ACK;
    }

    return publish(data);
  };

  const PING = 'ping';

  let lastPingAt = now();

  listen(ACK, (data) => {
    const ack = data[ACK];

    if (!ack || !pending[ack]) {
      DEBUG: console.warn(`${MODULE} !--> ignore unexpected ack`, data);
      return;
    }

    pending[ack][data.error ? 1 : 0](data);
  });

  listen(PING, (data, reply) => {
    ASSERT: guard(data, BUS_PING);

    lastPingAt = now();
    data.type = 'pong';
    reply(data);
  });

  connect();
})();

/**
 * @callback DataListener
 * @param {Data} data
 * @param {(data: Data) => boolean} reply
 * @param {Data["error"]} error
 * @returns {void}
 */

/**
 * @typedef {{
 *  type: string;
 *  ack?: number;
 *  error?: {
 *     code: number;
 *     message: string;
 *   };
 *   [key: string]: unknown;
 * }} Data
 */

const BUS_DATA = /* @__PURE__ */ AND(
  OBJECT({
    type: STRING,
    ack: OPTIONAL(INTEGER),
    error: OPTIONAL(
      OBJECT({
        code: INTEGER,
        message: STRING,
      })
    ),
  }),
  RECORD(STRING, UNKNOWN)
);

const BUS_PING = /* @__PURE__ */ OBJECT({
  type: ENUM(/** @type {const} */ (['ping', 'pong'])),
  timestamp_ms: TIMESTAMP_MS,
});
