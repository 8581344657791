/**
 * If the version is outdated, show a recurring `popup` asking the `User` to restart the application.
 * The application will reload automatically after {@link waitTime} if the `user` does not constantly prevent this.
 *
 * The popup is triggered by a detected version mismatched uncached api call,
 * but is suppressed as long as the `AuthUser` is watching a `Livecam`.
 *
 * Since the acng core kubernetes deploy needs time to switch the pods, the popup is delayed by 5-10s.
 *
 * @example
 * ```sh
 * # development environment test command should trigger the `Outdated`
 * ./artisan version:set some-new-version-string
 * ```
 *
 * @author ACNG-Team
 * @author Jacob Viertel <jv@onscreen.net>
 * @author Markus Kirscht <markus.kirscht@iventuregroup.com>
 * @module Outdated
 */

// bounty, rubicon, discovery
import {createDiv, setText} from '@acng/frontend-bounty';
import {clearTimeout, setTimeout} from '@acng/frontend-bounty/dom/window.js';
import {ceil, min} from '@acng/frontend-bounty/std/number.js';
import {IS, OBJECT, guard} from '@acng/frontend-rubicon';
import {throttle} from '@acng/frontend-bounty/timing/throttle.js';
import {sleep} from '@acng/frontend-bounty/sleep.js';
import {now} from '@acng/frontend-bounty/timing/now.js';
import {popup} from '@acng/frontend-discovery';
// enterprise
import {livecamSession} from 'acng/livecam/context/session.js';
import {coreFeature} from 'acng/core/config/feature.js';

const MODULE = 'core/service/outdated';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`Import verbose ${MODULE}`);

let reloadAt = 0;
let timeout = 0;

/**
 * @notExported
 */
const waitTime = 30000;

/**
 * Trigger the popup asking the `User` to reload the app.
 * Call this on a detected version mismatch with the backend.
 *
 * @see [Template](../../../../html/core/onsw-outdated.html)
 *
 * @see [Backend-Service](../backend.js)
 * @see [httpInterceptor](../../factory/httpInteceptor.js)
 */
export const setOutdated = () => {
  if (livecamSession) {
    DEBUG: if (VERBOSE) console.info(MODULE, 'livecam session prevents outdated popup.', {livecamSession});
    return;
  }

  popupOutdated();
};

const popupOutdated = throttle(async () => {
  await allOfYourPodsAreBelongToUs();

  const template = await coreFeature.render('onsw-outdated');
  const div = createDiv();
  const scope = {
    reload: () => location.reload(),
    extend: () => popup().close(div),
  };

  /**
   * @type {Parameters<import("@acng/frontend-stargazer").Engine["toElement"]>[2]}
   */
  const renderFn = (nodes) => {
    ASSERT: guard(
      nodes,
      OBJECT({
        seconds: IS(Text),
        bar: IS(HTMLElement),
      })
    );

    const {seconds, bar} = nodes;
    const printSeconds = () => setText(seconds, `${ceil((reloadAt - now()) / 1000)}`);

    (function tick() {
      const p = 100 * min(1, (reloadAt - now()) / waitTime);

      if (p > 0) {
        DEBUG: if (VERBOSE) console.debug(MODULE, 'tick', p);

        bar.style.width = `${min(p, 100)}%`;
        printSeconds();
        timeout = setTimeout(tick, 1000);
        return;
      }

      DEBUG: if (VERBOSE) console.warn(MODULE, 'reload');

      scope.reload();
    })();
  };

  DEBUG: if (VERBOSE) console.info(MODULE, 'popup', div);

  reloadAt = now() + waitTime;
  await popup().show(template.toElement(div, scope, renderFn));
  clearTimeout(timeout);
}, waitTime);

const initialRandomWaitTimeCoefficient = 5000;

/**
 * Just wait between five and ten seconds.
 */
const allOfYourPodsAreBelongToUs = async () => {
  const sleepTime = (1 + Math.random()) * initialRandomWaitTimeCoefficient;
  DEBUG: if (VERBOSE) console.debug(MODULE, 'additional random sleep', sleepTime);
  await sleep(sleepTime);
};

DEBUG: if (VERBOSE) popupOutdated.enableDebug(MODULE);
