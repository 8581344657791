import {STRING, TUPLE, guard} from '@acng/frontend-rubicon';
import {remove, set} from '@acng/frontend-bounty/dom/attribute.js';
import {Attribute} from '@acng/frontend-stargazer';
import {CLICK_SPECIAL_GAME} from './click-game';
import {CHALLENGE_KEY, USE_CHALLENGE, WITHOUT_ACHIEVEMENT} from 'acng/achievement/attribute';

/**
 * Add a click listener that starts the game for the special challenge.
 * Remove the element if the challenge has already been achieved by the user.
 *
 * This is just a shortcut for three underlying attributes.
 *
 * #### Formal Syntax
 * ```
 * click-special = "<name><year>:<achievement-key>"
 * <name> :=
 * <year> :=
 * <achievement-key> := $(artisan achievement:key "<name><year>")
 * ```
 *
 * @example
 * ```html
 * <button click-special="valentine2025">Click to start the game!</button>
 * ```
 * @see {@link CLICK_SPECIAL_GAME | `[click-special-game]`}
 * @see {@link WITHOUT_ACHIEVEMENT | `[without-achievement]`}
 * @see {@link USE_CHALLENGE | `[use-challenge]`}
 * @group DOM Attribute
 */
export const CLICK_SPECIAL = 'click-special';

Attribute(CLICK_SPECIAL, () => [
  (element, value) => {
    const match = /([A-Za-z]+)(\d+):(.*)/.exec(value);
    ASSERT: guard(match, SYNTAX);
    const [, name, year, key] = match;
    const challenge = `${name}${year}`;

    remove(element, CLICK_SPECIAL);
    set(element, CLICK_SPECIAL_GAME, name);
    set(element, WITHOUT_ACHIEVEMENT, challenge);
    set(element, USE_CHALLENGE, challenge);
    set(element, CHALLENGE_KEY, key);
  },
  ,
]);

/**
 * Validate the attribute value syntax.
 */
const SYNTAX = /* @__PURE__ */ TUPLE(STRING, STRING, STRING, STRING);
