import {addClass, append, body, createSpan, onClick, removeChildNodes, setText} from '@acng/frontend-bounty';
import {CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {getIcon} from 'acng/core/service/icon';
import {Regard} from '../model/regard';

export default 'onswRegardButton';
export const buttonComponent = {
  bindings: {
    label: '@',
  },
  controller: ['$element', 'user', '$translate', controller],
};

ctxAmateur[CTX_SUBSCRIBE_TAGNAME]('onsw-regard-button');

type User = import('acng/userPool/factory/user').User;
type Controller = angular.IController & {
  label: string;
};

function controller(
  this: Controller, //
  $element: JQLite,
  user: User,
  $translate: angular.translate.ITranslateService
) {
  const element = $element[0];
  addClass(element, 'ons-item');

  ctxAmateur[CTX_OBSERVE](element, async amateur => {
    removeChildNodes(element);
    if (!amateur) {
      return;
    }
    const box = createSpan('box');
    const label = createSpan('label');
    const [icon, translated] = await Promise.all([
      getIcon('regard'), //
      $translate(this.label || 'regard.gift'),
    ]);
    onClick(box, () => {
      if (user.guestSignup('regard.signupRequired', {nickname: amateur.getNickname()})) {
        return;
      }

      if (!user.fulfillsFskRequirement(18, 'regard.paymentRequired', {nickname: amateur.getNickname()})) {
        return;
      }

      addClass(body, 'regard-shop-open');
      Regard.recipient = amateur;
    });
    setText(label, translated);
    append(box, icon, label);
    append(element, box);
  });
}
