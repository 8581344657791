// @ts-nocheck do not fix deprecated tile
import angular from 'angular';
import {LocalActor} from '@acng/frontend-relativity';
import {ctxGallery} from 'acng/core/context/gallery.js';

const providePictureSet = LocalActor(ctxGallery);

angular.module('picturePool')

  .factory('PictureSetTile', ['AmateurTile', 'PictureSet', (AmateurTile, PictureSet) => {
    class PictureSetTile extends AmateurTile
    {
      load(scope, set_id) {
        return PictureSet.get(set_id)
          .then(set => {
            providePictureSet(this.el, set);
            this.apply({
              fnMain: 'detail',
              set: set,
              title: set.getTitle(),
              mediaId: set.id,
              mediaType: 'PictureSet',
              mediaSetId: set.id,
              publishedAt: new Date(set.published).toLocaleString(document.body.lang, {dateStyle: 'medium', timeStyle: 'short'}),
              image: set.getImageUrl(),
              click: () => set.goto()
            });
            return super.load(scope, `${set.user_id}`)
              .finally(() => {
                scope.desc = set.getDescription();
              });
          })
          .catch(err => {
            if (err.status == 404) {
              PictureSet.doNotShow.push(parseInt(set_id));
            }
            throw err;
          });
      }
      hookname() {
        return 'media';
      }
    }
    return PictureSetTile;
  }]);
