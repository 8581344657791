import {isNull} from '@acng/frontend-bounty/std/value.js';
import {OPEN, set} from '@acng/frontend-bounty/dom/attribute.js';
import {find} from '@acng/frontend-bounty/dom/document.js';
import {has} from '@acng/frontend-bounty/style/element.js';
import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';

import {onClickElement} from '@acng/frontend-bounty/dom/event.js';
import {skipPath} from './auto-close.js';
import {STYLE_MENU_MAIN, STYLE_OPEN, swapClass} from '../service/style.js';
import {IS, REGEX, guard} from '@acng/frontend-rubicon';
import {isHTMLElement} from '@acng/frontend-bounty/dom/type.js';
import {STYLE_ACTIVE} from '../config/style.js';
import {DETAILS} from '@acng/frontend-bounty/dom/element.js';

/**
 * @group DOM Attribute
 */
export const DATA_TOGGLE_MENU = 'data-toggle-menu';

defineRegistryAttribute(DATA_TOGGLE_MENU, (name) => {
  const CLOSE = 'close';

  defineRenderAttribute(name, (element, value) => {
    ASSERT: guard(value, REGEX(/^(open|close)?$/));

    const force = value === OPEN ? true : value === CLOSE ? false : null;

    if (isNull(force)) {
      swapClass(element, STYLE_ACTIVE, false);
    }

    onClickElement(element, () => {
      const target = find(`.${STYLE_MENU_MAIN}`);
      ASSERT: guard(target, IS(HTMLElement));
      const isOpen = force ?? !has(target, STYLE_OPEN);

      if (isNull(force)) {
        swapClass(element, STYLE_ACTIVE, isOpen);
      }

      DEBUG: if (element.hasAttribute('debug')) console.debug({element, target, isOpen});

      skipPath(target);
      if (isHTMLElement(target, DETAILS)) {
        set(target, OPEN, '');
      } else {
        swapClass(target, STYLE_OPEN, isOpen);
      }
    });
  });
});
