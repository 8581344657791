import {defineRenderAttribute} from "@acng/frontend-stargazer";
import {TITLE} from "@acng/frontend-bounty/dom/attribute.js";
import {IS, guard} from "@acng/frontend-rubicon";

/**
 * @group DOM Attribute
 */
export const DATA_AUTO_TITLE = 'data-auto-title';

defineRenderAttribute(DATA_AUTO_TITLE, () => (element) => {
  ASSERT: guard(element, IS(HTMLElement));

  element[TITLE] = element.textContent ?? '';
});
