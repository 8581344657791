angular.module('core').factory('http', [
  '$http',
  'API_origin',
  'API_cdn',
  'SystemCdnUsage',
  ($http, API_origin, API_cdn, SystemCdnUsage) => {
    class Http {
      constructor(useCdnForCalls) {
        this.setApiPrefix(SystemCdnUsage, useCdnForCalls);

        /*
        $timeout(() => {
          sock.registerHandler('system.cdnUsage', data => {
            this.setApiPrefix(data.state, useCdnForCalls);
          });
        });
        */
      }

      setApiPrefix(cdnUsage, useCdnForCalls) {
        this.apiPrefix = useCdnForCalls ? (cdnUsage ? API_cdn : API_origin) : '';
      }

      getApiUrl() {
        return this.apiPrefix;
      }

      get(url, config = {}) {
        return $http.get(this.apiPrefix + url, config);
      }

      post(url, data, config = {}) {
        return $http.post(this.apiPrefix + url, data, config);
      }

      put(url, data, config = {}) {
        return $http.put(this.apiPrefix + url, data, config);
      }

      delete(url, config = {}) {
        return $http.delete(this.apiPrefix + url, config);
      }

      // $http.head
      // $http.jsonp
      // $http.patch
    }

    const cdn = new Http(true);
    const origin = new Http();
    return (useCdn) => (useCdn ? cdn : origin);
  },
]);
