import {NODES, Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {
  ReferenceError,
  clearTimeout,
  getComputedStyleValue,
  removeChildNodes,
  setText,
  setTimeout,
  whenAll,
} from '@acng/frontend-bounty';
import wasmURL from 'vmsg/vmsg.wasm';

import {asset} from 'acng/core/service/env.js';
import {spinner} from 'acng/core/service/spinner.js';
import {ctxLivecamSession} from 'acng/livecam/context/session.js';
import {inject} from 'acng/core/service/ng.js';

import {messengerFeature} from '../config/feature.js';
import {ctxMessageDraft, getMessageDraft} from '../context/message-draft.js';
import {formatTime} from '../service/format-time.js';
import {popup} from '@acng/frontend-discovery';
import {CTX_OBSERVE, CTX_VALUE} from '@acng/frontend-relativity/minify';
import {guard} from '@acng/frontend-rubicon';

const MODULE = 'messenger/widget/voice-recorder';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`)

const TEMPLATE = /* @__PURE__ */ NODES({
  container: HTMLElement,
  btnPlay: HTMLButtonElement,
  btnStop: HTMLButtonElement,
  seconds: Text,
  duration: Text,
  countdown: Text,
});

class VoiceRecorder extends Widget {
  static consumables = [ctxMessageDraft, ctxLivecamSession];

  get composer() {
    const message = ctxMessageDraft[CTX_VALUE](this);
    if (!message) {
      throw ReferenceError();
    }
    return message;
  }

  render() {
    ASSERT: guard(this.nodes, TEMPLATE);

    ctxMessageDraft[CTX_OBSERVE](this, (composer, previous) => {
      DEBUG: if (VERBOSE) console.debug(`${MODULE} observing composer`, {...composer});

      if (!composer) {
        return;
      }

      if (composer != previous || !composer?.allowVoice) {
        this.reset();
      }
      composer.applyState(this);
    });

    ctxLivecamSession[CTX_OBSERVE](this, (session) => {
      DEBUG: if (VERBOSE) console.debug(`${MODULE} observing livecam session`, {...session});

      getMessageDraft(this)?.check();
    });
  }

  reset() {
    DEBUG: console.info(`${MODULE} reset`);
    // TODO improve waveSurfer.destroy
    ASSERT: guard(this.nodes, TEMPLATE);
    removeChildNodes(this.nodes.container);
    this.composer.wait = false;
    this.composer.voice = false;
  }

  async send() {
    await this.composer.send(this);
    this.reset();
  }

  async record() {
    if (!inject('user').isPremium()) {
      inject('payment').overlay('messenger.voicemessage.payment_required');
      return;
    }

    DEBUG: console.info(`${MODULE} record`);

    const composer = this.composer;
    ASSERT: console.assert(!composer.voice, `${MODULE} already seems to be recoding`);

    composer.wait = true;
    try {
      await spinner(record(this), this);
    } catch (reason) {
      DEBUG: console.error(reason);
      popup(this).error(reason instanceof Error ? reason.message : `${reason}`);
      this.reset();
    }
  }
}

defineCustomWidget(messengerFeature, 'onsw-voice-recorder', VoiceRecorder);

/**
 * @param {VoiceRecorder} host
 */
const record = async (host) => {
  ASSERT: guard(host.nodes, TEMPLATE);

  const {container, btnStop, countdown} = host.nodes;

  const [{default: WaveSurfer}, {default: RecordPlugin}, {Recorder}] = await whenAll([
    import('wavesurfer.js'),
    import('wavesurfer.js/dist/plugins/record.js'),
    import('vmsg'),
  ]);

  let timeout = NaN;

  const waveSurferRecorder = WaveSurfer.create({
    container,
    waveColor: getComputedStyleValue(container, '--wavesurfer-wave-color'),
    progressColor: getComputedStyleValue(container, '--wavesurfer-wave-progress-color'),
    barWidth: 4,
    barRadius: 4,
    height: 30,
    cursorWidth: 0,
  });

  const recorder = waveSurferRecorder.registerPlugin(
    RecordPlugin.create({
      scrollingWaveform: true,
    })
  );
  recorder.on('record-start', () => {
    let secondsLeft = 10;

    (function tick() {
      setText(countdown, formatTime(secondsLeft));
      if (!secondsLeft) {
        recorder.stopRecording();
      } else {
        secondsLeft--;
        timeout = setTimeout(tick, 1000);
      }
    })();

    host.composer.voice = true;
    host.composer.wait = false;
  });
  recorder.on('record-end', async () => {
    clearTimeout(timeout);

    try {
      const blob = await spinner(vmsgRecorder.stopRecording(), host);
      waveSurferRecorder.destroy();
      await spinner(initPlayer(blob, host), host);
    } catch (reason) {
      DEBUG: console.error(reason);
      popup(host).error(reason instanceof Error ? reason.message : `${reason}`);
      host.reset();
    }
  });

  const vmsgRecorder = new Recorder({wasmURL: asset(wasmURL)});
  await vmsgRecorder.init();
  await vmsgRecorder.initWorker();

  recorder.startRecording();
  vmsgRecorder.startRecording();

  btnStop.onclick = () => recorder.stopRecording();
};

/**
 * @param {Blob} recordedData
 * @param {VoiceRecorder} host
 */
const initPlayer = async (recordedData, host) => {
  ASSERT: guard(host.nodes, TEMPLATE);

  const {container, btnStop, btnPlay, seconds, duration} = host.nodes;

  const WaveSurfer = (await import('wavesurfer.js')).default;

  const waveSurfer = WaveSurfer.create({
    container,
    waveColor: getComputedStyleValue(container, '--voicemessage-recorder-wave-color'),
    progressColor: getComputedStyleValue(container, '--voicemessage-recorder-wave-progress-color'),
    barWidth: 4,
    barRadius: 4,
    height: 30,
    cursorWidth: 0,
    url: URL.createObjectURL(recordedData),
  });

  waveSurfer.once('decode', (maxTime) => {
    host.composer.voice = recordedData;
    setText(seconds, formatTime(0));
    setText(duration, formatTime(maxTime));
  });
  waveSurfer.on('timeupdate', (currentTime) => setText(seconds, formatTime(currentTime)));
  waveSurfer.on('play', () => (host.composer.playing = true));
  waveSurfer.on('pause', () => {
    host.composer.playing = false;
    setText(seconds, formatTime(waveSurfer.getCurrentTime()));
  });

  btnPlay.onclick = () => waveSurfer.play();
  btnStop.onclick = () => waveSurfer.stop();
};
