import angular from 'angular';
import {get} from '../service/amateur.js';

angular.module('amateurPool')

  .directive('onswAmateurCommonFilter', ['$injector', 'user', ($injector, user) => {

    return {
      scope: {
        filter: '<'
      },
      template: `
<form class="box">
    <label>
        <select ng-options="x as y | translate for (x,y) in categories" ng-model="filter.category" ng-change="change()"></select>
    </label>
    <label>
        <span translate="Nickname"></span>
        <input type="text" ng-model-options="{debounce: 1000}" ng-model="nickname" ng-focus="enableSuggestions()" ng-blur="disableSuggestions()" ng-keydown="keyAction($event)" ng-keypress="keyAction($event)">
    </label>
    <div ng-show="showSuggestions && suggestionsList !== null && suggestionsList.length > 0" class="suggestions ons-layout columns popup pad10 center down" ng-mouseleave="mouseLeavingSuggestions()">
      <div class="box">
          <div class="suggestion" ng-repeat="suggestion in suggestionsList" ng-class="{selected: suggestion.selected}" ng-mouseenter="mouseOverSuggestion(suggestion)" ng-click="suggestion.amateur.goto();" onsw-hook hookname="smallAmateurInfo" hook-data="suggestion.amateur"></div>
      </div>
    </div>
</form>`,
      link: function (scope, element) {

        element.addClass('ons-form');

        scope.user = user;

        scope.categories = {
          'all': 'amateurPool.allAmateurs',
          'new': 'amateurPool.newAmateurs',
          'online': 'amateurPool.onlineAmateurs',
          'top': 'amateurPool.topAmateurs',
          'livedating': 'amateurPool.allLivedates'
        };

        scope.showSuggestions = false;
        scope.suggestionsList = null;

        scope.enableSuggestions = () => {
          scope.showSuggestions = true;
        };

        scope.disableSuggestions = () => {
          if (mouseOverSuggestions) {
            return;
          }

          scope.showSuggestions = false;
        };

        let mouseOverSuggestions = false;

        scope.mouseOverSuggestion = suggestion => {
          mouseOverSuggestions = true;
          scope.selectSuggestion(suggestion);
        };

        scope.mouseLeavingSuggestions = () => {
          mouseOverSuggestions = false;
          scope.selectSuggestion(null);
        };

        let selectedSuggestionIndex = -1;

        function modifySelectSuggestion(addition) {
          if (selectedSuggestionIndex === -1 && addition === -1) {
            return;
          }

          const newIndex = selectedSuggestionIndex + addition;
          if (newIndex <= -1 || newIndex >= scope.suggestionsList.length) {
            scope.selectSuggestion(null);
          } else {
            scope.selectSuggestion(scope.suggestionsList[newIndex]);
          }
        }

        scope.keyAction = event => {
          if (!scope.suggestionsList) {
            return;
          }

          switch (event.keyCode) {
            case 38: // ↑
              modifySelectSuggestion(-1);
              event.preventDefault();
              break;
            case 40: // ↓
              modifySelectSuggestion(1);
              event.preventDefault();
              break;
            case 13: // enter
              if (selectedSuggestionIndex > -1) {
                event.preventDefault();
                scope.suggestionsList[selectedSuggestionIndex].amateur.goto();
              } else {
                event.target.blur();
              }
              break;
          }
        };

        scope.selectSuggestion = item => {
          if (!scope.suggestionsList) {
            return;
          }

          selectedSuggestionIndex = -1;
          for (let i = 0; i < scope.suggestionsList.length; i++) {
            scope.suggestionsList[i].selected = scope.suggestionsList[i] === item;
            if (scope.suggestionsList[i].selected) {
              selectedSuggestionIndex = i;
            }
          }
        };

        if ($injector.has('Livecam')) {
          scope.categories['livecam'] = 'livecam.livecams';
        }

        const specialReplacementChars = {
          '\u00e4': 'a',
          '\u00c4': 'A',
          '\u00fc': 'u',
          '\u00dc': 'U',
          '\u00f6': 'o',
          '\u00d6': 'O',
          '\u00df': 'ss'
        };

        scope.$watch('filter.nickname', n => scope.nickname = Array.isArray(n) ? n.join(' ') : '');
        scope.$watch('nickname', n => {
          scope.suggestionsList = null;

          if (typeof n !== 'string') {
            n = '';
          }

          n = n.replace(new RegExp(`[${Object.keys(specialReplacementChars).join('')}]`, 'g'), match => specialReplacementChars[match])
            .replace(/(?!-)\W/g, '');

          scope.filter.nickname = [n];
          scope.change();
        });

        scope.$watch('filter.searchResults', res => {
          selectedSuggestionIndex = -1;
          if (!Array.isArray(res) || !scope.nickname || scope.nickname.length < 2) {
            scope.suggestionsList = null;
            return;
          }

          scope.suggestionsList = res.slice(0, 10).map(amateurId => {
            const hookData = { amateur: null, selected: false };

            get(amateurId).then(amateur => hookData['amateur'] = amateur).catch(() => {});

            return hookData;
          });
        });

        scope.change = function () {
          scope.filter.trigger = 'user';
          scope.filter.dispatchEvent(new CustomEvent('change'));
        };
      }
    };
  }]);
