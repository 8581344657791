/**
 * Contains the logic for the automatic display of active payment bonuses in the slideshows for the *AuthUser*.
 * Deals with the following slideshows
 * - Slideshow on the Start Page
 * - Slideshow in the Sidebar
 *
 * ### TODO
 * 1. CSS identifiers must not begin with a number! (e.g. "100percent")
 *     a.t.m. acng style selectors might use those css class identifiers.
 *
 * @module PaymentBonus
 * @author Jacob Viertel <jv@onscreen.net>, Nils Engel <ne@onscreen.net>
 * @see [AuthUser](../../userPool/context/auth-user.js)
 */

// bounty, rubicon
import {extend} from '@acng/frontend-bounty/object.js';
import {ENUM, IS, guard} from '@acng/frontend-rubicon';
import {get, remove, set} from '@acng/frontend-bounty/dom/attribute.js';
import {append, query} from '@acng/frontend-bounty/dom/template.js';
import {classSelector} from '@acng/frontend-bounty/dom/query.js';
// stargazer & relativity
import {rootScope} from '@acng/frontend-stargazer';
// enterprise & relativity
import {ctxBonusList} from 'acng/payment/context/bonus-list.js';
import {GlobalWatch, globalValue} from '@acng/frontend-relativity';
import {getSlideshowSidebar, getSlideshowUserHome} from 'acng/layout/service/sequence.js';
import {media} from 'acng/core/service/env.js';
import {DATA_DELAY, SLIDESHOW_INDEX} from 'acng/layout/element/slideshow.js';
import {NOVA_TEASER} from 'acng/layout/style/teaser.js';
import {paymentFeature as payment} from '../config/feature.js';
import {SEQUENCE_NAME, TIMING_END, TIMING_START} from 'acng/layout/attribute/index.js';
import {removeNode} from '@acng/frontend-bounty';

const MODULE = 'payment/context/slideshow';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`);

(() => {
  const teaser = payment.spawn('bonus-teaser');

  /**
   * @type {Promise<import('acng/special/run/source.js').Module & {
   *   default: (name: string, format: string) => string;
   * }>}
   */
  const module = import(`${media.assets_ext}/bonus.js`);

  teaser.source = async ([name, format]) => {
    const {config, source} = await module;

    for (const bonus of globalValue(ctxBonusList)) {
      if (bonus.name == name) {
        const src = await source(name);
        const engine = teaser.spawn(name, format);
        const {template} = engine;
        ASSERT: guard(src, IS(URL));

        // template.setAttribute('debug', '');
        // TODO(1.)
        template.className = `${NOVA_TEASER} bonus ${bonus.name} bonus-${bonus.name}`;
        set(template, TIMING_START, bonus.created_at);
        set(template, TIMING_END, bonus.expires_at ?? '');
        set(template, DATA_DELAY, '10');
        set(template, 'data-bonus', bonus.name);
        await engine.fetch(src, config(extend(rootScope, {...bonus, format})));
      }
    }
  };

  const slideshow = payment.spawn('slideshow');

  slideshow.source = async ([format]) => {
    ASSERT: guard(format, ENUM(FORMATS));
    const {template: sequence} = slideshow.spawn(format);
    DEBUG: if (VERBOSE) console.log('create payment bonus teaser slides', {format});

    for (const bonus of globalValue(ctxBonusList)) {
      const scene = await teaser.lookup(bonus.name, format);

      set(scene, SEQUENCE_NAME, {overlay: 'slideshow-user-home', sidebar: 'slideshow-sidebar'}[format]);
      append(sequence, scene);
    }
  };

  const FORMATS = /** @type {const} */ (['overlay', 'sidebar']);

  GlobalWatch(ctxBonusList)(async (bonusList) => {
    DEBUG: if (VERBOSE) console.warn(`${MODULE} update bonus teaser in the slideshows`, bonusList);
    const slideshowSidebar = getSlideshowSidebar().template;
    const slideshowUserHome = getSlideshowUserHome().template;

    for (const element of [...slideshowUserHome.content.children, ...slideshowSidebar.content.children]) {
      const bonusName = get(element, 'data-bonus');

      if (bonusName && !bonusList.find((bonus) => bonus.name === bonusName)) {
        removeNode(element);
      }
    }

    for (const bonus of bonusList.slice().reverse()) {
      const homeTeaser = await teaser.lookup(bonus.name, 'overlay');
      const sidebarTeaser = await teaser.lookup(bonus.name, 'sidebar');

      if (!query(slideshowUserHome, `.${NOVA_TEASER}.bonus-${bonus.name}`)) {
        remove(slideshowUserHome, SLIDESHOW_INDEX);
        remove(slideshowSidebar, SLIDESHOW_INDEX);
        slideshowUserHome.content.prepend(homeTeaser);
        slideshowSidebar.content.prepend(sidebarTeaser);
      }
    }
  });
})();
