import {INTEGER, NULLABLE, OBJECT, STRING} from '@acng/frontend-rubicon';

const FILE = /* @__PURE__ */ NULLABLE(STRING);

export const PAYTTACHMENT_DATA = /* @__PURE__ */ OBJECT({
  id: INTEGER,
  type: STRING,
  price: INTEGER,
  file: FILE,
  thumb: STRING,
  size: INTEGER,
  amateur_id: STRING,
});
