/**
 * An `Element` with the `:responsive` attribute may have special formulated `class` entries.
 * Each entry will be added or removed according to the element´s width.
 *
 * @example
 * ```html
 * <div :responsive class="small-0-500 medium-500-1000 large-1000-"></div>
 * ```
 *
 * @module :responsive
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.83.0
 * @see [Element Variant](../widget/section.js)
 */

import {defineRenderAttribute} from '@acng/frontend-stargazer';
import {IS, guard} from '@acng/frontend-rubicon';

import {connect} from '../widget/section.js';

defineRenderAttribute(':responsive', (element) => {
  DEBUG: if (element.hasAttribute('debug')) console.info(':responsive', {element});
  ASSERT: guard(element, IS(HTMLElement));

  connect(element);
});
