angular.module('core').directive('onsScroll', ['$window', '$compile', ($window, $compile) => {

  function link(scope, element, box) {
    const overlay = angular.element('<div class="scroll-overlay">');
    const bar = angular.element('<span class="bar">');
    const slider = angular.element('<span class="slider">');
    const barX = angular.element('<span class="bar-x">');
    const sliderX = angular.element('<span class="slider-x">');
    const btnScrollTop = $compile('<span class="scroll-top" ons-icon="toTop">')(scope);
    const btnPageDown = $compile('<span class="page-down" ons-icon="down">')(scope);
    const btnLeft = $compile('<span class="left" ons-icon="::back">')(scope);
    const btnRight = $compile('<span class="right" ons-icon="::forward">')(scope);
    overlay.append(bar);
    overlay.append(barX);
    bar.append(slider);
    barX.append(sliderX);
    overlay.append(btnLeft.on('click', () => box.animate({ scrollLeft: 0 })));
    overlay.append(btnRight.on('click', () => box.animate({ scrollLeft: scrollLeft + (clientWidth/2) })));
    overlay.append(btnScrollTop.on('click', () => box.animate({ scrollTop: 0 })));
    overlay.append(btnPageDown.on('mousedown', function () {
      scroll(clientHeight);
      clickHold(clientHeight, scrollTopMax);
    }));
    element.append(overlay);

    let mode = 'v';
    let clientHeight,scrollHeight,scrollTopMax,sliderRatio,scrollTop = 0;
    let clientWidth,scrollWidth,scrollLeftMax,sliderRatioX,scrollLeft = 0;

    scope.$watchGroup([
      () => box[0].scrollHeight,
      () => box[0].clientHeight,
      () => box[0].scrollWidth,
      () => box[0].clientWidth],
    v => changeDimensions(v[0], v[1], v[2], v[3]));

    const resizeObserver = new ResizeObserver(entries => {
      onWindowResize();
    });
    resizeObserver.observe(box[0]);
    /* const mutationObserver = new MutationObserver(entries => {
      $log.info('MUTATION', entries);
    });
    mutationObserver.observe(box[0], {childList: true, subtree: false});*/

    function changeDimensions(newScrollHeight, newClientHeight, newScrollWidth, newClientWidth) {
      //$log.info('SCROLL dimension change', {clientHeight, newClientHeight, scrollHeight, newScrollHeight});
      clientHeight = newClientHeight;
      scrollHeight = newScrollHeight;
      scrollTopMax = Math.max(scrollHeight - clientHeight, 0);
      sliderRatio = scrollHeight ? clientHeight / scrollHeight : 1;
      element.toggleClass('scroll-void', scrollHeight <= clientHeight);
      slider[0].style.transform = 'scaleY(' + sliderRatio + ')';

      clientWidth = newClientWidth;
      scrollWidth = newScrollWidth;
      scrollLeftMax = Math.max(scrollWidth - clientWidth, 0);
      sliderRatioX = scrollWidth ? clientWidth / scrollWidth : 1;
      element.toggleClass('scroll-void-x', scrollWidth <= clientWidth);
      sliderX[0].style.transform = 'scaleX(' + sliderRatioX + ')';

      changeScroll(scrollTop, scrollLeft);
    }

    function changeScroll(newScrollTop, newScrollLeft) {
      //$log.info('SCROLL scroll change', {scrollTop, newScrollTop});
      scrollTop = newScrollTop;
      element.toggleClass('scroll-min', !scrollTop);
      element.toggleClass('scroll-max', Math.ceil(scrollTop) >= Math.floor(scrollTopMax));
      slider[0].style.transformOrigin = 'center ' + clientHeight * scrollTop / scrollTopMax + 'px';

      scrollLeft = newScrollLeft;
      element.toggleClass('scroll-min-x', !scrollLeft);
      element.toggleClass('scroll-max-x', Math.ceil(scrollLeft) >= Math.floor(scrollLeftMax));
      sliderX[0].style.transformOrigin = clientWidth * scrollLeft / scrollLeftMax + 'px center';
    }

    function scroll(deltaY) {
      box[0].scrollTop = scrollTop + deltaY;
    }

    let huba = false;
    box[0].addEventListener('scroll', (evt) => {
      if (!huba) {
        huba = requestAnimationFrame(() => {
          changeScroll(box[0].scrollTop, box[0].scrollLeft);
          huba = false;
        });
      }
    }, { passive: true });

    function onWindowResize() {
      changeDimensions(box[0].scrollHeight, box[0].clientHeight, box[0].scrollWidth, box[0].clientWidth);
    }

    scope.$on('$destroy', () => {
      resizeObserver.unobserve(box[0]);
    });

    var timer, interval;
    function clickHold(deltaY, limitY) {
      timer = setTimeout(function () {
        interval = setInterval(function () {
          if (deltaY > 0 && scrollTop + deltaY > limitY) {
            deltaY = limitY - scrollTop;
          } else if (deltaY < 0 && scrollTop + deltaY < limitY) {
            deltaY = limitY - scrollTop;
          } else if (!deltaY) {

            //$(window).trigger('mouseup.acScroll'+id+'clickHold');
          }scroll(deltaY);
        }, 50);
      }, 500);

      function clear() {
        clearTimeout(timer);
        clearInterval(interval);
        $window.removeEventListener('mouseup', clear);
      }

      $window.addEventListener('mouseup', clear);
    }


    bar.on('click', false);
    bar.on('mousedown', function (e) {
      var deltaY = clientHeight * (e.offsetY < scrollTop * sliderRatio ? -1 : 1);
      scroll(deltaY);
      clickHold(deltaY / 2, e.offsetY / sliderRatio);
      return false;
    });

    slider.on('click', false);
    slider.on('mousedown', function (e) {
      var startY = e.pageY;
      var startScrollPos = scrollTop;
      function onmousemove(e) {
        var scrollOffset = (e.pageY - startY) / sliderRatio;
        box[0].scrollTop = startScrollPos + scrollOffset;
      }
      function onmouseup() {
        $window.removeEventListener('mousemove', onmousemove);
        $window.removeEventListener('mouseup', onmouseup);
        return false;
      }
      $window.addEventListener('mousemove', onmousemove);
      $window.addEventListener('mouseup', onmouseup);
      return false;
    });

    sliderX.on('click', false);
    sliderX.on('mousedown', function (e) {
      var startX = e.pageX;
      var startScrollPosX = scrollLeft;
      function onmousemoveX(e) {
        var scrollOffsetX = (e.pageX - startX) / sliderRatioX;
        box[0].scrollLeft = startScrollPosX + scrollOffsetX;
      }
      function onmouseupX() {
        $window.removeEventListener('mousemove', onmousemoveX);
        $window.removeEventListener('mouseup', onmouseupX);
        return false;
      }
      $window.addEventListener('mousemove', onmousemoveX);
      $window.addEventListener('mouseup', onmouseupX);
      return false;
    });
  }

  return {
    scope: false,
    link: (scope, element) => {
      const unreg = scope.$watch(() => {
        const box = element.children('.box');
        return box.length ? box : false;
      }, box => {
        if (!box) {
          DEBUG: console.info('ons-scroll waiting for box', element[0]);
          return;
        }
        link(scope, element, box);
        unreg();
      });
    },
    restrict: 'C'
  };

}]);
