// @ts-nocheck
/**
 * @todo
 * - What if there is no image at all?
 *     Matchmatchmaker **must** be FSK18 only!
 */

import angular from 'angular';
import {listen, publish} from "acng/core/context/event-bus.js";
import {window} from '@acng/frontend-bounty';
import {get} from '../amateurPool/service/amateur.js';
import {matches} from './model.js';

angular.module('hotornot')
  .factory('Hotornot', ['$http', '$location', '$timeout', 'user', '$rootScope', '$q', ($http, $location, $timeout, user, $rootScope, $q) => {

    const hotlist = [];
    const votelist = [];
    const matchesList = [];
    hotlist.changed = 0;
    votelist.changed = 0;
    matchesList.changed = 0;

    function initLists(all) {
      hotlist.length = 0;
      votelist.length = 0;
      matchesList.length = 0;
      if (!Array.isArray(all)) {
        console.error('Hotornot initLists fail');
      } else {
        all.sort((u, v) => u.id - v.id);
        all.forEach(v => {
          switch (v.state) {
            case 'pending':
              votelist.unshift(`${v.amateur_id}`);
              votelist.changed++;
              break;
            case 'positive':
            case 'match':
              hotlist.unshift(`${v.amateur_id}`);
              hotlist.changed++;
              if (v.state === 'match') {
                matchesList.unshift(`${v.amateur_id}`);
                matchesList.changed++;
                matches.add(`${v.amateur_id}`);
              }
              break;
            case 'negative':
              break;
            default:
              console.warn('undefined state', v);
          }
        });
      }
      //$log.debug(hotlist, votelist, matchesList);
      return { hotlist, votelist, matchesList };
    }

    listen('hon.voted', (data) => {
      const i = votelist.indexOf(data.amateurId);
      if (i < 0) return;
      votelist.splice(i, 1);
      if (data.hot == 'hot') {
        hotlist.unshift(data.amateurId);
      }
      $rootScope.$apply();
    });

    listen('hon.matched', (data) => {
      if (data.amateurId && !matchesList.includes(data.amateurId)) {
        matchesList.push(data.amateurId);
        matches.add(data.amateurId);
        $rootScope.$apply();
      }
    });

    $rootScope.$on('userPool.login.success', () => {
      if (def) {
        loading = null;
        init().then(res => {
          def.resolve(res);
        });
      }
    });

    let loading = null;
    let def = null;
    let nextPeriod = null;
    let refreshTimer = null;
    function init(addVotes = true) {
      let promise = loading;
      if (!loading) {
        if (user.guest) {
          def = $q.defer();
          return loading = def.promise;
        }
        loading = $http.get('/api/hotornot/?addVotes=' + addVotes)
          .then(res => {
            if (!addVotes) {
              return initLists(res.data.votes);
            }
            nextPeriod = res.data.refreshInSeconds * 1000 + Date.now() + Math.floor(Math.random() * 5000);
            refreshTimer = $timeout(() => {
              loading = null;
              nextPeriod = null;
              refreshTimer = null;
              return init();
            }, Math.max(5000, nextPeriod - Date.now()));
            votelist.nextPeriod = nextPeriod;
            votelist.goto = Hotornot.goto;
            return initLists(res.data.votes);
          })
          .catch(err => {
            loading = null;
            console.error('Hotornot init fail', err);
            throw err;
          });
        promise = loading;
        if (!addVotes) loading = null;
      }
      return promise;
    }

    class Hotornot
    {

      static vote(amateurId, hot, imageId) {
        const i = votelist.indexOf(amateurId);
        if (i < 0) {
          console.error('amateur not votable');
          return false;
        }
        let list = null;
        switch (hot) {
          case 'hot':
            list = hotlist;
            break;
          case 'not':
            break;
          default:
            console.error('bad param');
            return;
        }
        return $http.put('/api/hotornot/' + amateurId, { state: hot, imageId })
          .then(() => {
            votelist.splice(i, 1);
            if (list) {
              list.unshift(amateurId);
              let positiveVoteImages = JSON.parse(window.localStorage.getItem('positiveVoteImages') ?? "{}");
              positiveVoteImages[amateurId] = imageId;
              window.localStorage.setItem('positiveVoteImages', JSON.stringify(positiveVoteImages));
              list.changed++;
              return 'positive';
            }
            return 'negative';
          });
      }

      static hotlist() {
        return init().then(lists => lists.hotlist);
      }

      static votelist() {
        return init().then(lists => lists.votelist);
      }

      static matchList() {
        return init().then(lists => lists.matchesList);
      }

      static isMatch(amateurId) {
        return matches.has(amateurId);
      }

      static getNextPeriod() {
        return nextPeriod;
      }

      static isActive() {
        return !!Hotornot.active;
      }

      static timer() {
        return refreshTimer;
      }

      static getMore() {
        loading = null;
        return init();
      }

      static goto() {
        $location.path('/hotornot');
      }
    }

    Hotornot.init = init;
    Hotornot.votes = hotlist;
    Hotornot.matches = matchesList;
    Hotornot.pending = votelist;

    return Hotornot;
  }])

  .directive('onsdHotornot', ['PimpMyRide', 'Hotornot', (PimpMyRide, Hotornot) => PimpMyRide('Hotornot', false, () => Hotornot.init(false).then(() => Hotornot))])

  .directive('onswHotornot', ['Widget', 'Hotornot', '$timeout', '$injector', '$q', (Widget, Hotornot, $timeout, $injector, $q) => {

    return {
      scope: {},
      template: `
<div class="box" ng-class="{hasVotes: !!positives.length, noVotes: !positives.length}">
  <div class="ons-layout">
    <div class="box">
      <div ng-repeat="amateur in list | limitTo : -6 as res" onsw-hook class="hotornot-pending animate" ng-class="'pos' + (res.length-$index)" hookname="hotornot" hook-data="amateur"></div>
    </div>
  </div>
  <div class="ons-layout hotornot-positives animate" ng-show="positives.length">
    <div class="box">
      <div ng-repeat="amateur in positives | limitTo : positiveLimit" onsw-hook class="hotornot-positive animate" hookname="hotornot" hook-data="amateur"></div>
      <span ng-if="positives.length > 0" onsw-link class="button" label="hotornot.votesAndMatches" route="#/hotornot/votes"></span>
    </div>
  </div>
  <div class="timer" ng-if="!res.length">
    <div class="box">
      <div class="ons-text"><div class="box">{{ 'hotornot.timer' | translate }}</div></div>
      <div onsw-countdown class="hotornot" ts="{{stamp}}"></div>
    </div>
  </div>
  <div class="ons-layout textblock" ng-if="res.length">
    <div class="box">
      <div onsw-item class="ons-heading" label="'hotornot.title' | translate"></div>
      <div onsw-text translation-key="hotornot.description"></div>
    </div>
  </div>
</div>`,
      link: (scope, element) => {

        const widget = new Widget(scope, element, 'ons-layout');
        let actionslider, actionsliderTimer;

        if ($injector.has('Actionslider')) {
          actionslider = $injector.get('Actionslider');
          $timeout(actionslider.hide, 1000);
        }
        Hotornot.active = true;

        scope.positiveLimit = 0;
        let limitTimer;
        function setLimit() {
          scope.positiveLimit = Math.max(0, Math.floor(element.children('.box').width() * (window.innerWidth < 1024 ? 1 : .7) / (window.innerWidth < 1024 ? 95 : 115)));
          limitTimer = $timeout(setLimit, 1000);
        }

        limitTimer = $timeout(setLimit, 100);

        scope.$on('$destroy', () => {
          $timeout.cancel(actionsliderTimer);
          if (actionslider) {
            actionslider.show();
          }
          $timeout.cancel(limitTimer);
          Hotornot.active = false;
        });

        function filter(list) {
          return $q.all(list.map((pending) => get(pending).catch(() => list.splice(list.indexOf(pending), 1), list.changed++)))
            .then(() => list);
        }

        function update() {
          widget.busy(Hotornot.votelist().then(filter))
            .then(list => {
              scope.stamp = Hotornot.getNextPeriod();
              Hotornot.timer().then(update);
              return list;
            })
            .then(list => scope.list = list)
            .then(() => Hotornot.hotlist().then(filter))
            .then(list => scope.positives = list);
        }

        update();
      }
    };
  }])

  .directive('onswHotornotButton', ['Widget', 'Hotornot', (Widget, Hotornot) => {

    return {
      scope: {
        amateurId: '<',
        type: '@',
        imageId: '<'
      },
      template: '<span class="box" ng-click="click()" style="cursor:pointer"><span class="label">{{type}}</span></span>',
      link: (scope, element) => {

        const widget = new Widget(scope, element, 'ons-item');

        scope.click = () => {
          widget.ifNotBusy(() => Hotornot.vote(scope.amateurId, scope.type, scope.imageId))
            .then(res => {
              element.closest('[onsw-hook]').addClass(res);
            });
        };
      }
    };
  }]);
