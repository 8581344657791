import {guard} from '@acng/frontend-rubicon';
import {startUpFsk} from '../core/service/env.js';
import {POSSIBLE_FSK_LEVELS_DESC, POSSIBLE_FSK_KEYS, FSK_LEVEL} from '../core/model/fsk.js';

/**
 * @typedef {typeof POSSIBLE_FSK_LEVELS_DESC[number]} FskLevel
 * @typedef {typeof POSSIBLE_FSK_KEYS[number]} FskString
 */

class Fsk extends EventTarget {
  /**
   * @param {FskLevel} level
   */
  constructor(level) {
    super();
    this.level = level;
  }

  /**
   * @param {FskLevel} level
   */
  set(level) {
    var oldLevel = this.level;
    this.level = level;
    if (level != oldLevel) {
      this.dispatchEvent(new CustomEvent('change', {detail: {fsk: level, old: oldLevel}}));
    }
  }

  get() {
    return this.level;
  }
}

const startUpFskNumber = Number.parseInt(startUpFsk);
ASSERT: guard(startUpFskNumber, FSK_LEVEL, 'startup fsk level');

export const fsk = new Fsk(startUpFskNumber);
