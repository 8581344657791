import {ARRAY, ENUM, OPTIONAL, RECORD, STRING} from '@acng/frontend-rubicon';
import {FSK_KEY} from './fsk.js';

/**
 * The list of valid ACNG languages
 */
export const POSSIBLE_LANGUAGES = /** @type {const} */ (['de', 'en']);

/**
 * Validate a language code.
 */
export const LANGUAGE = /* @__PURE__ */ ENUM(POSSIBLE_LANGUAGES);

/**
 * Validate a list of language codes.
 */
export const LANGUAGES = /* @__PURE__ */ ARRAY(LANGUAGE);

/**
 * Validate a conforming object with translations.
 */
export const TRANSLATIONS = /* @__PURE__ */ (() =>
  RECORD(FSK_KEY, OPTIONAL(RECORD(LANGUAGE, OPTIONAL(STRING)))))();
