import angular from 'angular';
import {guard} from '@acng/frontend-rubicon';
import {deleteUnusedFields} from 'acng/core/model/gallery.js';
import {Movie} from '../model/movie.js';
import {MOVIE_DATA} from '../service/guard.js';

angular.module('moviePool')

  .factory('Movie', ['http', 'HttpCache', 'user', function (http, HttpCache, user) {

    const cache = new HttpCache({
      prefix: '/api/movie/detail/',
      useCdn: true,
      fnError: (err, id) => {
        if (err.status == 404 || err.status == -1) {
          return http().get('/api/movie/blocked/' + id, { dontIntercept: true });
        }
        throw err;
      },
      fnRequest: (id) => http(true).get(`/api/movie/detail/${id}`, {dontIntercept: true}),
      fnResolve: res => {
        delete res.data.stream_id;
        deleteUnusedFields(res.data);
        res.data.category_codes ??= [];
        ASSERT: guard(res.data, MOVIE_DATA, 'movie data');
        return cache.set('s' + res.data.set_id, res.expire, new Movie(res.data));
      }
    });

    Movie.doNotShow = [];

    Movie.random = function (limit, nickname) {
      return http().get('/api/movie/random?limit=' + (limit || 5) + (nickname ? '&nickname=' + nickname : ''), { dontIntercept: true });
    };

    Movie.countByNickname = function (nickname) {
    };

    Movie.list = function (limit, offset, filter, type) {
      return http().get('/api/movie/list' + (type ? `/${type}` : '' ) + '?limit=' + limit + '&offset=' + offset + '&fsk=' + user.getListFsk() + '&filter=' + JSON.stringify(filter), { dontIntercept: true })
        .then(res => {
          if (res.headers('x-bl')) {
            res.data.bl = true;
          }
          return {
            total: parseInt(res.headers('x-numfound')) || 0,
            data: res.data
          };
        });
    };

    Movie.get = function (id) {
      return cache.get(parseInt(id));
    };

    Movie.getBySetId = function (setId) {
      return cache.get(
        's' + setId,
        () => http(true).get(`/api/movie/idBySetId/${setId}`, { dontIntercept: true }),
        res => Movie.get(res.data.id),
        err => {throw err;}
      );
    };

    Movie.categories = function () {
      return cache.get(
        'c',
        () => http().get('/api/movie/categories', { dontIntercept: true }),
        res => res.data,
        err => {throw err;}
      );
    };

    Movie.isValidId = id => !isNaN(id = parseInt(id)) && id;

    return Movie;
  }])

  .directive('onsdMovies', ['PimpMyRide', 'Movie',(PimpMyRide, Movie) => PimpMyRide(
    'Movies',
    ['limit', 'nickname', 'search', 'categories', 'type'],
    v => {
      return Movie.list(v[0], 0, { nickname: v[1], search: v[2] || '', categories: (v[3] || '').split(',').filter(v => !!v) }, v[4])
        .then(res => {
          Movie.doNotShow.forEach(id => {
            let i = res.data.indexOf(id);
            if (i >= 0) {
              res.data.splice(i, 1);
            }
          });
          return res.data;
        })
    },
    v => v[0] > 0
  )])

  .directive('onsdMoreMovies', ['PimpMyRide', 'Movie', (PimpMyRide, Movie) => PimpMyRide(
    'MoreMovies',
    ['movieId', 'type', 'limit'],
    v => Movie.get(v[0]).then(movie => movie.more(v[1], Math.max(0, parseInt(v[2])) || 5)),
    v => Movie.isValidId(v[0]) && /^(nickname|recommendation)$/.test(v[1])
  )]);
