import {HTMLElement, con, def, dis} from '@acng/frontend-bounty/dom/custom.js';
import {Watch} from '@acng/frontend-relativity';
import {IS, guard} from '@acng/frontend-rubicon';
import {Engine, defineRegistryElement} from '@acng/frontend-stargazer';

import {ctxGallery} from '../../core/context/gallery';
import {PictureSet} from '../model/picture-set.js';

export const PICTURE_SET_DETAIL = 'picture-set-detail';

defineRegistryElement(PICTURE_SET_DETAIL, (name) => {
  const watchGallery = Watch(name, ctxGallery);
  const transclude = Engine.Transclude(name);

  class Detail extends HTMLElement {
    #linker = Engine.root;

    [con]() {
      const linker = transclude(this);

      watchGallery(this, (element, pictureSet) => {
        ASSERT: guard(pictureSet, IS(PictureSet));

        linker.toElement(element, {
          description: pictureSet.getDescription(),
          length: `${pictureSet.items.length}`,
        });
      });

      this.#linker = linker;
    }

    [dis]() {
      this.#linker.disconnect();
    }
  }

  def(name, Detail);
});
