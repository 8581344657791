/**
 * May be NOT wumper if value == previous
 */

import {Engine} from '@acng/frontend-stargazer';
import {allAnimationsFinished, setText} from '@acng/frontend-bounty';
import {IS, OPTIONAL, guard} from '@acng/frontend-rubicon';
import {isUndefined} from '@acng/frontend-bounty/std/value.js';
import {append, remove} from '@acng/frontend-bounty/dom/move.js';
import {find, TEMPLATE, create, DIV} from '@acng/frontend-bounty/dom/element.js';

import {STYLE_EMPTY, swapClass, wumper} from './style.js';

/**
 * @param {Element} element
 * @param {number} value
 * @param {number} [previous]
 */
export const animate = (element, value, previous) => {
  const valueNode = find(element, '.value') ?? element;
  const template = find(element, TEMPLATE);
  const settle = () => {
    setText(valueNode, `${value}`);
    swapClass(element, STYLE_EMPTY, !value);
  };

  if (isUndefined(previous) || isNaN(previous) || value < previous) {
    settle();
    return;
  }

  if (!template || value === previous) {
    settle();
    if (value) {
      wumper(element);
    }
    return;
  }

  new Engine(template).toElement(create(DIV), {}, async (nodes, target) => {
    const {diff} = nodes;
    ASSERT: guard(diff, OPTIONAL(IS(Text)));

    if (diff) {
      setText(diff, `${value - previous}`);
    }
    append(element, target);

    await allAnimationsFinished(target);
    remove(target);

    // TODO Not 100% safe
    if (previous == Number(valueNode.textContent)) {
      settle();
      wumper(element);
    }
  });
};
