import {assign} from '@acng/frontend-bounty/object.js';
import {OWNED, guard} from '@acng/frontend-rubicon';
import {post} from 'acng/core/service/backend';

/**
 * @implements {ChallengeData}
 */
export class Challenge {
  /**
   * @param {ChallengeData & OptionalKey} data
   */
  constructor(data) {
    ASSERT: {
      this.id = data.id;
      this.name = data.name;
      this.value = data.value;
      this.key = data.key;
      guard(this, OWNED(data));
    }
    assign(this, data);
  }

  /**
   * @param {Element} element
   * @returns {Promise<boolean>}
   */
  async achieve(element) {
    try {
      await post('achievements/user-achievement', this, element);

      return true;
    } catch (reason) {
      return false;
    }
  }
}

/**
 * @typedef {Valid<typeof import("./challenge-data").CHALLENGE_DATA>} ChallengeData
 */

/**
 * @typedef {{key?: string | null}} OptionalKey
 */
