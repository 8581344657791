import {create, path, alias, expr, exact, middleware, incomplete, validate, on} from '@acng/frontend-voyager';
import {replaceUrl} from '@acng/frontend-relativity/history';

import {rootRoute} from 'acng/core/config/routes';
import {mainView, stickyView} from 'acng/layout/service/sequence.js';
import {onlyUserOrPreview} from 'acng/userPool/config/middleware';
import {amateurRoute} from 'acng/amateurPool/config/routes';
import {inject} from 'acng/core/service/ng';
import {picturePoolFeature} from './feature';
import {LocalActor} from '@acng/frontend-relativity';
import {ctxGallery} from 'acng/core/context/gallery.js';
import {documentElement} from '@acng/frontend-bounty/dom/document.js';
import {noop} from '@acng/frontend-bounty/std/value.js';

export const pictureSetsRoute = rootRoute.create(
  onlyUserOrPreview,
  // TODO voyager multi parent
  expr(/^(?:\/sedcard\/.+)?\/pictures\/sets/y), //
  mainView(picturePoolFeature, 'pictureSets'),
  // TODO alias is required by onswCategorySelect in sidebar
  alias('pictureSets')
);

rootRoute.create(
  path('/pictures/sets/{type}'),
  validate(({type}) => /^(top|new|classics)$/.test(/** @type {string} */ (type))),
  stickyView(picturePoolFeature, 'list-header'),
);

const providePictureSet = LocalActor(ctxGallery);

/**
 * @type {(element: Element) => void}
 */
let unlock = noop;

amateurRoute.create(
  path('/pictures/set/{setId}'),
  mainView(picturePoolFeature, 'pictureSet'),
  on('leave', () => {
    unlock?.(documentElement);
    unlock = noop;
  }),
  middleware((next, prev) => {
    if (next.setId == prev.setId) {
      next.set = prev.set;
      return true;
    }
    const PictureSet = inject('PictureSet');
    return PictureSet.get(/** @type {string} */ (next.setId))
      .then((pictureSet) => {
        next.set = pictureSet;
        unlock = providePictureSet(documentElement, pictureSet);
      })
      .catch((reason) => {
        replaceUrl('/#/');
        throw reason;
      });
  }),
  create(
    path('/item'),
    incomplete,
    alias('pictureSlideshow'),
    create(exact('/{item}'), alias('pictureSlideshowImage'))
  )
);
