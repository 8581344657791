import {now} from '@acng/frontend-bounty/timing/now.js';
import {INTEGER, MINMAX} from '@acng/frontend-rubicon';

/**
 * Validate a unix timestamp in milliseconds.
 *
 * The valid range is from 12/31/2014, 5:26:40 AM
 * until roughly a year into the future.
 *
 * @see {@link TIMESTAMP | Validate a timestamp in seconds.}
 */
export const TIMESTAMP_MS = /* @__PURE__ */ MINMAX(142e10, now() + 4e10, INTEGER);

/**
 * Validate a unix timestamp in seconds.
 *
 * The valid range is from 12/31/2014, 5:26:40 AM
 * until roughly a year into the future.
 *
 * @see {@link TIMESTAMP_MS | Validate a timestamp in milliseconds.}
 */
export const TIMESTAMP = /* @__PURE__ */ MINMAX(142e7, now() / 1000 + 4e7, INTEGER);
