/**
 * Display "popups" to the user when
 * - the E-Mail address is not confirmed.
 * - the users nickname is subject to change.
 * - ...many more
 *
 * These popups are deleted explicitly,
 * but they are published only once per backend-session to the frontend.
 *
 * @module
 */

import {Error} from '@acng/frontend-bounty/std/error.js';
import {isInstanceOf} from '@acng/frontend-bounty/std/value.js';
import {getHeader} from '@acng/frontend-bounty/fetch';
import {parseJSON} from '@acng/frontend-bounty/parse';
import {popup} from '@acng/frontend-discovery';
import {ARRAY, INTEGER, OBJECT, STRING, guard} from '@acng/frontend-rubicon';
import {inject} from '../ng.js';

const MODULE = 'core/service/backend/x-userpopups';
const VERBOSE = false;

const HEADER_NAME = 'x-userpopups';

/**
 * @param {Response} response
 */
export const showXUserPopups = (response) => {
  const userpopups = getHeader(response, HEADER_NAME);

  if (userpopups) {
    try {
      const items = parseJSON(userpopups);

      ASSERT: guard(items, X_USERPOPUPS);
      DEBUG: VERBOSE && console.info(MODULE, items);

      inject('$rootScope').$broadcast('userPool.popups', items);
    } catch (reason) {
      DEBUG: console.error(MODULE, reason);

      popup().error(isInstanceOf(reason, Error) ? reason.message : `${reason}`);
    }
  }
};

const X_USERPOPUPS = /* @__PURE__ */ ARRAY(
  OBJECT({
    id: INTEGER,
    user_id: INTEGER,
    created_at: STRING,
    template: STRING,
  })
);
