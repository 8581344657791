import {createMap} from '@acng/frontend-bounty/collection.js';

/**
 * @typedef {"normal" | "free" | "exclusive" | "voyeur"} RoomType
 */

/**
 * @typedef {Record<RoomType, number>} LivecamShowPrices
 */

/**
 * @typedef LivecamData
 * @property {string} id
 * @property {boolean} is_online
 * @property {number} fsk
 * @property {LivecamShowPrices} prices
 * @property {Array<keyof LivecamShowPrices>} show_types
 * @property {boolean} toycontrol
 * @property {import('acng/userPool/0fsk.js').FskTrans} motto
 */

/**
 * @type {Map<Amateur["id"], Livecam>}
 */
export const cams = createMap();
