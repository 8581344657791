import {guard} from '@acng/frontend-rubicon';
import {listen} from 'acng/core/context/event-bus.js';
import {EVENTBUS_TRANSACTION} from '../model/transaction.js';

const MODULE = 'payment/service/event-bus';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`);

/**
 * @param {TransactionCallback} callback
 *
 * @returns {() => void}
 * Call the returned function to revoke the callback.
 */
export const onTransaction = (callback) => {
  const off = listen(TRANSACTION, (data, reply, error) => {
    ASSERT: guard(data, EVENTBUS_TRANSACTION);
    DEBUG: if (VERBOSE) console.info(MODULE, data);

    callback(data.transaction, reply, error);
  });

  DEBUG: if (VERBOSE) {
    console.debug(`${MODULE} -<>-`, {type: TRANSACTION, callback});

    return () => {
      console.debug(`${MODULE} >  <`, {callback});

      off();
    };
  }

  return off;
};

export const TRANSACTION = 'user.transaction';

/**
 * @callback TransactionCallback
 * @param {import("../model/transaction").Transaction} transaction
 * @param {typeof import("acng/core/context/event-bus.js").publish} reply
 * @param {import("acng/core/context/event-bus.js").Data["error"]} error
 * @returns {void}
 */
