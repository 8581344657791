import {documentElement} from '@acng/frontend-bounty';
import {createHistoryEntry, pushHistory} from '@acng/frontend-relativity/history';
import {CTX_PROVIDE, CTX_VALUE} from '@acng/frontend-relativity/minify';
import {ngDigest} from 'acng/core/service/ng';
import {ignoredBy} from 'acng/userPool/context/ignored-by';
import {coreFeature} from 'acng/core/config/feature.js';
import {publish} from 'acng/core/context/event-bus';
import {t} from 'acng/locale/config/translate';
import {get} from 'acng/amateurPool/service/amateur.js';

export const ctxGlobalDialog = createHistoryEntry('messenger-dialog', /** @type {string | null} */ (null));

/**
 * Pushes the browser history!
 *
 * @param {string | null} amateurId
 *
 * @param {boolean} [replaceHistory]
 * Do not push the history when set to `true`.
 */
export const setGlobalDialog = (amateurId, replaceHistory) => {
  if (amateurId) {
     get(amateurId)
      .then(async (amateur) => {
        if (amateur && ignoredBy.has(`2-${amateur.id}`)) {
          const [msgIgnored, question] = await Promise.all([
            t('userPool.ignoredBy', {nickname: amateur.getNickname()}),
            t('messenger.confirmClose'),
          ]);
          coreFeature.popup(
            null,
            'confirm',
            /**
             * @param {(value?: boolean) => void} close
             */
            (close) => ({
              question: `${msgIgnored}\n\n${question}`,
              yes: () => {
                setGlobalDialog(null);
                ngDigest();
                publish({type: 'dialog.close', partner: {product_id: 2, id: amateurId}});
                close();
              },
              no: close,
            })
          );
          return;
        }
      });
  }

  if (!replaceHistory) {
    pushHistory();
  }
  ctxGlobalDialog[CTX_PROVIDE](null, amateurId);
};

/**
 * @returns {string | null}
 */
export const getGlobalDialog = () => ctxGlobalDialog[CTX_VALUE](documentElement);
