import {CONST, ENUM, INTEGER, OBJECT, OR, STRING} from '@acng/frontend-rubicon';

const TYPE_LIST = /** @type {const} */ (['msg', 'movie', 'picture']);

export const ERROR_CODE_MAP = {
  9000: TYPE_LIST[0],
  9001: TYPE_LIST[1],
  9002: TYPE_LIST[2],
  9003: TYPE_LIST[0],
};

export const PRICE_CHANGE = /* @__PURE__ */ (() =>
  OBJECT({
    type: ENUM(TYPE_LIST),
    article_id: OR(INTEGER, STRING),
    newPrice: INTEGER,
  }))();

export const EVENTBUS_PRICE_CHANGE = /* @__PURE__ */ (() =>
  OBJECT({
    type: CONST('price'),
    article: PRICE_CHANGE,
  }))();
