// @ts-nocheck
import {hasFeature, media} from 'acng/core/service/env';
import {getLocale} from 'acng/locale/model/locale.js';
import {getFallbackImageUrl} from 'acng/layout/service/fallback-image.js';
import {getByNickname} from 'acng/amateurPool/service/amateur.js';

angular.module('ons-actionslider')
  .decorator('onsActionsliderDirective', ['$delegate', $delegate => {
    const oldTemplate = angular.element($delegate[0].template);
    oldTemplate[1].setAttribute('ons-icon', '::back');
    oldTemplate[3].setAttribute('ons-icon', '::forward');
    $delegate[0].template = oldTemplate;
    return $delegate;
  }])
  .decorator('onsActionDirective', ['$delegate', $delegate => {
    $delegate[0].template = [
      '<div class="onsas-outer">',
      '<div class="onsas-image" ng-style="action.styles"></div>',
      '<div class="onsas-inner">',
      '<span class="onsas-title">', '{{ action.title }}', '</span>',
      '<span class="onsas-message">', '{{ action.text }}', '</span>',
      '</div>',
      '</div>']
      .join('');
    return $delegate;
  }]);
angular.module('actionslider')
  .factory('Actionslider', ['$rootScope', '$location', '$compile', '$translate', '$q', 'ActionsliderProfile', 'RATATOSKR', 'sid', '$injector', function ($rootScope, $location, $compile, $translate, $q, ActionsliderProfile, RATATOSKR, sid, $injector) {

    const Movie = hasFeature('moviePool') ? $injector.get('Movie') : null;
    const PictureSet = hasFeature('picturePool') ? $injector.get('PictureSet') : null;
    const Livecams = hasFeature('livecam');

    var as = null;
    var aside = null;
    var scope = $rootScope.$new(false);

    function create(user) {
      as = angular.element('<ons-actionslider>');

      as.attr('user-id', user.id)
        .attr('product-id', user.uid.split('-')[0])
        .attr('channels', [user.partner_pool])
        .attr('action-service', RATATOSKR)
        .attr('max-actions', 300)
        .attr('lifetime', 7200)
        .attr('on-click', 'onClick(action)')
        .attr('on-action', 'onAction(action)')
        .attr('action-session', sid)
        .attr('language', getLocale())
        .attr('on-scroll', 'onScroll(offset, children)');

      $compile(as)(scope);

    }

    function display(user) {

      scope.onAction = onAction;
      scope.onScroll = onScroll;
      scope.onClick = onClick;

      if (!as) {
        create(user);
      }
      aside = angular.element('<aside>').append(as);
      angular.element('body').append(aside);
    }

    function onClick(action) {
      switch (action.id) {
        case 'movies':
          return $location.url('sedcard/' + action.profile.nickname + '/movie/' + action.movie.id);
        case 'pictures':
          return $location.url('sedcard/' + action.profile.nickname + '/pictures/set/' + action.pictureSet.id);
        case 'buddyrequest':
          return $location.url('profile/friends/amateur_request');
        case 'buddyconfirm':
          return $location.url('sedcard/' + action.profile.nickname);
        default:
          $location.url('sedcard/' + action.profile.nickname);
      }

    }

    function onAction(action) {
      return $q.all([$translate('actionslider.' + action.id), getByNickname(action.nickname)])
        .then(function (t) {
          action.title = t[1].getNickname();
          action.text = t[0];
          switch (action.id) {
            case 'movies':
              if (Movie) {
                return Movie.getBySetId(action.payload.setId).then(function (movie) {
                  action.movie = movie;
                  return action;
                });
              }
              break;
            case 'pictures':
              if (PictureSet) {
                return PictureSet.get(action.payload.setId).then(function (set) {
                  action.pictureSet = set;
                  return action;
                });
              }
              break;
            case 'livecam':
              if (Livecams) {
                return action;
              }
              break;
            case 'buddyrequest':
            case 'buddyconfirm':
            case 'online':
              return action;
            case 'weblog':
              throw 'event weblog not supported';
          }
          throw 'no valid action given';
        }).then(function (action) {
          return ActionsliderProfile.find(action.userId);
        }).then(function (profile) {

          action.profile = profile;

          var img = new Image();
          img.onerror = function () {
            action.styles.backgroundImage = `url(${getFallbackImageUrl('160')})`;
          };
          img.onload = function () {
            action.styles.backgroundImage = 'url(' + profile.image + ')';
          };
          action.styles = action.styles || {};
          img.src = profile.image;

          return action;
        });

    }

    function onScroll(offset, children) {

      var width = 0;

      if (offset !== 0) {
        width = children.slice(-offset).reduce(function (acc, val) {
          return acc + val.width;
        }, 0);
      }

      width += offset * 1; // because of margins

      return {
        transform: 'translate(' + width + 'px, 0)'
      };
    }

    function hide() {
      if (aside) {
        aside.hide();
      }
    }

    function show() {
      if (aside) {
        aside.show();
      }
    }

    return {
      display: display,
      hide: hide,
      show: show
    };

  }]);
