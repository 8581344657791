import {RegardCategory} from '../model/category';
import {Regard} from '../model/regard';
import {Overlay} from 'acng/core/service/overlay';
import {WidgetConstructor} from 'acng/core/factory/widget';
import templateUrl from 'assets/html/regard/regards.html';
import {asset} from 'acng/core/service/env';

export default 'onswRegards';
export const regardsComponent: angular.IComponentOptions = {
  bindings: {
    category: '<',
    regardSelect: '&',
    childClass: '@',
  },
  templateUrl: asset(templateUrl),
  controller: ['$scope', '$element', 'Widget', controller],
};

type Scope = angular.IScope & {
  regards?: Regard[];
  click(regard: Regard): void;
};

type Controller = angular.IController & {
  childClass: string;
  category?: RegardCategory;
  selected?: Regard;
  regardSelect(params: {
    item: Regard;
    sendTo(
      amateur: Amateur, //
      overlay: Overlay,
      livecamTip: boolean
    ): void;
  }): void;
};

function controller(this: Controller, scope: Scope, element: JQLite, Widget: WidgetConstructor) {
  const widget = new Widget(scope, element, 'ons-layout');

  this.$onChanges = () => {
    scope.regards = [];
    const c = this.category;

    if (!(c instanceof RegardCategory)) {
      RegardCategory.list()
        .then(list => {
          return list.find(v => v.id == c)?.getItems();
        })
        .then(items => scope.$apply(() => scope.regards = items));
      return;
    }

    c && c.getItems().then(items => scope.$apply(() => scope.regards = items));
  };

  scope.click = regard => {
    this.selected = regard;
    this.regardSelect({
      item: regard,
      sendTo: (amateur, overlay, livecamTip) => {
        widget.ifNotBusy(() => {
          regard.livecamTip = livecamTip ? true : false;
          return regard.sendTo(amateur, overlay).catch(err => widget.notify(err)); //eslint-disable-line max-len
        });
      },
    });
  };
}
