/**
 * Display "notifications" to the user when
 * - the "coins"-value has been increased by the management.
 * - the profile image has been accepted/declined/deleted by the management.
 * - ...may be more
 *
 * @todo Discuss
 * Critical: If a x-notification-header is missed, the messages are lost.
 * Once shown or missed, the messages are gone.
 *
 * @module
 */

// discovery, bounty, rubicon
import {Error} from '@acng/frontend-bounty/std/error.js';
import {isInstanceOf} from '@acng/frontend-bounty/std/value.js';
import {getHeader} from '@acng/frontend-bounty/fetch';
import {parseJSON} from '@acng/frontend-bounty/parse';
import {popup} from '@acng/frontend-discovery';
import {ARRAY, ENUM, INTEGER, OBJECT, STRING, guard} from '@acng/frontend-rubicon';

const MODULE = 'core/service/backend/x-notifications';
const VERBOSE = false;

const mapTypeToPopupMethod = /** @type {const} */ ({
  danger: 'error',
  warning: 'warn',
  info: 'info',
  success: 'info',
});

/**
 * Parse the custom optional "x-notifications" header as json.
 * It should contain an array of messages, if set.
 * Use the global {@link popup} to display each of them.
 *
 * @example
 * ```json
 * [
 *   {"message": "Hallo Welt!", "type": "info"},
 *   {"message": "Super!", "type": "success"}
 * ]
 * ```
 *
 * The headers content is validated if ASSERT-labeled statements are not dropped.
 *
 * @param {Response} response
 */
export const showXNotifications = (response) => {
  try {
    const notifications = getHeader(response, 'x-notifications');
    if (notifications) {
      const messages = parseJSON(notifications);

      ASSERT: guard(messages, X_NOTIFICATIONS);
      DEBUG: VERBOSE && console.info(MODULE, {messages});

      for (const message of messages) {
        popup()[mapTypeToPopupMethod[message.type]](message.message);
      }
    }
  } catch (reason) {
    DEBUG: console.error(MODULE, reason);

    popup().error(isInstanceOf(reason, Error) ? reason.message : `${reason}`);
  }
};

const X_NOTIFICATIONS = /* @__PURE__ */ ARRAY(
  OBJECT({
    id: INTEGER,
    type: ENUM(/** @type {const} */ (['danger', 'warning', 'info', 'success'])),
    message: STRING,
  })
);
