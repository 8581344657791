import angular from 'angular';

import angularTranslate, {provideTranslations} from 'acng/core/service/angular-translate';

import {runSedcardNav} from './config/routes';
import {runLaforge} from './config/laforge';
import onswAmateurContext, {contextComponent} from './widgets/context';
import './widgets/otm-shuffle';
import './widgets/list-small';
import './widgets/sedcard-image.js';
import featureName from './config/feature.js';
import {AmateurTile} from './tiles/AmateurTile.js';
import './element/index.js';
import './attribute/index.js';
import {get, getByNickname} from './service/amateur.js';
import {list, listOfAmateur} from './service/feed.js';

export default featureName;

angular
  .module(featureName, [angularTranslate])
  .config(provideTranslations(featureName))
  .config(provideTranslations('models'))
  .factory('AmateurTile', () => AmateurTile)

  .directive('onsdAmateurFeed', [
    'PimpMyRide',
    (PimpMyRide) =>
      PimpMyRide(
        'AmateurFeed',
        ['amateurId', 'amateurName'],
        /**
         * @param {[string, string]} v
         */
        async ([id, nickname]) => {
          const amateur = await (id ? get(id) : getByNickname(nickname));
          const feed = await listOfAmateur(document.body, amateur.id);

          if (!feed.length) {
            throw feed;
          }

          return feed;
        },
        /**
         * @param {[string, string]} v
         */
        ([id, nickname]) => id || nickname
      ),
  ])

  .directive('onsdGlobalFeed', [
    'PimpMyRide',
    (PimpMyRide) =>
      PimpMyRide(
        'GlobalFeed',
        ['filter', 'latest'],
        /**
         * @param {["all" | "posts" | "media" | "favorites", boolean]} args
         */
        ([filter, latest]) => list(document.body, filter, latest),
        /**
         * @param {["all" | "posts" | "media" | "favorites", boolean]} args
         */
        ([filter]) => ['all', 'posts', 'media', 'friends', 'favorites'].indexOf(filter) >= 0
      ),
  ])
  .component(onswAmateurContext, contextComponent)
  .run(runSedcardNav)
  .run(runLaforge);
