import {NODES, Widget} from '@acng/frontend-stargazer';
import {on, EVENT_INPUT, EVENT_SUBMIT} from '@acng/frontend-bounty/event';
import {guard} from '@acng/frontend-rubicon';

import {inject} from 'acng/core/service/ng';

import {userPoolFeature} from '../config/feature.js';
import {confirmDevice} from '../service/http.js';
import {SecurityCodeElement} from 'acng/core/widgets/securityCode.js';

userPoolFeature.defineWidget(
  'onsw-confirm-device',
  class extends Widget {
    render() {
      ASSERT: guard(this.nodes, TEMPLATE);

      const {form, code, button} = this.nodes;

      on(code, EVENT_INPUT, () => (button.disabled = code.value.length != 6));
      on(form, EVENT_SUBMIT, async (e) => {
        e.preventDefault();
        try {
          button.disabled = true;

          const user = await confirmDevice(code.value, this);

          inject('$rootScope').$broadcast('userPool.login.success', user);
          inject('onsOverlay').get('confirm-device')?.close();
        } finally {
          button.disabled = false;
        }
      });
    }
  }
);

const TEMPLATE = /* @__PURE__ */ NODES({
  form: HTMLFormElement,
  code: SecurityCodeElement,
  button: HTMLButtonElement,
});
