import {connectPopupController, popup} from '@acng/frontend-discovery';
import {GlobalActor, Watch} from '@acng/frontend-relativity';
import {CTX_UNOBSERVE} from '@acng/frontend-relativity/minify';
import {isInstanceOf} from '@acng/frontend-bounty/std/value.js';
import {add} from '@acng/frontend-bounty/style/element.js';
import {IS, guard} from '@acng/frontend-rubicon';

import {inject} from '../../core/service/ng.js';
import {ctxStockUpdate} from 'acng/userPool/context/stock-update.js';
import {authUser} from '../../userPool/context/auth-user.js';
import {FreeMovie} from '../../moviePool/factory/FreeMovie.js';
import {Movie} from '../../moviePool/model/movie.js';
import {PictureSet} from '../../picturePool/model/picture-set.js';
import {ctxGallery} from '../../core/context/gallery.js';
import {ctxCreatedComment} from '../context/created.js';
import {createComment} from '../service/http.js';

/**
 * @group DOM Element
 */
export const COMMENT_CREATE = 'onsw-comment-create';

/**
 * @module acng/comment/widget/create
 */
const name = 'onswCommentCreate';
export default name;

const watchGallery = Watch(COMMENT_CREATE, ctxGallery);
const watchStock = Watch(COMMENT_CREATE, ctxStockUpdate);

/** @type {angular.IComponentOptions} */
export const createComponent = {
  bindings: {
    onclose: '&',
  },
  templateUrl: '/template/comment.create',
  controller: ['$scope', '$element', '$rootScope', '$translate', controller],
};

/**
 * @this {angular.IController & {
 *   text?: string;
 *   notCommentable?: string | false;
 *   create?: Function;
 *   onclose: Function;
 * }}
 * @param {angular.IScope} $scope
 * @param {JQLite} $element
 * @param {angular.translate.ITranslateService} $translate
 */
function controller($scope, $element, $translate) {
  const element = $element[0];

  add(element, 'ons-form');
  connectPopupController(element);

  this.text = '';
  this.notCommentable = '';

  this.$onInit = () =>
    watchGallery(element, async (_, nextCommentable) => {
      if (!nextCommentable) {
        this.create = undefined;
        this.notCommentable = 'comment.noCommentable';
        ctxStockUpdate[CTX_UNOBSERVE](element);
        return;
      }
      const gallery = nextCommentable;

      const create = async () => {
        this.create = undefined;
        try {
          if (!this.text) {
            throw 'comment.noTextGiven';
          }
          if (this.text.length < 15) {
            throw 'comment.shortText';
          }
          const comment = await createComment(element, gallery, this.text);

          broadcast({gallery, comment});
          this.text = '';
          this.onclose();
        } catch (/** @type {any} */ reason) {
          // TODO error types
          if (reason?.data?.message) {
            reason = reason.data.message;
          }
          if (typeof reason == 'string') {
            popup(element).warn(await $translate(reason).catch((err) => err));
          } else {
            console.error(`${name} create`, {reason});
          }
        } finally {
          this.create = create;
          $scope.$digest();
        }
      };

      watchStock(element, async () => {
        this.notCommentable = await blockWithReason(gallery);
        this.create = this.notCommentable ? undefined : create;
        $scope.$digest();
      });
    });
}

const broadcast = GlobalActor(ctxCreatedComment);

/**
 * @param {Gallery} gallery
 * @returns {Promise<string | false>}
 */
const blockWithReason = async (gallery) => {
  if (gallery.blocked) {
    return 'comment.notCommentable';
  }

  // @ts-expect-error TODO gefactor stock
  if (await gallery.getStock()) {
    return false;
  }

  if (isInstanceOf(gallery, Movie)) {
    const free = await FreeMovie.isFree(gallery.id);

    if (free) {
      if (!authUser || authUser.level < 10) {
        inject('payment').overlay('comment.freeUser');

        return 'comment.freeUser';
      }

      return false;
    }

    return 'comment.buyMovie';
  }

  ASSERT: guard(gallery, IS(PictureSet));

  return 'comment.buyPictureSet';
};
