  const config: Record<string, number | undefined> = {
    AT: 4,
    CH: 4,
  };

  export const zipFilter = (zip: number | string, country: string) => {
    if (zip === -1) return '';

    if (typeof zip === 'number') {
      zip = zip.toString().padStart(2, '0');
    }

    const length = config[country] || 5;

    while (zip.length < length) {
      zip += 'x';
    }

    return zip;
  };
