// @ts-nocheck
import angular from 'angular';
import {get} from 'acng/amateurPool/service/amateur.js';

angular.module('actionslider')
  .factory('ActionsliderProfile', [function () {

    function Profile(data) {
      this.nickname = data.nickname;
      this.id = data.id;
      this.image = data.getImageUrl('small');
    }

    Profile.find = function (id) {
      return get(id).then(function (res) {
        return new Profile(res);
      });
    };

    return Profile;

  }]);
