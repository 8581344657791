import {Store, add, createMap, createSet, remove, set} from '@acng/frontend-bounty/collection.js';
import {resolved} from '@acng/frontend-bounty/std/control.js';
import {STRING, guard} from '@acng/frontend-rubicon';

import {AMATEUR_DATA} from '../model/amateur-data.js';
import {Amateur} from '../model/amateur.js';
import {cdn} from 'acng/core/service/backend.js';
import {fetch} from '@acng/frontend-bounty/fetch.js';
import {API_cdn} from 'acng/core/service/env.js';
import {handleCfChallenge} from 'acng/core/service/backend/cf-challenge.js';

/**
 * @type {Set<Amateur["id"]>}
 */
export const doNotShow = createSet();

/**
 * @type {Map<Amateur["id"], Promise<Amateur>>}
 */
const idIndex = createMap();

/**
 * @type {Map<Amateur["nickname"], Promise<Amateur>>}
 */
const nicknameIndex = createMap();

export const get = Store(idIndex, async (id) => {
  try {
    const data = await fetchViaCDN(`view?id=${id}`);

    ASSERT: {
      delete data.new_images;
      delete data.preferred_image;
      delete data.livedating;
      delete data.weblog;
      delete data.games;
      delete data.livecam;
      guard(data, AMATEUR_DATA, 'amateur data');
    }

    const amateur = new Amateur(data);

    if (amateur.doNotShow()) {
      add(doNotShow, id);

      throw doNotShow;
    }

    set(nicknameIndex, amateur.nickname, resolved(amateur));

    return amateur;
  } catch (reason) {
    remove(idIndex, id);

    throw reason;
  }
});

export const getByNickname = Store(nicknameIndex, async (nickname) => {
  try {
    const data = await fetchViaCDN(`id-by-nickname/${nickname}`);
    ASSERT: guard(data, STRING, 'id by nickname');

    const amateur = await get(data);

    return amateur;
  } catch (reason) {
    remove(nicknameIndex, nickname);

    throw reason;
  }
});

/**
 * @param {string} path
 * @returns {Promise<any>}
 */
const fetchViaCDN = async (path) => {
  const res = await fetch(`${API_cdn}/api/amateur-profile/${path}`);

  handleCfChallenge(res);

  if (!res.ok) {
    throw res;
  }

  return res.json();
};
