import angular from 'angular';
import {ARRAY, guard} from '@acng/frontend-rubicon';

import {getLocale} from 'acng/locale/model/locale.js';
import {useToken, discardToken} from 'acng/userPool/service/token.js';
import {onTransaction} from '../service/event-bus.js';
import {putBonusData} from 'acng/payment/context/bonus-list.js';
import {BONUS_DATA} from '../model/bonus.js';

angular.module('payment')
  .factory('payment', ['$sce', 'paymentApi', 'paymentConfig', '$sessionStorage', 'onsOverlay', '$rootScope', '$q', 'http', 'ARCHIMEDES', 'startUpCountry', 'storage', function ($sce, paymentApi, paymentConfig, $sessionStorage, onsOverlay, $rootScope, $q, http, ARCHIMEDES, startUpCountry, storage) {

    var session = $sessionStorage;
    if (typeof session.transactionDetails == 'undefined') {
      session.transactionDetails = {};
    }

    const METHOD_ID__MOBILE_PAYMENT = 8;
    const METHOD_ID__SOFORT = 3;

    function Payment() {
      this.packageOrderByClause = (paymentConfig.packages.sorting.order === 'ASC' ? '+' : '-') + paymentConfig.packages.sorting.key;

      this.customer = null;
      this.method = null;
      this.country = null;
      this.package = null;
      this.transaction = null;
      this.finished = false;
      this.defaultCurrency = null;
      this.countryLocked = false;
      this.preselectedCountryCode = null;
    }

    Payment.prototype.init = function () {
      return paymentApi.init().then(function (res) {
        this.customer = res.data;
        Payment.customerId = res.data.customer_id;
        return this;
      }.bind(this));
    };

    Payment.prototype.getIndex = function (freeCoinsPackage) {
      return paymentApi.index(this.customer)
        .then(function (res) {
          var ps = res.data.packages;
          var ms = res.data.methods;

          this.preselectedCountryCode = res.data.country.preselected || startUpCountry;
          this.countryLocked = res.data.country.locked;
          this.defaultCurrency = res.data.default_currency;

          ASSERT: guard(res.data.active_bonuses, ARRAY(BONUS_DATA));
          const activeBonuses = res.data.active_bonuses;

          const filterPackages = [];
          const welcomePackagePresent = ps.find(item => item.name === 'coins.welcome') !== undefined;
          if (freeCoinsPackage) {
            filterPackages.push('coins.1000', 'coins.1000b', 'coins.carefree');
            if (welcomePackagePresent) {
              filterPackages.push('coins.50', 'coins.starter');
            }
          } else if (welcomePackagePresent) {
            filterPackages.push('coins.1000', 'coins.1000b', 'coins.carefree');
          }

          if (filterPackages.length) {
            ps = ps.filter(item => !filterPackages.includes(item.name));
          }

          let mobilePayment = null;
          let mobilePaymentMethod = null;

          const awayFromSofortBonus = activeBonuses.find(
            (bonus) => bonus.type === 'percent' && bonus.name === 'awayfromsofort'
          );

          for (let i = 0; i < ms.length; i++) {
            const actualMethod = ms[i];

            actualMethod.consent_for_data_storage = false;

            // special method bonus badge for the "away from sofort" bonus
            if (awayFromSofortBonus && actualMethod.id !== METHOD_ID__SOFORT) {
              actualMethod.visible_percent_bonus = awayFromSofortBonus.value;
            }

            // special mobile billing handling
            if (actualMethod.id === METHOD_ID__MOBILE_PAYMENT) {

              mobilePaymentMethod = actualMethod;
              mobilePaymentMethod.iterator = i;

              const preparedPackages = [];
              for (const actualCurrency in mobilePaymentMethod.packages) {
                for (const packageId of mobilePaymentMethod.packages[actualCurrency]) {
                  preparedPackages.push(ps.find(item => item.id === packageId));
                }
              }

              mobilePaymentMethod.packages = preparedPackages;
              continue;
            }

            const preparedPackages = {};
            for (const actualCurrency in actualMethod.packages) {
              preparedPackages[actualCurrency] = {};
              for (const packageId of actualMethod.packages[actualCurrency]) {
                preparedPackages[actualCurrency][packageId] = true;

                const actualPackage = ps.find(item => item.id === packageId);
                if (actualPackage) {
                  (actualPackage.methods || (actualPackage.methods = {}))[actualMethod.id] = true;
                }
              }
            }

            actualMethod.packages = preparedPackages;
          }

          if (mobilePaymentMethod) {
            ms.splice(mobilePaymentMethod.iterator, 1);
            if (mobilePaymentMethod.packages.length > 0) {
              delete mobilePaymentMethod.iterator;
              mobilePayment = new Payment();
              mobilePayment.customer = this.customer;
              mobilePayment.method = mobilePaymentMethod;
            }
          }

          const pByC = {};
          for (const actualPackage of ps) {
            // special mobile billing handling
            if (mobilePayment && mobilePayment.method.packages.find(item => item.id === actualPackage.id)) {
              continue;
            }

            actualPackage._fractionSize = (actualPackage.price % 1) > 0 ? 2 : 0;

            (pByC[actualPackage.currency.iso_code] || (pByC[actualPackage.currency.iso_code] = [])).push(actualPackage);
          }

          if (freeCoinsPackage) {
            for (const currency in pByC) {
              if (!pByC.hasOwnProperty(currency)) {
                continue;
              }

              pByC[currency].unshift(freeCoinsPackage);
            }
          }

          res.data.methods = ms;
          res.data.packages = pByC;
          res.data.mobilePayment = mobilePayment;

          putBonusData(activeBonuses);

          return res.data;
        }.bind(this))
        .catch(angular.noop);
    };

    Payment.prototype.start = async function () {
      try {
        const response = await paymentApi.transaction(this.customer, this.country, this.method, this.package);
        response.data.method_id = this.method.id;
        response.data.package_id = this.package.id;
        response.data.rawPspUrl = response.data.url + '?' + angular.element.param(response.data.parameter);
        response.data.pspUrl = $sce.trustAsResourceUrl(response.data.rawPspUrl);
        this.transaction = response.data;

        this.remember();

        return response.data;
      } catch (err) {
        this.transaction = null;
        throw err;
      }
    };

    Payment.startFollow = function (callData) {

      callData.parameter.browser = {
        java_available: navigator.javaEnabled(),
        language: navigator.language,
        timezone_offset: new Date().getTimezoneOffset(),
        color_depth: screen.colorDepth,
        screen_height: screen.height,
        screen_width: screen.width
      };

      return http().put(callData.url, callData.parameter, { headers: callData.header, dontIntercept: true })
        .then(res => {
          if (res.data && res.data.url) {
            res.data.rawUrl = res.data.url;
            res.data.url = $sce.trustAsResourceUrl(res.data.rawUrl);
          }

          return res;
        });
    };

    Payment.prototype.remember = function () {
      session.transactionDetails[this.transaction.id] = { country: this.country, method: this.method, package: this.package };
      register(this.transaction.id);
    };

    Payment.prototype.isLegalAgeConfirmationRequired = function () {
      return this.method && this.method.legal_age_check && !Payment.legalAgeConfirmationClicked;
    };

    Payment.prototype.setLegalAgeConfirmationClicked = function (value) {
      if (Payment.legalAgeConfirmationClicked === value) {
        return;
      }

      Payment.legalAgeConfirmationClicked = value === true;
      storage.driver('persistent').set('legalAgeConfirmationClicked', Payment.legalAgeConfirmationClicked);
    };

    Payment.prototype.legalAgeConfirmationClicked = function () {
      return Payment.legalAgeConfirmationClicked;
    };

    Payment.config = paymentConfig;
    Payment.create = create;
    Payment.checkPending = checkPending;
    Payment.customerId = null;
    Payment.legalAgeConfirmationClicked = storage.driver('persistent').get('legalAgeConfirmationClicked') === true;

    Payment.overlay = function (reason, params, classes) {
      if (typeof reason == 'object') {
        classes = reason.classes;
        params = reason.params;
        reason = reason.reason;
      }

      onsOverlay.create('payment', {
        reason: reason,
        reasonParams: angular.toJson(params),
        reasonClasses: classes ,
        bonus: params?.bonus ?? null,
      })
        .disableBackdrop()
        .open();
    };

    Payment.closeOverlay = function () {
      const paymentOverlay = onsOverlay.get('payment');
      if (paymentOverlay) {
        paymentOverlay.close();
      }
    };

    Payment.countries = function () {
      return http().get(ARCHIMEDES + '/countries', {
        headers: { 'X-Language': getLocale() },
        cache: true
      }).then(function (res) {
        return res.data;
      });
    };

    Payment.prototype.resetVerifiedMethod = function (method) {
      return Payment.resetMethod(this.customer.customer_id, method.id);
    };

    Payment.loadAndGetCustomerId = function () {
      if (Payment.customerId) {
        $rootScope.customerId = Payment.customerId;
        return $q.resolve(Payment.customerId);
      }

      return paymentApi.customer().then(res => {
        Payment.customerId = res.data.customer_id || null;
        $rootScope.customerId = Payment.customerId;
        return Payment.customerId;
      });
    };

    Payment.resetMethod = function (customerId, methodId) {
      return useToken().then(function (token) {
        return http().delete(ARCHIMEDES + '/customer/' + customerId + '/verified_method/' + methodId,
          {
            headers: {
              'X-AuthToken': token
            },
            dontIntercept: true
          })
          .catch(function (err) {
            if (!err.data || err.data.error != 1001) throw err;
            discardToken(token);
            return Payment.resetMethod(customerId, methodId);
          });
      });
    };

    Payment.isTransactionInSession = function (transactionId) {
      return session.transactionDetails[transactionId] !== void 0;
    };

    Payment.calculateBonusPercentage = function (pkg) {
      return Math.round(20 * pkg.bonus / (pkg.value + pkg.addition)) * 5;
    };

    $rootScope.$on('userPool.login.success', function () {
      Payment.loadAndGetCustomerId();
    });

    return Payment;

    function create() {
      return new Payment().init();
    }

    function checkPending() {
      angular.forEach(session.transactionDetails, function (details, txId) {
        register(txId);
      });
    }

    /**
     * TODO One listener should be enough
     * @param {number} txId
     */
    function register(txId) {
      onTransaction((transaction) => {
        if (transaction.id !== txId) {
          return;
        }

        onsOverlay.create(transaction.code ? 'payment' : 'transactionResultOverlay', {
          callbackTx: transaction,
          sessionTx: session.transactionDetails[transaction.id],
          hideBonusBanner: true
        })
          .disableBackdrop()
          .open();

        delete session.transactionDetails[txId];
      });
    }

  }]);
