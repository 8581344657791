// bounty, stargazer, rubicon, relativity
import {Element, Engine, NODES, task} from '@acng/frontend-stargazer';
import {HTMLElement, def, con, dis} from '@acng/frontend-bounty/dom/custom.js';
import {createDiv, setText} from '@acng/frontend-bounty';
import {all} from '@acng/frontend-bounty/std/control.js';
import {ARRAY, guard} from '@acng/frontend-rubicon';
import {Watch} from '@acng/frontend-relativity';
import {popup} from '@acng/frontend-discovery';
// enterprise
import {repeatElement} from 'acng/layout/service/repeat.js';
import {achievementFeature} from '../config/feature.js';
import {get} from 'acng/core/service/backend.js';
import {ACHIEVEMENT_DATA} from '../model/achievement-data.js';
import {ctxVipPoints} from '../context/points.js';
import {throttle} from '@acng/frontend-bounty/timing/throttle.js';
import {isConnected} from '@acng/frontend-bounty/dom/type.js';

/**
 * @group DOM Element
 */
export const LATEST_ACHIEVEMENTS = 'onsw-latest-achievements';

Element(LATEST_ACHIEVEMENTS, (name) => {
  /**
   * @type {Achievement[]}
   */
  let achievements;

  const transclude = Engine.Transclude(name);
  const watchPoints = Watch(name, ctxVipPoints);
  const asyncUpdate = throttle(
    /**
     * @param {HTMLElement} element
     * @param {Engine} linker
     */
    async (element, linker) => {
      await popup(element).info('Changed');

      if (!isConnected(element)) {
        return;
      }

      await loadItems();

      if (!isConnected(element)) {
        return;
      }

      render(element, linker);
    }
  );

  def(
    name,
    class extends HTMLElement {
      /**
       * @type {Engine | undefined}
       */
      #linker;

      [con]() {
        const linker = transclude(this);

        watchPoints(this, (element, current, previous) => {
          if (previous && current > previous) {
            asyncUpdate(element, linker);
          } else {
            render(element, linker);
          }
        });
        this.#linker = linker;
      }

      [dis]() {
        this.#linker?.disconnect();
      }
    }
  );

  /**
   * @param {HTMLElement} element
   * @param {Engine} linker
   */
  const render = (element, linker) => {
    linker.toElement(element);
    ASSERT: guard(linker.nodes, TEMPLATE);
    const {repeat, time, value, descr} = linker.nodes;

    repeatElement(repeat, achievements, (ua) => {
      time.dateTime = ua.created_at;
      setText(time, ua.date);
      setText(value, `${ua.value}`);
      setText(descr, ua.descr);
    });
  };

  const loadItems = async () => {
    const userAchievements = await get('achievements/latest');
    ASSERT: guard(userAchievements, ARRAY(ACHIEVEMENT_DATA), 'users latest achievements');

    achievements = await all(
      userAchievements.map(async ({created_at, value, achievement}) => ({
        created_at,
        value,
        date: new Date(created_at).toLocaleDateString(document.body.lang),
        descr: await achievementFeature.translate([achievement.name, 'action'], {value: `${value}`}),
      }))
    );
  };

  return [(_element, _p, compiler) => task(compiler, loadItems()), ,];
});

const TEMPLATE = /* @__PURE__ */ NODES({
  time: HTMLTimeElement,
  repeat: HTMLElement,
  value: Text,
  descr: Text,
});

/**
 * Prepared for render
 *
 * @private
 * @typedef {{
 *   descr: string;
 *   value: number;
 *   created_at: string;
 *   date: string;
 * }} Achievement
 */
