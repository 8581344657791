import {ENUM} from '@acng/frontend-rubicon';

/**
 * The list of valid ACNG fsk levels, ordered by priority
 */
export const POSSIBLE_FSK_LEVELS_DESC = /** @type {const} */ ([18, 16, 12]);

/**
 * The list of valid ACNG fsk levels as strings, ordered by priority
 */
export const POSSIBLE_FSK_KEYS = /** @type {const} */ (['18', '16', '12']);

/**
 * Validate a fsk level.
 */
export const FSK_LEVEL = /* @__PURE__ */ ENUM(POSSIBLE_FSK_LEVELS_DESC);

/**
 * Validate a string contained fsk level.
 *
 * > The fsk level is generally a number. The string variant is typically required as object key.
 *
 * @see {@link FSK_LEVEL}
 * @see {@link POSSIBLE_FSK_KEYS}
 */
export const FSK_KEY = /* @__PURE__ */ ENUM(POSSIBLE_FSK_KEYS);
