import {getHeader} from '@acng/frontend-bounty/fetch';
import {popup} from '@acng/frontend-discovery';

let active = false;
let loaded = false;

function loadJS() {
  loaded = true;
  return new Promise(async (resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
    document.body.appendChild(script);
    //@ts-ignore
    window.onloadTurnstileCallback = resolve;
  });
}

function render() {
  const div = document.createElement('div');
  popup().showModal(div);

  // @ts-ignore
  turnstile.render(div, {
    sitekey: '0x4AAAAAAA6-uZLLkFeZZ-6O',
    // @ts-ignore
    callback: async (token) => {
      console.log(`Challenge Success ${token}`);
      try {
        await fetch('https://turnstile.ng-source.com/', {
          method: 'POST',
          mode: 'no-cors',
          body: JSON.stringify({token}),
          headers: {'Content-Type': 'application/json'},
        });
      } finally {
        window.location.reload();
        popup().close(div);
        active = false;
      }
    },
  });
}

export const showTurnstyle = async () => {
  if (active) return;
  active = true;
  if (!loaded) {
    await loadJS();
  }
  render();
};

/**
 * @param {Response} response
 */
export const handleCfChallenge = (response) => {
  const challenge = getHeader(response, 'cf-mitigated') === 'challenge';
  if (challenge) {
    showTurnstyle();
  }
};
