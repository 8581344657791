/**
 * Parse `<script id="env">...</script>` from the current `document`,
 * which is provided by {@link ../../../../views/index.blade.php}
 *
 * The values of the variables are configured via the backend. (.env, etc.)
 *
 * Assign types, validate and export all values.
 *
 * @module
 */

import {parseScriptElementAsJson} from '@acng/frontend-bounty/parse';
import {ENV, guard} from './guard.js';

const ENV_ID = 'env';

/**
 * @deprecated DO NOT USE THIS EXPORT
 */
export const env = parseScriptElementAsJson(ENV_ID);
ASSERT: guard(env, ENV, 'ENV');

/**
 * Contains base paths for assets and content.
 * TODO explain and cleanup
 */
export const media = env.media;

/**
 * @param {string} src
 */
export const asset = (src) => `${media.assets}/${src}`;

export const FEATURES = env.FEATURES;

/**
 * @param {string} name
 * @returns {boolean} -
 */
export const hasFeature = (name) => FEATURES.includes(name);

/**
 * TODO explain
 */
export const INHERIT = env.INHERIT;

export const portal = env.portal;
portal.copyright = `© ${new Date().getFullYear()} ${portal.name}`;

export const hasMyPortal = env.hasMyPortal;

export const startUpCountry = env.startUpCountry;
export const startUpLanguage = env.startUpLanguage;
export const startUpFsk = env.startUpFsk;
export const VERSION = env.VERSION;
export const API_cdn = env.API_cdn;
export const isMobile = env.isMobile;
export const SONUS = env.SONUS;
export const UTM = env.UTM;
export const PLAYPAL_STATIC = env.PLAYPAL_STATIC;
export const ARCHIMEDES = env.ARCHIMEDES;
export const dummyImageMap = env.dummyImageMap;

/**
 * The event bus master **SockJS** URL
 */
export const TROI = env.TROI;

/**
 * @property fsk Blka
 */
export const authUser = env.authUser;
export const BUILD = env.BUILD;
