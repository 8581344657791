// @ts-nocheck do not fix deprecated tile
import {hasFeature} from 'acng/core/service/env';
import {Tile} from 'acng/core/widgets/basic-tile.js';
import {connect as connectResponsiveElement} from 'acng/layout/widget/section.js';

angular.module('amateurPool')

  .factory('FeedTile', ['weblogStore', '$injector', 'AmateurTile', (weblogStore, $injector, AmateurTile) => {

    const MovieTile = hasFeature('moviePool') ? $injector.get('MovieTile') : null;
    const PictureSetTile = hasFeature('picturePool') ? $injector.get('PictureSetTile') : null;

    class FeedTile extends Tile
    {
      load(scope, feed) {
        connectResponsiveElement(this.box);
        scope.feed = feed;
        this.dontSetBg = true;
        this.cssClass = [feed.type.toLowerCase()];
        scope.headline = () => 'amateurPool.feed.' + feed.type.toLowerCase() + (feed.date ? '' : 'WithoutDate');
        switch (feed.type) {
          case 'Movie':
            return MovieTile.prototype.load.call(this, scope, feed.id);
          case 'Picture':
            return PictureSetTile.prototype.load.call(this, scope, feed.id);
          case 'Weblog':
            return weblogStore.get(feed.id)
              .then(weblog => {
                scope.weblog = weblog;
                this.cssClass.push(weblog.mediaType ? weblog.mediaType.toLowerCase() : 'without-media');
                switch (weblog.mediaType) {
                  case 'Movie':
                    if (MovieTile) {
                      return MovieTile.prototype.load.call(this, scope, weblog.mediaId);
                    }
                    break;
                  case 'PictureSet':
                    if (PictureSetTile) {
                      return PictureSetTile.prototype.load.call(this, scope, weblog.mediaId);
                    }
                    break;
                  default:
                    const image = scope.weblog.getImageUrl();
                    if (!image) {
                      this.cssClass.push('without-image');
                    } else {
                      this.apply({ image });
                    }
                    return AmateurTile.prototype.load.call(this, scope, weblog.amateurId);
                }

                throw { msg: 'no template for feed type', feed };
              })
              .then(() => {
                scope.title = scope.weblog.title;
                scope.desc = scope.weblog.content;
                scope.publishedAt = new Date(scope.weblog.published).toLocaleString(document.body.lang, {dateStyle: 'medium', timeStyle: 'short'});
              });
          default:
            throw { msg: 'no template for feed type', feed };
        }
      }
      hookname() {
        return 'feed';
      }
      css() {
        return this.cssClass;
      }
    }
    return FeedTile;
  }]);
