// bounty, rubicon
import {setTimeout} from '@acng/frontend-bounty/dom/window.js';
import {MapStore, remove} from '@acng/frontend-bounty/collection.js';
import {size} from '@acng/frontend-bounty/std/array.js';
import {ARRAY, UNKNOWN, guard} from '@acng/frontend-rubicon';
// enterprise
import {get} from 'acng/core/service/backend.js';
import {authUser} from 'acng/userPool/context/auth-user.js';

/**
 * @type {(achievement: string) => Promise<boolean>}
 */
export const userHas = MapStore(async (achievement, cache) => {
  setTimeout(() => remove(cache, achievement), 5000);

  if (!authUser) {
    return false;
  }

  const achievements = await get(`achievements/user?name=${achievement}`, undefined, async (error) => {
    const error_ = new Error(error.message);
    // @ts-ignore
    error_.cause = error;
    throw new Error(error.message);
  });
  ASSERT: guard(achievements, ARRAY(UNKNOWN));

  return size(achievements) > 0;
});
