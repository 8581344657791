import {media} from 'acng/core/service/env';
angular.module('core', ['pascalprecht.translate'])
  .config(['$sceDelegateProvider', $sceDelegateProvider => {
    $sceDelegateProvider.trustedResourceUrlList([
      'self',
      `${media.assets}/**`,
    ]);
  }])
  .config(['$animateProvider', $animateProvider => {
    $animateProvider.customFilter(node => node.hasAttribute('ons-action') || node.classList.contains('animate'));
  }])
  .run(['director', function (director) {
    director.init();
  }])
  .run(['$document', 'isMobile', ($document, isMobile) => {
    angular.element($document[0].body).addClass(isMobile ? 'mobile' : 'desktop');
  }])
  .run(['$rootScope', '$timeout', 'authUser', ($rootScope, $timeout, authUser) => {
    $rootScope.Plimit = 5000;
    $rootScope.Mlimit = 5000;
    if (authUser) {
      $timeout(function () {
        $rootScope.$broadcast('userPool.login.success', authUser);
      }, 300);
    }
  }])
  .config(['$locationProvider', function ($locationProvider) {
    $locationProvider.hashPrefix('');
  }])
  .run(['$rootScope', '$location', 'user', 'supportEmail', 'portal', 'media', 'webmasterLink', 'fsk', function ($rootScope, $location, user, supportEmail, portal, media, webmasterLink, fsk) {
    $rootScope.user = user;
    $rootScope.Mcategories = [];
    $rootScope.Pcategories = [];

    $rootScope.$on('$locationChangeSuccess', function ($event, newUrl, oldUrl) {
      if (newUrl !== oldUrl) {
        angular.element('html, body, main > .box').scrollTop(0);
      }
    });

    $rootScope._globals = {
      portal: portal,
      portalName: portal.name,
      supportEmail: supportEmail,
      media: media,
      hostname: window.location.hostname.replace('www.', ''),
      webmasterLink: webmasterLink
    };

    $rootScope.gotoHotornot = () => {
      $location.path('/hotornot');
    };

    $rootScope.$on('$locationChangeSuccess', () => {
      if ($location.search().preview_mode) {
        const previewKey = $location.search().preview_mode;
        $location.search('preview_mode', null).replace();
        if (previewKey == 'c2Q0BYI') {
          user.preview = true;
          user.fsk = 18;
          fsk.set(18);
        }
      }
    });
  }])

  .config(['$httpProvider', function ($httpProvider) {
    $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
    $httpProvider.interceptors.unshift('globalHttpInterceptor');
  }]);
