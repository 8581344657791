import {Engine, defineCompileElement, defineRegistryElement} from '@acng/frontend-stargazer';
import {connectedCallback, defineElement, HTMLElement} from '@acng/frontend-bounty/dom/custom.js';
import {IS, guard} from '@acng/frontend-rubicon';
import {push} from '@acng/frontend-bounty/std/array.js';
import {isInstanceOf} from '@acng/frontend-bounty/std/value.js';

import {getMessageDraft} from 'acng/messenger/context/message-draft.js';
import {coreFeature} from '../config/feature.js';
import {smileys} from '../factory/smileyList.js';
import {repeatElement} from 'acng/layout/service/repeat.js';
import {onClick} from 'acng/layout/service/worker.js';

const MODULE = 'core/widgets/smileys';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`);

defineRegistryElement('onsw-smileys', (name) => {
  /**
   * @type {import("@acng/frontend-stargazer").Engine}
   */
  let parser;

  defineCompileElement(name, (_element, promises) => {
    push(
      promises,
      coreFeature.lookup(name).then((template) => (parser = new Engine(template)))
    );
  });

  defineElement(
    name,
    class extends HTMLElement {
      [connectedCallback]() {
        parser.toElement(this, undefined, ({repeat}, target) => {
          ASSERT: guard(repeat, IS(HTMLImageElement));

          repeatElement(repeat, smileys, (smiley) => {
            repeat.alt = smiley.key;
            repeat.src = smiley.getUrl();
            repeat.title = smiley.key;
          });

          onClick(target, (element, {target}) => {
            if (!isInstanceOf(target, HTMLImageElement)) {
              DEBUG: if (VERBOSE) console.debug(`${MODULE} the click didn't hit a smiley`, {element: this});
              return;
            }

            const draft = getMessageDraft(element);

            if (!draft) {
              DEBUG: console.warn(`${MODULE} there is no draft message`, {element: this});
              return;
            }

            DEBUG: if (VERBOSE) console.info(MODULE, {element: this, smiley: target.alt, draft});

            draft.body += target.alt;
            draft.focusIfNotMobile();
          });
        });
      }
    }
  );
});
