import {ENUM, INTEGER, OBJECT, OPTIONAL, STRING, UNKNOWN} from '@acng/frontend-rubicon';
import {PAYTTACHMENT_DATA} from './payttachment.js';

/**
 * @typedef {Partial<Valid<MESSAGE_PAYLOAD>>} MessagePayload
 */

export const MESSAGE_PAYLOAD = /* @__PURE__ */ (() =>
  OBJECT({
    type: OPTIONAL(
      ENUM(
        /** @type {const} */ ([
          'match',
          'kiss',
          'game.invitation',
          'sexicon',
          'regard',
          'payttachment',
          'voice',
          'message',
        ])
      )
    ),
    text: OPTIONAL(STRING),
    image: OPTIONAL(STRING),
    ibid: OPTIONAL(INTEGER),
    ibts: OPTIONAL(STRING),
    ibb: OPTIONAL(UNKNOWN),
    payttachment: OPTIONAL(PAYTTACHMENT_DATA),
    utm_id: OPTIONAL(UNKNOWN),
    id: OPTIONAL(UNKNOWN),
    price: OPTIONAL(INTEGER), // regard
    invitation_id: OPTIONAL(INTEGER),
  }))();
