import {defineRegistryElement} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {size} from '@acng/frontend-bounty/std/array.js';
import {def} from '@acng/frontend-bounty/dom/custom.js';
import {create} from '@acng/frontend-bounty/dom/element.js';
import {append, remove} from '@acng/frontend-bounty/dom/move.js';
import {add} from '@acng/frontend-bounty/style/element.js';
import {createTemplate, document, setText} from '@acng/frontend-bounty';
import {createPerceptiveHTML, createStream} from '@acng/frontend-discovery';

import {commentFeature} from '../config/feature.js';
import {ctxGallery} from '../../core/context/gallery.js';
import {ctxCreatedComment} from '../context/created.js';
import {listComments} from '../service/http.js';

const limit = 20;
const VERBOSE_DEBUG = false;

/**
 * @group DOM Element
 */
export const COMMENT_LIST = 'onsw-comment-list';

defineRegistryElement(COMMENT_LIST, (tagName) => {
  const watchGallery = Watch(tagName, ctxGallery);
  const watchEvent = Watch(tagName, ctxCreatedComment);

  class CommentList extends HTMLElement {
    /** @type {Gallery | null} */
    #commentable = null;
    /** @type {HTMLParagraphElement | undefined} */
    #empty;

    #stream = createStream([], async (offset) => {
      if (!this.#commentable) {
        throw Error('something went wrong');
      }
      DEBUG: console.warn(`${tagName} pull`, {offset});
      const comments = await listComments(this, this.#commentable, limit, offset);
      const count = size(comments);
      DEBUG: console.warn(`${tagName} pulled`, {comments});
      if (!offset && !count) {
        (async () => {
          const empty = create('p');
          add(empty, 'empty');
          this.#empty = empty;
          setText(empty, await commentFeature.translate('noComment'));
          append(this, empty);
        })();
      } else if (this.#empty) {
        remove(this.#empty);
        this.#empty = undefined;
      }
      return comments;
    });

    #perceptive = createPerceptiveHTML(this, this.#stream, async (comment, loader) => {
      DEBUG: console.info(tagName, {comment, loader});
      const header = create('header');
      const nickname = create('span');
      const text = create('p');
      add(nickname, 'name');
      setText(nickname, comment.nickname);
      append(
        header,
        nickname,
        ` ${await commentFeature.translate('date')} `,
        new Date(comment.created_at).toLocaleString(document.body.lang, {
          dateStyle: 'medium',
          timeStyle: 'short',
        })
      );
      setText(text, comment.text);
      // TODO confirm no markup
      // text.innerHTML = comment.text;
      return createTemplate('', header, text);
    });

    connectedCallback() {
      DEBUG: if (VERBOSE_DEBUG) this.#perceptive.enableDebug('comments');
      watchGallery(this, async (_, commentable) => {
        if (this.#commentable?.set_id === commentable.set_id) {
          return;
        }
        DEBUG: console.debug(`${tagName} connected with commentable`, {commentable});
        this.#commentable = commentable;
        this.#stream.streamReset([]);
        this.#perceptive.connect();
      });

      watchEvent(this, async (_, created) => {
        if (created && created.gallery.set_id == this.#commentable?.set_id) {
          DEBUG: console.debug(`${tagName} observed comment creation`, created);
          this.#stream.streamReset([]);
          this.#perceptive.connect();
        }
      });
    }

    disconnectedCallback() {
      DEBUG: console.debug(`${tagName} disconnected`);
      this.#perceptive.disconnect();
    }
  }

  def(tagName, CommentList);
});
