import {ENUM, INTEGER, OBJECT, STRING} from '@acng/frontend-rubicon';

/**
 * UHURA style the acng backend has no status property but a "locked" flag
 *
 * ```json
 * {
 *   "id": 8658605,
 *   "status": "ok",
 *   "name": "20231101_172158.jpg",
 *   "url": "/171/423/017/u17142317/files/file17142317.1698861859.jpg"
 * }
 * ```
 *
 * @typedef {Valid<ATTACHMENT_DATA>} AttachmentData
 */

const POSSIBLE_STATUS_LIST = /** @type {const} */ (['ok', 'locked', 'deleted']);

const STATUS = /* @__PURE__ */ ENUM(POSSIBLE_STATUS_LIST);

export const ATTACHMENT_DATA = /* @__PURE__ */ OBJECT({
  id: INTEGER,
  status: STATUS,
  name: STRING,
  url: STRING,
});
