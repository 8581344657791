/**
 * Provides the list of *PaymentBonus* currently active for the *AuthUser*.
 * It is used in the following places
 * - Slideshow on the Start Page
 * - Slideshow in the Sidebar
 * - The Payment Overlay
 * - Special Teaser
 *
 * @module PaymentBonus
 * @author Jacob Viertel <jv@onscreen.net>, Nils Engel <ne@onscreen.net>
 * @see [AuthUser](../../userPool/context/auth-user.js)
 */

import {setTimeout} from '@acng/frontend-bounty';
import {GlobalContext, GlobalWatchGroup, globalProvide, globalValue} from '@acng/frontend-relativity';
import {now} from '@acng/frontend-bounty/timing/now.js';
import {throttle} from '@acng/frontend-bounty/timing/throttle.js';

import {ARCHIMEDES} from 'acng/core/service/env.js';
import {inject} from 'acng/core/service/ng.js';
import {discardToken, useToken} from 'acng/userPool/service/token.js';
import {BONUS_DATA, Bonus} from '../model/bonus.js';
import {listen} from 'acng/core/context/event-bus.js';
import {TRANSACTION} from '../service/event-bus.js';
import {ctxEventBusUser} from 'acng/userPool/context/event-bus-user.js';
import {ARRAY, CONST, OBJECT, guard} from '@acng/frontend-rubicon';
import {TIMESTAMP_MS} from 'acng/core/model/time.js';

const MODULE = 'payment/context/bonus-list';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`import verbose ${MODULE}`);

/**
 * @type {import('@acng/frontend-relativity').ObservableValue<Bonus[]>}
 */
export const ctxBonusList = GlobalContext([]);

/**
 * @param {import('../model/bonus').BonusData[]} data
 */
export const putBonusData = (data) => {
  DEBUG: if (VERBOSE) console.info(`${MODULE} putBonusData`, data);
  const bonusList = data.map((bonusData) => new Bonus(bonusData));

  globalProvide(ctxBonusList, bonusList);
};

/**
 * @param {string} name
 * @returns {?Bonus}
 */
export const getBonus = (name) => globalValue(ctxBonusList).find((bonus) => bonus.name == name) ?? null;

(() => {
  let expireTimeout = 0;

  const getBonus = throttle(async () => {
    DEBUG: if (VERBOSE) console.debug(`${MODULE} getBonus`);
    const payment = inject('payment');
    const token = await useToken();
    await payment.loadAndGetCustomerId();

    try {
      /**
       * Archi calls have to use the legacy http factory for now.
       * @type {{data: unknown}}
       */
      const res = await inject('http')().get(`${ARCHIMEDES}/customer/${payment.customerId}/bonus`, {
        headers: {'X-AuthToken': token},
      });

      ASSERT: guard(res.data, ARCHIMEDES_BONUS);

      DEBUG: if (VERBOSE) console.info(`${MODULE} ${ARCHIMEDES}`, res.data);

      putBonusData(res.data);
    } catch (/** @type {any} */ err) {
      if (err?.data && err.data.error == 1001) {
        discardToken(token);
        await getBonus();
        return;
      }

      throw err;
    }
  }, 3000);

  DEBUG: if (VERBOSE) getBonus.enableDebug(MODULE);

  GlobalWatchGroup(
    ctxEventBusUser,
    ctxBonusList
  )(([eventBusUser, bonusList], [prevUser, prevBonusList]) => {
    DEBUG: console.debug(`${MODULE} watch`, {eventBusUser, bonusList, prevUser, prevBonusList});

    if (eventBusUser?.id != prevUser?.id) {
      getBonus();
      return;
    }
    if (!bonusList.length) {
      return;
    }

    const bonus = bonusList.reduce((detectedBonus, currentBonus) => {
      return (detectedBonus?.expiresAt ?? Infinity) > (currentBonus.expiresAt ?? Infinity)
        ? currentBonus
        : detectedBonus;
    }, /** @type {?Bonus} */ (null));

    if (bonus?.expiresAt) {
      clearTimeout(expireTimeout);

      expireTimeout = setTimeout(() => {
        DEBUG: if (VERBOSE) console.info(`${MODULE} bonus expired`);

        globalProvide(
          ctxBonusList,
          bonusList.filter((bonus) => (bonus.expiresAt?.getTime() ?? Infinity) > now())
        );
        setTimeout(getBonus, 1000);
      }, Math.min(1000 * 3600 * 24, bonus.expiresAt.getTime() - now()));
    }
  });

  listen('bonus', (data) => {
    ASSERT: guard(data, EVENTBUS_BONUS);
    DEBUG: if (VERBOSE) console.info(`${MODULE} event bus`, data.bonus);
    const bonusList = globalValue(ctxBonusList);

    for (const bonus of bonusList) {
      if (data.bonus.name === bonus.name) {
        return;
      }
    }

    bonusList.push(new Bonus(data.bonus));
    bonusList.sort((a, b) => b.priority - a.priority);

    globalProvide(ctxBonusList, bonusList);
  });

  listen(TRANSACTION, getBonus);
})();

const EVENTBUS_BONUS = /* @__PURE__ */ OBJECT({
  type: CONST('bonus'),
  bonus: BONUS_DATA,
  timestamp_ms: TIMESTAMP_MS,
});

const ARCHIMEDES_BONUS = /* @__PURE__ */ ARRAY(BONUS_DATA);
