// bounty, rubicon
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {guard} from '@acng/frontend-rubicon';
// stargazer, relativity, nova
import {Attribute, Engine} from '@acng/frontend-stargazer';
import {onClick} from 'acng/layout/service/worker.js';
import {localValue} from '@acng/frontend-relativity';
// enterprise
import {media} from '../../core/service/env.js';
import {ctxChallenge} from '../../achievement/context/challenge.js';
import {USE_CHALLENGE} from '../../achievement/attribute/use-challenge.js';
import {NAME, GAME} from '../model/game.js';

/**
 * Start a *special game* when the element is clicked.
 *
 * An optionally specified {@link USE_CHALLENGE | challenge}
 * will be achieved once the game is won.
 *
 * @example
 * ```html
 * <button click-special-game="valentine"> Play </button>
 * ```
 * @group DOM Attribute
 */
export const CLICK_SPECIAL_GAME = 'click-special-game';

Attribute(CLICK_SPECIAL_GAME, () => [
  ,
  (element, value) => () =>
    onClick(element, async () => {
      ASSERT: guard(value, NAME, 'special game name');
      const game = await import(`../games/${value}.js`);
      ASSERT: guard(game, GAME, 'special game module');
      const assetsPath = `${media.assets_ext}/specials/`;

      await game.start(assetsPath, Engine.root, async () => {
        try {
          const challenge = localValue(element, ctxChallenge);

          await challenge.achieve(element);
        } catch (reason) {
          DEBUG: if (debug(element)) console.info('no challenge specified', {element});
        }
      });
    }),
]);
