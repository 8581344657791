// bounty, stargazer, relativity, rubicon
import {attributeSelector} from '@acng/frontend-bounty/dom/query.js';
import {Attribute} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {IS, guard} from '@acng/frontend-rubicon';
// enterprise
import {getFallbackImageUrl} from 'acng/layout/service/fallback-image.js';
import {IMAGE_FORMAT} from '../../core/model/image.js';
import {ctxAmateur} from '../context/amateur.js';

/**
 * Set the image source to the preferred profile image of the amateur context.
 *
 * @example
 * ```html
 * <img amateur-image-src="medium">
 * ```
 * @group DOM Attribute
 */
export const AMATEUR_IMAGE_SRC = 'amateur-image-src';

Attribute(AMATEUR_IMAGE_SRC, (name) => {
  const watch = Watch(attributeSelector(name), ctxAmateur);

  return [
    ,
    (image, format) => () =>
      watch(image, (image, amateur) => {
        ASSERT: {
          guard(image, IS_IMAGE, `[${name}]`);
          guard(format, IMAGE_FORMAT, `[${name}="${format}"]`);
        }
        image.src = amateur ? amateur.getImageUrl(format) : getFallbackImageUrl(format);
      }),
  ];
});

const IS_IMAGE = /* @__PURE__ */ IS(HTMLImageElement);
