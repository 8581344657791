import {INTEGER, NULLABLE, OBJECT, STRING, OPTIONAL, ARRAY, UNKNOWN, CONST} from '@acng/frontend-rubicon';
import {TIMESTAMP_MS} from '../../core/model/time.js';

/**
 * @typedef {Valid<typeof INVITATION_DATA>} InvitationData
 */

export const INVITATION_DATA = /* @__PURE__ */ OBJECT({
  id: INTEGER,
  createdAt: STRING,
  expires: NULLABLE(INTEGER),
  expiresAt: NULLABLE(STRING),
  updatedAt: STRING,
  gameId: INTEGER,
  fromId: STRING,
  toId: STRING,
  status: STRING,
  sessionId: UNKNOWN,
  from: UNKNOWN,
  to: UNKNOWN,
  session: UNKNOWN,
  game: UNKNOWN,
});

export const INVITATIONS = /* @__PURE__ */ ARRAY(INVITATION_DATA);

/**
 * @typedef {Valid<typeof SESSION_DATA>} SessionData
 */

const SESSION_DATA = /* @__PURE__ */ OBJECT({
  id: INTEGER,
  createdAt: STRING,
  updatedAt: STRING,
  status: STRING,
  decision: NULLABLE(STRING),
  decisionReason: NULLABLE(STRING),
  winnerId: NULLABLE(STRING),
  turnId: STRING,
  turnExpires: NULLABLE(INTEGER),
  turnExpiresAt: NULLABLE(STRING),
  sessionId: UNKNOWN,
  invitation: UNKNOWN,
});

export const SESSIONS = /* @__PURE__ */ ARRAY(SESSION_DATA);

export const EVENTBUS_GAME = /* @__PURE__ */ OBJECT({
  type: CONST('games.update'),
  from: STRING,
  timestamp_ms: TIMESTAMP_MS,
  invitation: OPTIONAL(INVITATION_DATA),
  session: OPTIONAL(SESSION_DATA),
});

/**
 * @typedef {Valid<typeof GAMES>[number]} Game
 */

export const GAMES = /* @__PURE__ */ ARRAY(
  OBJECT({
    id: INTEGER,
    name: STRING,
    createdAt: STRING,
    webComponentLoaderPath: STRING,
    webComponentPath: STRING,
    webComponentUrl: UNKNOWN,
    webComponentLoaderUrl: UNKNOWN,
  })
);
